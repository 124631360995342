import styled from "styled-components";

const Container = styled.div`
  /* margin-left: -24px; */
  max-width: 30vw;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  line-height: 1.7;

  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
`;

// const Layer = styled.div`
//   margin-left: 24px;
// `;

// const Row = styled.div`
//   text-align: left;
//   min-height: 25px;
// `;

const Green = styled.div`
  color: ${(props) => props.theme.positiveColor};
`;

const Red = styled.div`
  color: ${(props) => props.theme.negativeColor};
`;

const Text = styled.div<{ rowLimit?: number }>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(props) => props.rowLimit};
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  overflow: hidden;
  overflow-wrap: anywhere;
`;

const Font = styled.span`
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#222")};
`;

export interface ILCServerStatusComponentProps {
  data: {
    value: any;
  };
  options: {
    arrayLimitSize?: number;
    fontSize?: number;
  };
}

export default function LCServerStatusComponent(
  props: ILCServerStatusComponentProps
) {
  return (
    <Container>
      <Font>
        <Text>
          {props.data.value === "online" ? (
            <Green>Online</Green>
          ) : (
            <Red>Offline</Red>
          )}
        </Text>
      </Font>
    </Container>
  );
}
