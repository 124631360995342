import { Registry } from "allegro-api";
import { ASLowCodeFormDefinition } from "allegro-low-code-components";
import { createnXtalAPI } from "src/api";
import { fetchGroupMany } from "src/portal/api/fetchGroup";
import { fetchUsersByEmail } from "src/portal/api/fetchUser";

export const createOptXV2FormDeinition = (options?: {
  isUpdate?: boolean;
}): ASLowCodeFormDefinition => {
  const advancedSettings: any[] = !options?.isUpdate
    ? [
        {
          label: "Advanced Setting",
          name: "advanced",
          type: "section",
        },
        {
          label: "OptX ID",
          name: "optXId",
          type: "text",
          required: false,
          supportText:
            "Enter OptX ID. By default, it will be generated automatically by API.",
        },
        {
          label: "Docker Repository Name",
          name: "dockerRepositoryName",
          type: "text",
          required: false,
          supportText:
            "Enter a Docker image name. By default, the optXId is applied to the Docker image.",
        },
      ]
    : [];
  return {
    sections: [
      {
        title: "",
        fields: [
          {
            label: "OptX Name",
            type: "text",
            name: "name",
            required: true,
            errorText: "name is required.",
          },
          {
            label: "Registry",
            type: "text",
            name: "registryId",
            required: false,
            values: async (name) => {
              const nxtal = createnXtalAPI();

              const searchFilter = name
                ? {
                    $or: [
                      {
                        "contents.rawdata.name": {
                          $regex: name,
                        },
                      },
                      {
                        "contents.rawdata.registryId": {
                          $regex: name,
                        },
                      },
                    ],
                  }
                : {};
              const newtQuery = {
                shape: {
                  "contents.rawdata.registryId": "any",
                  "contents.rawdata.name": "any",
                },
                search: searchFilter,
              };
              const [registries] = await nxtal.registry
                .dataProvider(newtQuery)
                .catch((err) => [[] as Registry[]]);

              return registries.map((registry) => {
                return {
                  label: registry.name,
                  value: registry.registryId,
                };
              });
            },
            errorText: "registry is requried.",
            supportText: "Retrieves OptX Image from the specified Registry.",
          },
          {
            label: "Description",
            type: "multiline",
            name: "description",
            required: false,
            values: undefined,
          },
          {
            label: "shareLevel",
            name: "shareLevel",
            type: "radio",
            required: true,
            errorText: "shareLevel is required.",
            supportText:
              "If it is public, you can get the data across all contractors.",
            values: [
              {
                label: "公開",
                value: "public",
              },
              {
                label: "非公開",
                value: "private",
              },
            ],
            active: "private",
          },
          {
            label: "Readable Users",
            name: "allowReadUsers",
            type: "chip",
            required: true,
            errorText: "readable users are required.",
            supportText:
              "Please enter * to allow access to all contractor users.",
            active: ["*"],
            values: async (searchText) => {
              if (searchText === "*") {
                return [];
              }
              const users = await fetchUsersByEmail(searchText ?? "");
              return users.map((user) => {
                return {
                  label: user.email,
                  value: user.userNoText,
                };
              });
            },
          },
          {
            label: "Readable Groups",
            name: "allowReadGroups",
            type: "chip",
            required: false,
            active: [],
            values: async (searchText) => {
              if (searchText === "*") {
                return [];
              }
              const searchQuery = {
                $or: [
                  { "contents.rawdata.group_name": searchText },
                  { "contents.rawdata.group_id": searchText },
                ],
              };
              const groups = await fetchGroupMany({ matchQ: searchQuery });
              return groups.map((group) => {
                return {
                  label: group.group_name,
                  value: group.group_id,
                };
              });
            },
          },
          {
            label: "Writable Users",
            name: "allowWriteUsers",
            type: "chip",
            required: true,
            errorText: "writable users are required.",
            supportText:
              "Please enter * to allow access to all contractor users.",
            active: ["*"],
            values: async (searchText) => {
              if (searchText === "*") {
                return [];
              }
              const users = await fetchUsersByEmail(searchText ?? "");
              return users.map((user) => {
                return {
                  label: user.email,
                  value: user.userNoText,
                };
              });
            },
          },
          {
            label: "Writable Groups",
            name: "allowWriteGroups",
            type: "chip",
            required: false,
            active: [],
            values: async (searchText) => {
              if (searchText === "*") {
                return [];
              }
              const searchQuery = {
                $or: [
                  { "contents.rawdata.group_name": searchText },
                  { "contents.rawdata.group_id": searchText },
                ],
              };
              const groups = await fetchGroupMany({ matchQ: searchQuery });
              return groups.map((group) => {
                return {
                  label: group.group_name,
                  value: group.group_id,
                };
              });
            },
          },
          ...advancedSettings,
        ],
      },
    ],
    buttonOptions: {
      submitText: "Create",
      updateText: "Update",
    },
  };
};
