import { createNewtZeroAPI } from "src/api";
import { ASBrowserUiConf } from "src/cheetah/components/libs/ASLowCodeBrowser";

/**
 * InfrastructureのServer一覧のローコード設定
 */
export const createServerBroweserUi = (
  canDeleteServer?: boolean
): ASBrowserUiConf => {
  const newtzero = createNewtZeroAPI();
  return {
    newtQ: async (limit: number, skip: number, options) => {
      const res = await newtzero.server.dataProvider({
        match: options?.match,
        limit,
        skip,
        shape: {
          "contents.rawdata": "any",
        },
      });
      return res;
    },
    filters: [],
    verticalFilters: [
      {
        label: "Search",
        design: "search",
        selections: [],
        callback: (value: string) => {
          return {
            $or: [
              {
                "contents.rawdata.non_numeric_server_name": {
                  $regex: value,
                  $options: "i",
                },
              },
              {
                "contents.rawdata.non_numeric_ip1": {
                  $regex: value,
                  $options: "i",
                },
              },
              {
                "contents.rawdata.non_numeric_ip2": {
                  $regex: value,
                  $options: "i",
                },
              },
              {
                "contents.rawdata.non_numeric_ip3": {
                  $regex: value,
                  $options: "i",
                },
              },
            ],
          };
        },
      },
    ],
    tables: [
      {
        label: "Server name",
        component: "LCTextLinkCellComponent",
        data: {
          label: "contents.rawdata.non_numeric_server_name",
          id: "contents.rawdata.non_numeric_server_id",
        },
        statics: {
          path: "/devops/servers/{id}",
        },
        options: {
          dateFormat: "wareki",
          editable: false,
        },
      },
      {
        label: "ID",
        component: "LCSimpleColComponent",
        data: {
          value: "contents.rawdata.non_numeric_server_id",
        },
        options: {
          dateFormat: "wareki",
          editable: false,
        },
      },
      {
        label: "IP1",
        component: "LCSimpleColComponent",
        data: {
          value: "contents.rawdata.non_numeric_ip1",
        },
        options: {
          dateFormat: "wareki",
          editable: false,
        },
      },
      {
        label: "IP2",
        component: "LCSimpleColComponent",
        data: {
          value: "contents.rawdata.non_numeric_ip2",
        },
        options: {
          dateFormat: "wareki",
          editable: false,
        },
      },
      {
        label: "IP3",
        component: "LCSimpleColComponent",
        data: {
          value: "contents.rawdata.non_numeric_ip3",
        },
        options: {
          dateFormat: "wareki",
          editable: false,
        },
      },
      {
        label: "ServerStatus",
        component: "LCServerStatusComponent",
        data: {
          value: "contents.rawdata.non_numeric_server_status",
        },
        options: {},
      },
      {
        label: "Last Status Updates",
        component: "LCUnixTimeCellComponent",
        data: {
          value: "contents.rawdata.non_numeric_last_status_update_datetime",
        },
        options: { dateFormat: "wareki", editable: false },
      },

      {
        label: "Tags",
        component: "LCSimpleColComponent",
        data: {
          value: "contents.rawdata.non_numeric_tags",
        },
        options: {
          dateFormat: "wareki",
          editable: false,
        },
      },

      {
        label: "",
        component: "LCDeleteButtonComponent",
        data: {
          name: "contents.rawdata.non_numeric_server_name",
          value: "contents.rawdata.non_numeric_server_id",
        },
        options: {
          disabled: !canDeleteServer,
          onSubmit: async (serverId: string) => {
            const newtzero = createNewtZeroAPI();
            try {
              const res = await newtzero.server.removeOne(serverId);
              if (res) {
                alert(`削除に成功しました。${serverId}`);
                window.location.reload();
              } else {
                alert(`削除に失敗しました. ${serverId}`);
              }
            } catch (err) {
              console.error(err);
            }
          },
        },
      },
    ],
    pager: { perPage: 50 },
  };
};
