import { ASBrowserUiConf } from "src/cheetah/components/libs/ASLowCodeBrowser";
import { fetchActivitiesByUserNo } from "src/opt-editor/api/fetchActivities";

export const createActivityV2ListBrowser = (
  userNo: number
): ASBrowserUiConf => {
  return {
    newtQ: async (limit: number, skip: number) => {
      const activities = await fetchActivitiesByUserNo(userNo, { limit, skip });
      return activities;
    },
    tables: [
      {
        label: "Level",
        component: "LCSimpleColComponent",
        data: {
          value: "level",
        },
        options: {},
      },
      {
        label: "Message",
        component: "LCSimpleColComponent",
        data: {
          value: "message",
        },
        options: {},
      },
      {
        label: "Created",
        component: "LCUnixTimeCellComponent",
        data: {
          value: "created",
        },
        options: {
          dateFormat: "default",
        },
      },
    ],
  };
};
