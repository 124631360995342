import { ASBrowserUiConf } from "src/cheetah/components/libs/ASLowCodeBrowser";
import { Bundle } from "allegro-api";

export const createBundleResourcesBrowserUiConf = (
  bundle: Bundle
): ASBrowserUiConf => {
  return {
    newtQ: async () => {
      return bundle.references;
    },
    tables: [
      {
        label: "Name",
        component: "LCTextLinkCellComponent",
        data: {
          label: "name",
          itemId: "object.assetId",
        },
        statics: {
          path: `/ai/${bundle.bundleId}/asset/{itemId}`,
        },
        options: {},
      },
      {
        label: "slug",
        component: "LCSimpleColComponent",
        data: {
          value: "slug",
        },
        options: {},
      },
      {
        label: "kind",
        component: "LCSimpleColComponent",
        data: {
          value: "kind",
        },
        options: {},
      },
      {
        label: "Tags",
        component: "LCSimpleColComponent",
        data: {
          value: "object.assetTags",
        },
        options: {},
      },
      {
        label: "Asset ID",
        component: "LCSimpleColComponent",
        data: {
          value: "object.assetId",
        },
        options: {},
      },
    ],
  };
};
