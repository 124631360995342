import { useParams } from "react-router-dom";
import { NTMainViewTemplate } from "../templates/NTMainViewTemplate";
import { NTRegistrySingleTemplate } from "../templates/NTRegistrySingleTemplate";

type PathParams = {
  registryId: string;
};

export const NTRegistrySinglePage: React.FC = () => {
  const { registryId } = useParams<PathParams>();

  return (
    <NTMainViewTemplate>
      <NTRegistrySingleTemplate registryId={registryId ?? ""} />
    </NTMainViewTemplate>
  );
};
