import { XFile, XFileInput } from "allegro-api";
import { useState } from "react";
import { createnXtalAPI } from "src/api";
import { activityLogger } from "../activity-logger";

export const useXFile = (optXId: string, variationId: string, file: XFile) => {
  const nxtal = createnXtalAPI();
  const [showModal, setShowModal] = useState(false);
  const [cursorFile, setCursorFile] = useState<XFile>(file);

  const _updateXFile = async (newFile: XFileInput) => {
    const res = await nxtal.xfile.updateOne(
      optXId,
      variationId,
      file.name,
      newFile,
      {
        upsert: true,
      }
    );
    if (!res) {
      throw Error(`Failed to update ${file.name}`);
    }

    await activityLogger.saveOptXVariationActivity(
      "info",
      `OptX Variation:${variationId}の${file.name}を編集しました。`,
      optXId,
      variationId,
      ["xfile"]
    );
  };

  const _createXFile = async (newFile: XFileInput) => {
    const res = await nxtal.xfile.add(optXId, variationId, newFile);
    if (!res) {
      throw Error(`Failed to create ${file.name}`);
    }

    await activityLogger.saveOptXVariationActivity(
      "info",
      `OptX Variation:${variationId}の${file.name}を新規登録しました。`,
      optXId,
      variationId,
      ["xfile"]
    );
  };

  const handleSubmitXFile = async (data: { [key: string]: any }) => {
    const newFile: XFileInput = {
      name: file.name,
      text: data.text,
      parentOptXId: file.parentOptXId,
      parentVariationId: file.parentVariationId,
    };

    if (file.createdBy === "default") {
      await _createXFile(newFile);
    } else {
      await _updateXFile(newFile);
    }

    setCursorFile({ ...cursorFile, ...newFile });
  };

  const handleOpenEditModal = () => {
    setShowModal(true);
  };

  const handleCloseEditModal = () => {
    setShowModal(false);
  };

  return {
    cursorFile,
    showModal,
    handleSubmitXFile,
    handleOpenEditModal,
    handleCloseEditModal,
  };
};
