import { OptXV2 } from "allegro-api";
import { ASLowCodeFormContainer } from "allegro-low-code-components";
import { ASButton, ASInputBase, ASPictureSelectButton } from "allegro-ui";
import { createOptXV2FormDeinition } from "src/xerver-room/config/formDefinition";
import { useOptXV2UpdateForm } from "src/xerver-room/hooks/useOptXV2UpdateForm";
import styled from "styled-components";
import { XROptXBlock } from "../molecules/XROptXBlock";

const MenuArea = styled.div`
  display: flex;
  flex-direction: column;

  > *:last-child {
    margin-bottom: 24px;
  }
`;

const FormContainer = styled.div`
  margin: 12px 0;
`;

const MarginDiv = styled.div`
  text-align: right;
  margin-top: 12px;
`;

const DeleteContainer = styled.div`
  > * {
    margin-right: 12px;
  }
  > *:last-child {
    margin-right: 0px;
  }
`;

const DeleteButton = styled(ASButton)``;

type Props = {
  optxv2: OptXV2;
};

export const XROptXV2UpdateForm: React.FC<Props> = (props) => {
  const { optxv2 } = props;

  const {
    canUpdateOptX,
    canDeleteOptX,
    handleUpdateOptX,
    handleChangeThumbnail,
    handleDeleteOptX,
    handleChangeDeleteOptXName,
  } = useOptXV2UpdateForm(optxv2.optXId);

  return (
    <MenuArea>
      <XROptXBlock title="OptX Setting">
        <FormContainer>
          <ASLowCodeFormContainer
            fullWidth
            isUpdate
            editable={canUpdateOptX}
            defaultValues={optxv2}
            formDefinition={createOptXV2FormDeinition({ isUpdate: true })}
            onSubmit={async (data, options) => {
              await handleUpdateOptX(data);
            }}
          />
        </FormContainer>
      </XROptXBlock>
      <XROptXBlock
        title="Icon Setting"
        description="画像は1MB以下にしてください。大きすぎるとアップロードできません。"
      >
        <MarginDiv>
          <ASPictureSelectButton
            disabled={!canUpdateOptX}
            onChange={handleChangeThumbnail}
          />
        </MarginDiv>
      </XROptXBlock>
      <XROptXBlock
        title="Delete OptX"
        description="削除するには確認のため、OptXの名前を入力してください。"
      >
        <DeleteContainer>
          <ASInputBase
            kind="digicho"
            disabled={!canDeleteOptX}
            onChange={handleChangeDeleteOptXName}
          />
          <DeleteButton
            kind="secondary"
            disabled={!canDeleteOptX}
            onClick={handleDeleteOptX}
          >
            DELETE
          </DeleteButton>
        </DeleteContainer>
      </XROptXBlock>
    </MenuArea>
  );
};
