import {
  ASButton,
  ASFormLabel,
  ASModal,
  ASNoDataPlaceHolder,
} from "allegro-ui";
import styled from "styled-components";
import {
  NAGraphContainer,
  NAGraphContainerProps,
} from "../molecules/NAGraphContainer";
import { useRecoilValueLoadable } from "recoil";
import {
  learningResultsState,
  papermillNotebookState,
} from "src/nai-console/recoils/nAIState";
import { ASLowCodeDataBrowserContainer } from "src/cheetah/components/libs/ASLowCodeBrowser";
import { HTMLParser } from "../atoms/HTMLParser";
import { useState } from "react";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

const Container = styled.div``;

const BlockContainer = styled.div`
  margin-bottom: 24px;
`;

const BlockHeader = styled.div``;
const BlockBody = styled.div``;

const ModalContainer = styled.div`
  padding: 24px 40px;
  height: 90vh;
  width: 70vw;
  overflow-y: scroll;
`;

const StyledNoData = styled(ASNoDataPlaceHolder)`
  height: 60px;
  background: ${(props) => props.theme.cardColor};
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
`;

const SupportText = styled.div`
  color: ${(props) => props.theme.textColor};
  font-size: ${(props) => props.theme.supportFontSizeLg}px;
`;

const roundWithScale = (value: number, scale: number) => {
  return Math.round(value * 10 ** scale) / 10 ** scale;
};

const createGraphData = (
  data: any,
  options?: { x: string; y: string; color?: string; title?: string }
): NAGraphContainerProps => {
  const { color, title } = options ?? {};
  const xLabel = options?.x ?? "label";
  const yLabel = options?.y ?? "value";
  if (data) {
    return {
      title: title,
      xlabel: xLabel,
      ylabel: yLabel,
      graphData: [
        {
          id: 2,
          label: "",
          color: color ?? "primary",
          dataPoints: data.map((d: any) => {
            return { x: String(d[xLabel]), y: d[yLabel] };
          }),
        },
      ],
    };
  } else {
    return {
      title: title,
      graphData: [
        {
          id: 2,
          label: "",
          color: color ?? "primary",
          dataPoints: Array.from({ length: 101 }, (_, i) => i).map((arr) => {
            return {
              x: String(arr),
              y: 0,
            };
          }),
        },
      ],
    };
  }
};

type Props = {};

export const NABundleSpec: React.FC<Props> = (props) => {
  const [showPapermill, setShowPapermill] = useState(false);

  const learningResultsLoadable = useRecoilValueLoadable(learningResultsState);
  const papermillNotebookLoadable = useRecoilValueLoadable(
    papermillNotebookState
  );

  const graphData = createGraphData(
    learningResultsLoadable.contents?.learningCurve,
    {
      x: "epoch",
      y: "loss",
      color: "primary",
      title: "Learning Curve",
    }
  );
  const renderGraph = () => {
    return (
      <>
        {learningResultsLoadable.state === "hasValue" ? (
          <NAGraphContainer {...graphData} />
        ) : (
          <NAGraphContainer {...graphData} />
        )}
      </>
    );
  };

  return (
    <Container>
      {renderGraph()}

      <BlockContainer>
        <BlockHeader>
          <ASFormLabel>Performance</ASFormLabel>
        </BlockHeader>
        <BlockBody>
          {learningResultsLoadable.state === "hasValue" &&
          learningResultsLoadable.contents ? (
            <ASLowCodeDataBrowserContainer
              name="nai-performance"
              uiConf={{
                newtQ: async () => {
                  return [learningResultsLoadable.contents];
                },
                tables: Object.entries({
                  bestEpoch: learningResultsLoadable.contents.bestEpoch,
                  ...learningResultsLoadable.contents.accuracy,
                }).map(([key, value]) => {
                  return {
                    label: key,
                    component: "LCSimpleColComponent",
                    data: (data) => {
                      return {
                        value: roundWithScale(Number(value), 3),
                      };
                    },
                    options: {},
                  };
                }),
              }}
            />
          ) : (
            <>
              <StyledNoData>データがありません</StyledNoData>
            </>
          )}
        </BlockBody>
      </BlockContainer>

      <BlockContainer>
        <BlockHeader>
          <ASFormLabel>Papermill Notebook</ASFormLabel>
        </BlockHeader>
        <BlockBody>
          <SupportText>
            Papermillによって実行した学習結果をファイルで確認できます。
          </SupportText>
          <ASButton
            kind="primary"
            disabled={
              !(
                papermillNotebookLoadable.state === "hasValue" &&
                papermillNotebookLoadable.contents
              )
            }
            onClick={() => {
              setShowPapermill(true);
            }}
          >
            <OpenInNewIcon />
            Open
          </ASButton>
          <ASModal
            open={showPapermill}
            title="Papermill Notebook"
            onClose={() => {
              setShowPapermill(false);
            }}
          >
            <ModalContainer>
              {papermillNotebookLoadable.state === "hasValue" ? (
                <HTMLParser html={String(papermillNotebookLoadable.contents)} />
              ) : (
                <></>
              )}
            </ModalContainer>
          </ASModal>
        </BlockBody>
      </BlockContainer>
    </Container>
  );
};
