import { BundleRefenrece } from "allegro-api";

export const nAIBundleReferences: { [key: string]: BundleRefenrece } = {
  trainDataset: {
    name: "TrainingDataset",
    slug: "training",
    kind: "TopData",
  },
  testDataset: {
    name: "TestDataset",
    slug: "test",
    kind: "TopData",
  },
  souceCode: {
    name: "SourceCode",
    slug: "sourcecode",
    kind: "minio",
  },
  weight: {
    name: "Weight",
    slug: "weight",
    kind: "minio",
  },
  aiConfig: {
    name: "AIConfig",
    slug: "aiconfig",
    kind: "TopData",
  },
  papermillLog: {
    name: "PapermillLog",
    slug: "log",
    kind: "minio",
  },
  learningResult: {
    name: "LearningResult",
    slug: "learningResult",
    kind: "TopData",
  },
  predict: {
    name: "Predict",
    slug: "predict",
    kind: "TopData",
  },
};
