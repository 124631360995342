import { ASLowCodeFormDefinition } from "allegro-low-code-components";

export const editXFileFormDefinition: ASLowCodeFormDefinition = {
  sections: [
    {
      fields: [
        {
          label: "Text",
          name: "text",
          type: "multiline",
          values: undefined,
          required: true,
        },
      ],
    },
  ],
};
