import {
  Activity,
  ActivityInput,
  ActivityLevel,
  ActivityResource,
} from "allegro-api";
import { createNewtZeroAPI } from "src/api";

const activityResource: ActivityResource = "registry";
const defaultSlotTags = ["newtroid", "nxtal", activityResource];

/**
 * RegistryのActivityをPOSTする関数
 * @params level
 * @params message
 * @params registryId Acitivityの対象registryのID
 * @returns 書き込みに成功した場合、Acitivityを返却する
 */
export const saveRegistryActivity = async (
  level: ActivityLevel,
  message: string,
  registryId: string
): Promise<Activity | null> => {
  const newtzero = createNewtZeroAPI();
  const activityInput: ActivityInput = {
    level,
    message,
    tags: [...defaultSlotTags],
    resource: activityResource,
    resourceId: registryId,
  };

  const resActivity = await newtzero.activity
    .addActivity(activityInput)
    .catch(() => null);
  return resActivity;
};
