//Libs
import React from "react";
//Components
//States
//Types

import { OEPageTemplate } from "../templates";
import { OEActivityListTemplate } from "./OEActivityListTemplate";

export const OEActivityListPageTemplate: React.FC = () => {
  return (
    <OEPageTemplate>
      <OEActivityListTemplate />
    </OEPageTemplate>
  );
};
