import { OptXSchemaType } from "allegro-api";
import { useSwitchOptX } from "src/xerver-room/hooks/useSwitchOptX";
import { XROptXV2Details } from "../organisms/XROptXV2Details";
import { XROptXDetails } from "../molecules";
import { LCLoadingBoard } from "src/cheetah/components/libs/ASLowCodeBrowser";

type XROptXSingleTemplateProps = {
  optXId: string;
};

export const XROptXSingleTemplate: React.FC<XROptXSingleTemplateProps> = (
  props
) => {
  const { optXId } = props;
  const optx = useSwitchOptX(optXId);

  return (
    <>
      {optx ? (
        optx?.schemaType === OptXSchemaType.optxv2 ? (
          <XROptXV2Details optXV2={optx} />
        ) : (
          <XROptXDetails optX={optx} />
        )
      ) : (
        <LCLoadingBoard />
      )}
    </>
  );
};
