import { Controller, Control } from "react-hook-form";
import { ASFormDesign, ASTextFormField } from "../../models/ASLowCodeFormField";
import { ASTextArea } from "allegro-ui";
import { useState } from "react";
import { ASFormFieldBase } from "./ASFormFieldBase";
import styled from "styled-components";

const StyledTextArea = styled(ASTextArea)`
  line-height: 1.7;
  letter-spacing: 0.04em;
  padding: 16px 16px 23px 16px;
  resize: vertical;
  font-family: inherit;
  width: 100%;
`;

type ASFormFieldProps = {
  id?: number | string;
  control: Control;
  defaultValue?: string;
  error: any;
  editable?: boolean;
  active?: string;
  design?: ASFormDesign;
  supportText?: string;
  requiredMarker?: string;
  optionalMarker?: string;
  inputOnly?: boolean;
  onChange?: (text: string) => void;
};

export const ASMultilineTextFormField = (
  props: ASFormFieldProps & ASTextFormField
) => {
  const {
    id,
    label,
    name,
    required,
    control,
    active,
    values,
    editable,
    error,
    errorText,
    design,
    supportText,
    inputOnly,
    placeholder,
  } = props;
  const defaultValue =
    props.defaultValue && props.defaultValue !== ""
      ? props.defaultValue
      : active;

  // テキスト文字数によってフォームの行数を自動で合わせる最大・最小値
  const minRow = 4;
  const maxRow = 8;

  const [text, setText] = useState(defaultValue);
  return (
    <div key={`lc-create-data-form-${id}-${name}`}>
      {inputOnly ? (
        <Controller
          key={`as-text-form-controller-${id}-${label}-${defaultValue}`}
          name={name}
          control={control}
          rules={{ required: required }}
          defaultValue={defaultValue ?? active ?? undefined}
          render={({ field: { onChange } }) => (
            <StyledTextArea
              value={defaultValue}
              key={`as-text-form-component-${name}-${defaultValue}`}
              minRows={minRow}
              maxRows={maxRow}
              placeholder={placeholder ?? "入力してください"}
              disabled={!(editable ?? false)}
              kind={design === "digicho" ? "digicho" : "secondary"}
              onChange={(e: any) => {
                onChange(e);
              }}
            />
          )}
        />
      ) : (
        <ASFormFieldBase
          error={error !== undefined}
          errorText={errorText}
          label={label}
          design={design}
          required={required}
          editable={editable}
          supportText={supportText}
          requiredMarker={props.requiredMarker}
          optionalMarker={props.optionalMarker}
        >
          <Controller
            key={`as-text-form-controller-${id}-${label}-${defaultValue}`}
            name={name}
            control={control}
            rules={{ required: required }}
            defaultValue={defaultValue ?? active ?? undefined}
            render={({ field: { onChange } }) => (
              <StyledTextArea
                value={text}
                key={`as-text-form-component-${name}-${defaultValue}`}
                minRows={minRow}
                maxRows={maxRow}
                placeholder={placeholder ?? "入力してください"}
                disabled={!(editable ?? false)}
                kind={design === "digicho" ? "digicho" : "secondary"}
                onChange={(e: any) => {
                  setText(e.target.value);
                  onChange(e);
                }}
                error={error === true ? "true" : "false"}
              />
            )}
          />
        </ASFormFieldBase>
      )}
    </div>
  );
};
