//Libs
import { useCallback } from "react";
import produce from "immer";
import { createnXtalAPI } from "src/api";
import { useRecoilState, useSetRecoilState } from "recoil";

//Components

//States
import { Edge } from "react-flow-renderer";
import { requestEdgesIdState } from "../recoils/edges";
import { slotState, slotRequestId } from "../recoils";

//Types
import { OptXConf, Output, Target, defaultOptXConf } from "allegro-api";
import { Environments, Slot } from "../models";
import { OptXVariation } from "allegro-api";
import { saveSlotActivity } from "../activity-logger/saveSlotActivity";
import { useAuth } from "src/root";
import { useSwitchOptX } from "./useSwitchOptX";

export const useSlotActions = (slotId: string) => {
  const auth = useAuth();
  const [self, setSelf] = useRecoilState(slotState(slotId));
  const optX = useSwitchOptX(self.optXId ?? "");

  const [slotReqId, setSlotReqId] = useRecoilState(slotRequestId);
  const setEdgesRequestId = useSetRecoilState(requestEdgesIdState);

  const nxtal = createnXtalAPI();

  /**
   * スロットの変更によって再描画する関数
   */
  const notifySlotUpdates = () => {
    setSlotReqId((n) => n + 1);
  };

  /**
   * SlotにOptXの情報を格納する関数
   *
   */
  const setOptX = async (optXId: string) => {
    //ファイル名がopt-xのファイルを取得する。
    const optXConf: OptXConf =
      (await nxtal.optx
        .fetchOptXConf({
          contractorNo: sessionStorage.contractorNo,
          optXId,
        })
        .catch((err) => defaultOptXConf)) ?? defaultOptXConf;

    setSelf((s) =>
      produce(s, (draft) => {
        draft.optXId = optXId;

        const newEnvironments: Environments = Object.fromEntries(
          optXConf.environments.map((env) => {
            const value = draft.environments[env.name] ?? null;
            return [env.name, value];
          })
        );

        draft.environments = newEnvironments;

        draft.inputs = optXConf?.io.inputs;
        draft.outputs = optXConf?.io.outputs;

        draft.variationId = undefined;
        draft.dockerImageTag = undefined;
      })
    );

    notifySlotUpdates();
  };

  /**
   * SlotにOptXの情報を格納する関数
   * optx.registryIdからregistryを取得し、以下の4つの情報をslotに保存する
   * - optXId
   * - dockerRepositoryName
   * - registryId
   * - registryHost
   *
   * Environmentsを更新する
   *
   */
  const setOptXV2 = async (optXId: string) => {
    // optxを取得する
    const optx = await nxtal.optxv2.fetchOne(optXId).catch(() => null);

    // registryを取得する
    const registry = optx?.registryId
      ? await nxtal.registry.fetchOne(optx.registryId).catch(() => null)
      : null;

    //ファイル名がopt-xのファイルを取得する。
    const optXConf: OptXConf = defaultOptXConf;

    setSelf((s) =>
      produce(s, (draft) => {
        draft.optXId = optXId;
        draft.dockerRepositoryName = optx?.dockerRepositoryName;
        draft.registryId = optx?.registryId;
        draft.registryHost = registry?.host;

        const newEnvironments: Environments = Object.fromEntries(
          optXConf.environments.map((env) => {
            const value = draft.environments[env.name] ?? null;
            return [env.name, value];
          })
        );

        draft.environments = newEnvironments;

        draft.inputs = optXConf?.io.inputs;
        draft.outputs = optXConf?.io.outputs;

        draft.variationId = undefined;
        draft.dockerImageTag = undefined;

        draft.envMappings = {};
      })
    );

    notifySlotUpdates();
  };

  /**
   * OptX Variationを更新する関数
   */
  const handleChangeOptXVersion = async (
    optXId: string,
    optXVariation: OptXVariation
  ) => {
    const optXConf =
      (await nxtal.xfile
        .fetchOptXConf(optXId, optXVariation.variationId)
        .catch(() => null)) ?? defaultOptXConf;

    setSelf((prevSlot) => {
      return produce(prevSlot, (draft) => {
        draft.optXId = optXId;
        draft.variationId = optXVariation.variationId;
        draft.dockerImageTag = optXVariation.dockerImageTag;

        draft.inputs = optXConf.io.inputs;
        draft.outputs = optXConf.io.outputs;

        const newEnvironments: Environments = Object.fromEntries(
          optXConf.environments.map((env) => {
            const value = draft.environments[env.name] ?? null;
            return [env.name, value];
          })
        );
        draft.environments = newEnvironments;

        draft.envMappings = optXConf.envMappings;
      });
    });

    notifySlotUpdates();
  };

  /**
   * Slotの位置情報を更新する関数
   */
  const updatePosition = useCallback(
    async (x: number, y: number) => {
      setSelf((currentSelf) =>
        produce(currentSelf, (draft) => {
          draft.ui = {
            ...currentSelf.ui,
            x,
            y,
          };
        })
      );
      // await saveSlotActivity(
      //   "info",
      //   `Slot:${slotId}の位置情報を更新しました。`,
      //   self.pipelineId,
      //   slotId
      // );
    },
    [setSelf]
  );

  /**
   * 出力端子の接続情報を追加する関数
   */
  const addConnection = useCallback(
    async (edge: Edge) => {
      setSelf((s) =>
        produce(s, (draft) => {
          const myIOName = edge.sourceHandle ?? "";

          const myIO = s.outputs.find((o) => o.name === myIOName);
          if (!myIO) {
            console.error("ERROR:　addConnectionプログラムが不正です。");
            return;
          }
          const newTarget: Target = {
            slotId: edge.target,
            name: edge.targetHandle ?? "",
          };

          const evenTarget = (t1: Target, t2: Target) =>
            t1.name === t2.name && t1.slotId === t2.slotId;

          const newTargets = (() => {
            let result: Target[] = Array.isArray(myIO.targets)
              ? myIO.targets
              : [];

            //すでに存在するか確認する。
            const existAlready: boolean = result.some((t) =>
              evenTarget(t, newTarget)
            );
            //ない場合は新しくターゲットを追加する
            if (!existAlready) {
              result = [...result, newTarget];
            }
            return result;
          })();

          const newOutput: Output = {
            name: myIOName,
            targets: newTargets,
          };

          //@todo outputの複数対応
          draft.outputs = draft.outputs.map((output) =>
            output.name === myIOName ? newOutput : output
          );
        })
      );

      await saveSlotActivity(
        "info",
        `Slot:${slotId}の接続情報を編集しました。`,
        self.pipelineId,
        slotId
      );

      // await _reload();
      setEdgesRequestId((n) => n + 1);
      // setSlotReqId(slotReqId + 1);
      return true;
    },
    [self.pipelineId, setEdgesRequestId, setSelf, slotId]
  );

  /**
   * 出力端子の接続情報を削除する関数
   */
  const removeConnection = useCallback(
    async (
      outputIOName: string,
      targetIOName: string,
      targetSlotId: string
    ) => {
      setSelf((slot) =>
        produce(slot, (draft) => {
          const evenTarget = (t1: Target, t2: Target) =>
            t1.name === t2.name && t1.slotId === t2.slotId;

          //inputNameからこのスロットのアウトプットを取得する

          //アウトプットに繰り返す
          let io = slot.outputs.find((output) => {
            return output.name === outputIOName;
          });

          if (!io) {
            console.error("削除するIOが見つかりません。");
            return;
          }

          const targetToRemove: Target = {
            name: targetIOName,
            slotId: targetSlotId,
          };

          const filterd = io?.targets.filter(
            (target) => !evenTarget(targetToRemove, target)
          );

          draft.outputs = draft.outputs.map((output) =>
            output.name === outputIOName
              ? { name: outputIOName, targets: filterd }
              : output
          );
        })
      );

      await saveSlotActivity(
        "info",
        `Slot:${slotId}の接続情報を削除しました。`,
        self.pipelineId,
        slotId
      );

      // await _reload();
      setEdgesRequestId((n) => n + 1);
    },
    [self.pipelineId, setEdgesRequestId, setSelf, slotId]
  );

  /**
   * 出力端子の接続情報をリセットする関数
   */
  const resetConnections = useCallback(async () => {
    setSelf((s) =>
      produce(s, (draft) => {
        draft.environments = {
          ...self.environments,
          ...{ ALLEFAVO_TARGET_URL: "" },
        };
        draft.outputs[0].targets = [];
      })
    );
    // await _reload();
    setSlotReqId(slotReqId + 1);
  }, [self.environments, setSelf, setSlotReqId, slotReqId]);

  /**
   * Slotの名称を変更する関数
   */
  const updateName = useCallback(
    async (name: string) => {
      setSelf((s) =>
        produce(s, (draft) => {
          draft.name = name;
          // draft.pipelineName =
          //   cursorPipeline.state === "hasValue"
          //     ? cursorPipeline.contents?.name ?? "unknown"
          //     : "unknown";
        })
      );

      await saveSlotActivity(
        "info",
        `Slot:${slotId}の名称を編集しました。`,
        self.pipelineId,
        slotId
      );

      setSlotReqId(slotReqId + 1);
    },
    [self.pipelineId, setSelf, setSlotReqId, slotId, slotReqId]
  );

  /**
   * サーバー情報を更新する関数
   */
  const updateServerId = useCallback(
    async (serverId: string) => {
      setSelf((s) =>
        produce(s, (draft) => {
          draft.serverId = serverId;
        })
      );

      await saveSlotActivity(
        "info",
        `Slot:${slotId}のサーバー情報を編集しました。`,
        self.pipelineId,
        slotId
      );

      setSlotReqId(slotReqId + 1);
    },
    [self.pipelineId, setSelf, setSlotReqId, slotId, slotReqId]
  );

  /**
   * Slotを起動する関数
   */
  const requestStart = async () => {
    await nxtal.xervice.update(self.id, {
      status: {
        desire: "run",
        current: "stop",
      },
    });

    await saveSlotActivity(
      "info",
      `Slot:${slotId}の起動に成功しました。`,
      self.pipelineId,
      slotId
    );
  };

  /**
   * Slotを停止する関数
   */
  const requestStop = async () => {
    await nxtal.xervice.update(self.id, {
      status: {
        desire: "stop",
        current: "run",
      },
    });

    await saveSlotActivity(
      "info",
      `Slot:${slotId}の停止に成功しました。`,
      self.pipelineId,
      slotId
    );
  };

  const setSlot = (slot: Slot) => {
    setSelf(slot);
  };

  /**
   * 環境変数を保存する関数
   */
  const saveEnvironments = async (environments: Environments) => {
    const postEnvironmets = Object.fromEntries(
      Object.entries(environments).map(([key, val]) => {
        return [key, val !== undefined ? val : null];
      })
    );

    setSelf((s) =>
      produce(s, (draft) => {
        draft.environments = { ...draft.environments, ...postEnvironmets };
      })
    );

    await saveSlotActivity(
      "info",
      `Slot:${slotId}の環境変数を編集しました。`,
      self.pipelineId,
      slotId
    );

    setSlotReqId(slotReqId + 1);
  };

  /**
   * ボリューム情報を保存する関数
   */
  const saveVolume = async (volume: string) => {
    setSelf((s) =>
      produce(s, (draft) => {
        draft.volume = volume;
      })
    );

    await saveSlotActivity(
      "info",
      `Slot:${slotId}のボリューム情報を編集しました。`,
      self.pipelineId,
      slotId
    );

    // await _reload();
    setSlotReqId(slotReqId + 1);
  };

  /**
   * ポート情報を保存する関数
   */
  const savePort = async (port: string) => {
    const portNum = Number(port);
    if (isNaN(portNum)) {
      return;
    }
    setSelf((s) =>
      produce(s, (draft) => {
        draft.inputs[0].portOutside = portNum;
      })
    );

    await saveSlotActivity(
      "info",
      `Slot:${slotId}のポート情報を編集しました。`,
      self.pipelineId,
      slotId
    );

    setSlotReqId(slotReqId + 1);
  };

  /**
   * 複数のポート情報を保存する関数
   */
  const savePorts = async (ports: string[]) => {
    ports.forEach((port, id) => {
      const portNum = Number(port);
      if (isNaN(portNum)) {
      } else {
        setSelf((s) =>
          produce(s, (draft) => {
            draft.inputs[id].portOutside = portNum;
          })
        );
      }
    });

    await saveSlotActivity(
      "info",
      `Slot:${slotId}のポート情報を編集しました。`,
      self.pipelineId,
      slotId
    );

    // await _reload();
    setSlotReqId(slotReqId + 1);
  };

  /**
   * 入力端子を保存する関数
   */
  const saveInputs = async (formData: { [key: string]: string }) => {
    setSelf((s) =>
      produce(s, (draft) => {
        draft.inputs.forEach((input, id) => {
          draft.inputs[id].portOutside =
            formData[`port${id}`] !== ""
              ? Number(formData[`port${id}`])
              : undefined;
          draft.inputs[id].customDomain =
            formData[`customDomain${id}`] !== ""
              ? formData[`customDomain${id}`]
              : undefined;
          draft.inputs[id].usePrivateGateway =
            formData[`usePrivateGateway${id}`] === "はい"; //@TODO はいで判定するとi8n対応時にエラーとなる。
        });
      })
    );

    await saveSlotActivity(
      "info",
      `Slot:${slotId}のNetwork情報を編集しました。`,
      self.pipelineId,
      slotId
    );
  };

  /**
   * スロットを削除する関数
   */
  const deleteSlot = async (slot: Slot) => {
    // hooksからの操作だと更新されないのでページに直書きした
    // slotList.deleteSlot(slot);
    if (slot !== undefined) {
      try {
        //@todo リファァクタリング
        await nxtal.xervice.deleteOne(slot.id);

        // Activityの保存
        await saveSlotActivity(
          "info",
          `Slot:${slotId}を削除しました。`,
          self.pipelineId,
          slotId
        );

        setSlotReqId(slotReqId + 1);
      } catch (err) {
        console.error(err);
      }
    }
    setSlotReqId(slotReqId + 1);
  };

  return {
    ...self,

    optX,
    setOptX,
    setOptXV2,
    handleChangeOptXVersion,
    requestStart,
    requestStop,
    setSlot,
    deleteSlot,
    addConnection,
    removeConnection,
    updatePosition,
    resetConnections,
    saveEnvironments,
    saveVolume,
    savePort,
    savePorts,
    saveInputs,
    updateName,
    updateServerId,
  };
};
