import produce from "immer";

export type Input = {
  name: string;
  url?: string;
  portInside: number;
  portOutside?: number;
  type?: "http" | "tcp";
  usePrivateGateway: boolean;
  customDomain?: string;
};

/**
 * Inputのエイリアス
 */
export type Expose = Input;

export type Target = {
  slotId: string;
  name: string;
};

export type Output = {
  name: string;
  targets: Target[];
  type?: "http" | "tcp";
};

export type EnvironmentVariables = string[];

export type Environments = { [key: string]: string | number | null };

type ViewModel = {
  serverName: string;
  showLogs: boolean;
};

export type Slot = ViewModel & {
  id: string;
  serverId: string;
  contractor: string;
  pipelineId: string;
  pipelineName: string;
  ip: string;
  slotId: string;
  optXId: string | undefined;
  name: string;
  registryUrl: string | null;
  serviceUrl: string | null;
  inputs: Array<Input>;
  outputs: Array<Output>;
  environments: Environments;
  volume: string | null;

  status: {
    desire: "run" | "stop" | "unknown";
    current: "run" | "stop" | "unknown";
  };
  ui: {
    x: number;
    y: number;
  };
};

export const defaultSlot: Slot = {
  id: "",
  serverId: "",
  contractor: "",
  ip: "",
  pipelineId: "",
  pipelineName: "",
  serverName: "",
  slotId: "",
  optXId: undefined,
  name: "",
  registryUrl: null,
  serviceUrl: null,
  showLogs: true,
  inputs: [],
  outputs: [],
  environments: {},
  volume: null,
  status: {
    desire: "stop",
    current: "stop",
  },
  ui: {
    x: 0,
    y: 0,
  },
};

export const createSlot = (props: Partial<Slot>) => {
  return produce(defaultSlot, (draft) => {
    draft.id = props.slotId ?? draft.id;
    draft.slotId = props.slotId ?? draft.id;
    draft.name = props.name ?? draft.name;
    draft.contractor = props.contractor ?? draft.contractor;
    draft.pipelineName = props.pipelineName ?? draft.pipelineName ?? "unknown";
    draft.serverId = props.serverId ?? draft.serverId;
    draft.inputs =
      Array.isArray(props.inputs) && props.inputs !== null
        ? props.inputs
        : draft.inputs;
    draft.optXId = props.optXId;
    draft.pipelineId = props.pipelineId ?? "unknown";
    draft.showLogs = true;
    draft.outputs =
      props.outputs instanceof Array && props.outputs !== null
        ? props.outputs
        : draft.outputs;
    draft.status.desire = props.status?.desire ?? "unknown";
    draft.status.current = props.status?.current ?? "unknown";
    draft.ui.x = props.ui?.x ?? 0;
    draft.ui.y = props.ui?.y ?? 0;

    draft.ip = props.ip ?? "none";
    draft.environments = props.environments ?? {};
    draft.volume = props.volume ?? null;
  });
};
