import { ASLowCodeFormDefinition } from "allegro-low-code-components";
import { createNewtZeroAPI } from "src/api";
import { newtOut } from "src/fortress/api";

export const assetCreatingFormDefinition: ASLowCodeFormDefinition = {
  sections: [
    {
      title: "Asset Settings",
      description: "Configure assets.",
      fields: [
        {
          label: "Asset Name",
          name: "name",
          type: "text",
          active: "",
          values: undefined,
          required: true,
          errorText: "Asset Name is required.",
        },
        {
          label: "Tags",
          name: "tags",
          type: "chip",
          active: [],
          values: undefined,
          required: true,
          errorText: "Tags is required.",
        },
        {
          label: "Asset Type",
          name: "assetType",
          type: "selection",
          active: "GENERAL",
          values: ["GENERAL", "TABLE"],
          required: true,
          errorText: "Asset Type is required.",
        },

        {
          label: "Collection Name",
          name: "collectionName",
          type: "text",
          active: "TopData",
          values: undefined,
        },

        {
          label: "Unique Keys",
          name: "uniqueKeys",
          type: "chip",
          values: undefined,
        },

        {
          label: "Publication",
          supportText:
            "If set to public, the asset will be available to other contractors.",
          name: "shareLevel",
          type: "selection",
          active: "private",
          values: ["public", "private"],
        },

        {
          label: "CMS Config",
          supportText: "view only.",
          name: "cmsConfig",
          type: "multiline",
          values: undefined,
        },

        // {
        //   label: "データの読み込み公開範囲",
        //   supportText:
        //     "publicにした場合、systemコントラクタで登録したデータが他のコントラクタから読み込むことができます。",
        //   name: "readablePublicAssetData",
        //   type: "selection",
        //   active: "private",
        //   values: ["public", "private"],
        // },
        {
          label: "Authorities",
          name: "権限",
          type: "section",
          supportText: "",
          active: [],
          values: undefined,
          required: true,
          errorText: "",
        },
        {
          label: "Allow Read Users",
          name: "allowReadUsers",
          type: "chip",
          supportText:
            "Please enter * to allow access to all contractor users.",
          active: [],
          values: undefined,
          required: true,
          errorText: "required read user.",
        },
        {
          label: "Allow Write Users",
          name: "allowWriteUsers",
          type: "chip",
          supportText:
            "Please enter * to allow access to all contractor users.",
          active: [],
          values: undefined,
          required: true,
          errorText: "required write user.",
        },
        {
          label: "Allow Read Groups",
          name: "allowReadGroups",
          type: "chip",
          supportText: "",
          active: [],
          values: undefined,
          required: false,
          errorText: "required",
        },
        {
          label: "Allow Write Groups",
          name: "allowWriteGroups",
          type: "chip",
          supportText: "",
          active: [],
          values: undefined,
          required: false,
          errorText: "required",
        },

        {
          label: "ProviderAPI",
          name: "ProviderAPI",
          type: "section",
          supportText: "",
          active: [],
          values: undefined,
          required: true,
          errorText: "",
        },

        {
          label: "enable",
          name: "provider-enable",
          type: "radio",
          supportText: "Enables access to data by ProviderAPI.",
          active: "Disable",
          values: ["Enable", "Disable"],
          required: true,
          errorText: "",
        },

        {
          label: "Free Access",
          name: "provider-freeAccess",
          type: "radio",
          supportText:
            "If you check this item, the data will be treated as open data that anyone can access. Please handle with care. ",
          active: "Disable",
          values: ["Enable", "Disable"],
          required: true,
          errorText: "",
        },

        {
          label: "Enable Access Token",
          name: "provider-enableAccessToken",
          type: "radio",
          supportText:
            "If this item is enabled, authentication using an access token will be required. Access to this asset is restricted to users with the access token.",
          active: "Disable",
          values: ["Enable", "Disable"],
          required: true,
          errorText: "",
        },

        {
          label: "Access Token",
          name: "provider-accessToken",
          type: "text",
          supportText: "",
          active: "",
          values: undefined,
          required: false,
          errorText: "",
        },

        {
          label: "NewtQuery Modifier",
          name: "provider-newtQueryModifier",
          type: "text",
          supportText:
            "If accessToken is not enabled, only the any specification for shpe is valid. If you want to specify operations such as join, please specify the query here.",
          active: "",
          values: undefined,
          required: false,
          errorText: "",
        },
        {
          label: "allowUsers",
          name: "provider-allowUsers",

          type: "chip",
          supportText:
            "Please enter * to allow access to all contractor users.",
          active: [],
          values: undefined,
          required: false,
          errorText: "required this field.",
        },
        {
          label: "Allow Groups",
          name: "provider-allowGroups",
          type: "chip",
          supportText:
            "Please enter * to allow access to all contractor users.",
          active: [],
          values: undefined,
          required: false,
          errorText: "required this field.",
        },
        {
          label: "Disable AssetId Protection",
          name: "provider-disableAssetIdProtection",
          type: "radio",
          supportText:
            "Valid only when asset type is TABLE. When enabled, access to this asset will allow access to the data in the table.",
          active: "Disable",
          values: ["Enable", "Disable"],
          required: true,
          errorText: "",
        },

        {
          label: "StreamGateAPI",
          name: "StreamGateAPI",
          type: "section",
          supportText: "",
          active: [],
          values: undefined,
          required: true,
          errorText: "",
        },

        {
          label: "enable",
          name: "streamgate-enable",
          type: "radio",
          supportText: "Enables access to data by ProviderAPI.",
          active: "Disable",
          values: ["Enable", "Disable"],
          required: true,
          errorText: "",
        },

        {
          label: "Free Access",
          name: "streamgate-freeAccess",
          type: "radio",
          supportText:
            "If you check this item, the data will be treated as open data that anyone can access. Please handle with care. ",
          active: "Disable",
          values: ["Enable", "Disable"],
          required: true,
          errorText: "",
        },

        {
          label: "Enable Access Token",
          name: "streamgate-enableAccessToken",
          type: "radio",
          supportText:
            "If this item is enabled, authentication using an access token will be required. Access to this asset is restricted to users with the access token.",
          active: "Disable",
          values: ["Enable", "Disable"],
          required: true,
          errorText: "",
        },

        {
          label: "Access Token",
          name: "streamgate-accessToken",
          type: "text",
          supportText: "",
          active: "",
          values: undefined,
          required: false,
          errorText: "",
        },

        {
          label: "Allow Users",
          name: "streamgate-allowUsers",

          type: "chip",
          supportText:
            "Please enter * to allow access to all contractor users.",
          active: [],
          values: undefined,
          required: false,
          errorText: "required this field.",
        },
        {
          label: "Allow Groups",
          name: "streamgate-allowGroups",
          type: "chip",
          supportText:
            "Please enter * to allow access to all contractor users.",
          active: [],
          values: undefined,
          required: false,
          errorText: "required this field.",
        },
        {
          label: "Disable AssetId Protection",
          name: "streamgate-disableAssetIdProtection",
          type: "radio",
          supportText:
            "Valid only when asset type is TABLE. When enabled, access to this asset will allow access to the data in the table.",
          active: "Disable",
          values: ["Enable", "Disable"],
          required: true,
          errorText: "",
        },

        {
          label: "DeleteDataAPI",
          name: "DeleteDataAPI",
          type: "section",
          supportText: "",
          active: [],
          values: undefined,
          required: true,
          errorText: "",
        },

        {
          label: "enable",
          name: "deletedata-enable",
          type: "radio",
          supportText: "Enables access to data by ProviderAPI.",
          active: "Disable",
          values: ["Enable", "Disable"],
          required: true,
          errorText: "",
        },

        {
          label: "Free Access",
          name: "deletedata-freeAccess",
          type: "radio",
          supportText:
            "If you check this item, the data will be treated as open data that anyone can access. Please handle with care. ",
          active: "Disable",
          values: ["Enable", "Disable"],
          required: true,
          errorText: "",
        },

        {
          label: "Enable AccessToken",
          name: "deletedata-enableAccessToken",
          type: "radio",
          supportText:
            "If this item is enabled, authentication using an access token will be required. Access to this asset is restricted to users with the access token.",
          active: "Disable",
          values: ["Enable", "Disable"],
          required: true,
          errorText: "",
        },

        {
          label: "Access Token",
          name: "deletedata-accessToken",
          type: "text",
          supportText: "",
          active: "",
          values: undefined,
          required: false,
          errorText: "",
        },

        {
          label: "Allow Users",
          name: "deletedata-allowUsers",

          type: "chip",
          supportText:
            "Please enter * to allow access to all contractor users.",
          active: [],
          values: undefined,
          required: false,
          errorText: "required this field.",
        },
        {
          label: "Allow Groups",
          name: "deletedata-allowGroups",
          type: "chip",
          supportText:
            "Please enter * to allow access to all contractor users.",
          active: [],
          values: undefined,
          required: false,
          errorText: "required this field.",
        },
        {
          label: "Disable AssetId Protection",
          name: "deletedata-disableAssetIdProtection",
          type: "radio",
          supportText:
            "Valid only when asset type is TABLE. When enabled, access to this asset will allow access to the data in the table.",
          active: "Disable",
          values: ["Enable", "Disable"],
          required: true,
          errorText: "",
        },

        // {
        //   label: "オリジナル日付フィールド名",
        //   name: "timeFieldMetric",
        //   type: "text",
        //   active: "",
        //   values: undefined,
        // },

        // {
        //   label: "シャードキー(beta)",
        //   name: "shardkey",
        //   type: "text",
        //   active: "",
        //   values: undefined,
        // },

        {
          label: "id",
          name: "id",
          type: "skelton",
          active: "",
          values: undefined,
        },
      ],
    },
  ],
  onValid: async (d, options) => {
    // validation
    // if (d["assetType"] === "TABLE") {
    //   return {
    //     error: "ERROR",
    //     message: "assetType TABLEはsystemコントラクタのみが編集可能です。",
    //   };
    // }

    if (
      d["provider-enableAccessToken"] === "Enable" &&
      d["provider-accessToken"] === ""
    ) {
      return {
        error: "ERROR",
        message: "When checked EnableAccessToken, AccessToken is required.",
      };
    }

    if (
      d["assetType"] !== "TABLE" &&
      d["provider-disableAssetIdProtection"] === "Enable"
    ) {
      return {
        error: "ERROR",
        message: "disableAssetIdProtection is only allowed TABLE asset.",
      };
    }

    if (options?.isUpdate) {
      //更新時
      return {};
    } else {
      //新規作成

      const [fetchAsset] = await newtOut({
        collection: "Asset",
        limit: 1,
        primaryKey: "name",
        search: {
          name: d.name,
        },
        shape: {
          name: "any",
        },
      });

      if (fetchAsset.length > 0) {
        return {
          error: "duplicated name",
          message: "同じアセット名が既に存在します。",
        };
      }
    }
    return {};
  },
  onSubmit: async (d, options) => {
    //互換性のあるアセットを作成する。
    console.log("update asset.");

    // const sendToTags = d["dataSavingCollection"].map(
    //   (collectionName: string) => `SendTo${collectionName}`
    // );

    let tags: string[] = d.tags;
    let uniqueKeys: string[] = d.uniqueKeys ?? [];
    uniqueKeys = uniqueKeys.filter(
      (uniqueKey: string, index) => uniqueKeys.indexOf(uniqueKey) === index
    );

    // if (d.uniqueKeys) {
    //   const parsedUniqueKeys = d.uniqueKeys.map(
    //     (keyName: string) => `key:${keyName}`
    //   );
    //   tags = [...tags, ...parsedUniqueKeys];
    //   tags = tags.filter((tag, index) => {
    //     return tags.indexOf(tag) === index;
    //   });
    // }

    //sendToTagsを追加する。
    // for (const sendToTag of sendToTags) {
    //   if (!tags.includes(sendToTag)) {
    //     tags.push(sendToTag);
    //   }
    // }

    const body = {
      name: d["name"],
      id: d["id"],
      tags,
      userNoText: sessionStorage.userNoText,
      // pipelineName: "fortress",
      // rawQuePartitions: d["rawQuePartitions"],
      // txnQuePartitions: d["txnQuePartitions"],
      // topQuePartitions: d["topQuePartitions"],
      // timeFieldMetric: d["timeFieldMetric"],
      // readers: d["readers"],
      // writers: d["writers"],

      assetType: d["assetType"],
      cmsConfig: d["cmsConfig"],

      /**
       * アセット自体の操作権限
       */
      allowReadUsers: d["allowReadUsers"],
      allowReadGroups: d["allowReadGroups"],
      allowWriteUsers: d["allowWriteUsers"],
      allowWriteGroups: d["allowWriteGroups"],

      providerAPIConfig: {
        enable: d["provider-enable"] === "Enable",
        freeAccess: d["provider-freeAccess"] === "Enable",
        enableAccessToken: d["provider-enableAccessToken"] === "Enable",
        accessToken: d["provider-accessToken"],
        newtQueryModifier: d["provider-newtQueryModifier"],
        allowUsers: d["provider-allowUsers"],
        allowGroups: d["provider-allowGroups"],
        disableAssetIdProtection:
          d["provider-disableAssetIdProtection"] === "Enable",
      },
      streamGateAPIConfig: {
        enable: d["streamgate-enable"] === "Enable",
        freeAccess: d["streamgate-freeAccess"] === "Enable",
        enableAccessToken: d["streamgate-enableAccessToken"] === "Enable",
        accessToken: d["streamgate-accessToken"],
        allowUsers: d["streamgate-allowUsers"],
        allowGroups: d["streamgate-allowGroups"],
        disableAssetIdProtection:
          d["streamgate-disableAssetIdProtection"] === "Enable",
      },
      deleteDataAPIConfig: {
        enable: d["deletedata-enable"] === "Enable",
        freeAccess: d["deletedata-freeAccess"] === "Enable",
        enableAccessToken: d["deletedata-enableAccessToken"] === "Enable",
        accessToken: d["deletedata-accessToken"],
        allowUsers: d["deletedata-allowUsers"],
        allowGroups: d["deletedata-allowGroups"],
        disableAssetIdProtection:
          d["deletedata-disableAssetIdProtection"] === "Enable",
      },

      collectionName: d.collectionName,
      uniqueKeys: uniqueKeys,
      shareLevel: d.shareLevel,
      // readablePublicAssetData: d.readablePublicAssetData === "public",
    };
    try {
      const newtzero = createNewtZeroAPI();
      const res = await newtzero.asset.save(body, true);

      if (res.status === 200) {
        window.location.reload();
      }
    } catch (err: any) {
      if (err.response) {
        return err.response.data;
      }
      console.error(err);
    }
    return null;
  },
};
