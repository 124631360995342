import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import { optXSingleFetcherState, optXState, optXV2State } from "../recoils";

/**
 * OptXV2とOptXに対応したhooks
 */
export const useSwitchOptX = (optXId: string) => {
  const [isInitialized, setIsInitialized] = useState(false);

  const optXSingleLoadable = useRecoilValueLoadable(
    optXSingleFetcherState(optXId)
  );
  const [, setOptXV1] = useRecoilState(optXState(optXId));
  const [, setOptXV2] = useRecoilState(optXV2State(optXId));

  const optX =
    optXSingleLoadable.state === "hasValue"
      ? optXSingleLoadable.contents
      : null;

  const initialize = useCallback(async () => {
    if (
      optXSingleLoadable.state === "hasValue" &&
      optXSingleLoadable.contents
    ) {
      const optXSingle = optXSingleLoadable.contents;

      switch (optXSingle.schemaType) {
        case "optxv2": {
          setOptXV2(optXSingle);
          break;
        }
        case "optxv1": {
          setOptXV1(optXSingle);
          break;
        }
      }
    }
  }, [
    optXSingleLoadable.contents,
    optXSingleLoadable.state,
    setOptXV1,
    setOptXV2,
  ]);

  useEffect(() => {
    if (!isInitialized) {
      initialize();
      setIsInitialized(true);
    }
  }, [initialize, isInitialized]);

  return optX;
};
