import produce from "immer";
import { BasesSchema } from "./Base";

export enum CICDVarTypes {
  string = "string",
  secrets = "secrets",
}

export type Credential = {
  userName: string;
  password: string;
};

export type Registry = {
  registryId: string;
  name: string;
  host: string;
  version: string;
  credential: Credential;

  shareLevel: "public" | "private";

  allowReadUsers: string[];
  allowReadGroups: string[];
  allowWriteUsers: string[];
  allowWriteGroups: string[];
} & BasesSchema;

export type RegistryInput = {
  name: string;
  host: string;
  version: string;
  credential: Credential;
  shareLevel: "public" | "private";
  allowReadUsers: string[];
  allowReadGroups: string[];
  allowWriteUsers: string[];
  allowWriteGroups: string[];
};

export const defaultRegistry: Registry = {
  registryId: "",
  name: "",
  host: "",
  version: "",
  credential: {
    userName: "",
    password: "",
  },
  shareLevel: "private",

  allowReadUsers: ["*"],
  allowReadGroups: [],
  allowWriteUsers: ["*"],
  allowWriteGroups: [],

  created: new Date(),
  modified: new Date(),
  contractorNo: "",
  createdBy: "",
  readers: ["*"],
  writers: ["*"],
};

export const createRegistryFromNewtData = (data: {
  [key: string]: any;
}): Registry => {
  return produce(defaultRegistry, (draft) => {
    const rawdata = data.contents?.rawdata;
    const bases = data.bases;
    draft.registryId = rawdata?.registryId ?? draft.registryId;
    draft.name = rawdata?.name ?? draft.name;
    draft.host = rawdata?.host ?? draft.host;
    draft.version = rawdata?.version ?? draft.version;

    const credential = rawdata?.credential;
    draft.credential = {
      userName: credential?.userName,
      password: credential?.password,
    };

    draft.shareLevel = data.contents.rawdata.shareLevel ?? draft.shareLevel;
    draft.allowReadUsers =
      data.contents.rawdata.allowReadUsers ?? draft.allowReadUsers;
    draft.allowReadGroups =
      data.contents.rawdata.allowReadGroups ?? draft.allowReadGroups;
    draft.allowWriteUsers =
      data.contents.rawdata.allowWriteUsers ?? draft.allowWriteUsers;
    draft.allowWriteGroups =
      data.contents.rawdata.allowWriteGroups ?? draft.allowWriteGroups;

    draft.contractorNo = bases?.contractorNo ?? draft.contractorNo;
    draft.createdBy = bases?.userNo;
    draft.created = bases?.created;
    draft.modified = bases?.modified;

    draft.readers = data.readers ?? draft.readers;
    draft.writers = data.writers ?? draft.writers;
  });
};
