import { ASIconButton } from "allegro-ui";
import React from "react";
import styled from "styled-components";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  color: ${(props) => props.theme.secondaryOnText};
  padding: 0 24px;
`;

const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.secondaryBorderColor};
  height: 90px;
`;

const ContentBody = styled.div`
  padding: 24px 0;
`;

const ButtonGroup = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin-left: 8px;
  }
`;

const PageTitle = styled.h1`
  font-size: 24px;
  font-weight: bold;
`;

const BackButton = styled(ASIconButton)`
  width: 28px;
  height: 28px;
  margin-right: 16px;
`;

type Props = {
  title?: string | React.ReactNode;
  headerComponents?: React.ReactNode;
  withBackButton?: boolean;
  children?: React.ReactNode;
};

export const XRPageTemplate: React.FC<Props> = (props) => {
  const history = useNavigate();
  const backPage = () => {
    history(-1);
  };
  return (
    <Container>
      <ContentHeader>
        {props.withBackButton ? (
          <BackButton size="small" onClick={backPage}>
            <ArrowBackIcon fontSize="inherit" />
          </BackButton>
        ) : null}
        <PageTitle>{props.title}</PageTitle>

        <ButtonGroup>{props.headerComponents}</ButtonGroup>
      </ContentHeader>
      <ContentBody>{props.children}</ContentBody>
    </Container>
  );
};
