import { Paper } from "@material-ui/core";

import styled from "styled-components";
import { useManagementList } from "src/opt-editor/hooks/useManagementList";
import { ASLowCodeDataBrowserContainer } from "src/cheetah/components/libs/ASLowCodeBrowser";

const ManagementView = styled(Paper)`
  width: 100%;
  /* padding: 20px; */
  background-color: ${(props) => props.theme.bgPrimaryColor};
  overflow: scroll;
`;

export const OEManagementListTemplate = () => {
  const { browserUi } = useManagementList();
  return (
    <ManagementView>
      <ASLowCodeDataBrowserContainer
        name={`oe-management-list-page`}
        uiConf={browserUi}
      />
    </ManagementView>
  );
};
