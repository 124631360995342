import { ASLowCodeFormDefinition } from "allegro-low-code-components";
import { fetchOptXVariationsByVersion } from "src/xerver-room/api/fetchOptXVariations";

export const createSelectOptXVersionFromDefinition = (
  optXId: string
): ASLowCodeFormDefinition => {
  return {
    sections: [
      {
        fields: [
          {
            label: "Version",
            name: "version",
            type: "text",
            values: async (inputText) => {
              const variations = await fetchOptXVariationsByVersion(optXId, {
                version: inputText,
                variationId: inputText,
              }).catch(() => []);

              return variations.map((variation) => {
                return {
                  label: variation.version,
                  value: variation.version,
                };
              });
            },
          },
        ],
      },
    ],
  };
};
