import React from "react";

import { NTInfrastructurePageTemplate } from "../templates/NTInfrastructurePageTemplate";
import { NTMainViewTemplate } from "../templates/NTMainViewTemplate";

export const NTInfrastructurePage: React.FC = () => {
  return (
    <NTMainViewTemplate>
      <NTInfrastructurePageTemplate />
    </NTMainViewTemplate>
  );
};
