import { XROptXSingleTemplate } from "src/xerver-room/components/templates/XROptXSingleTemplates";
import { NTMainViewTemplate } from "../templates/NTMainViewTemplate";
import { useParams } from "react-router-dom";

type Params = {
  optXId: string;
};

export const NTOptXSinglePage: React.FC = () => {
  const { optXId } = useParams<Params>();

  return (
    <NTMainViewTemplate>
      <XROptXSingleTemplate optXId={optXId ?? ""} />
    </NTMainViewTemplate>
  );
};
