import { selector, selectorFamily } from "recoil";
import { cursorBundleState } from "./cursorBundleState";
import { fetchData } from "src/api/bundles";
import { createNewtZeroAPI } from "src/api";
import { NewtQuery } from "allegro-api";

export const datasetCountState = selectorFamily<any, string>({
  key: "datasetCountState",
  get:
    (key) =>
    async ({ get }) => {
      const newtzero = createNewtZeroAPI();
      const bundle = get(cursorBundleState);

      if (!bundle) {
        return "--";
      }

      const reference = bundle?.references.find((ref) => ref.slug === key);

      if (!reference) {
        return "--";
      }

      const assetId = reference.object?.assetId ?? "";
      const newtQuery: NewtQuery = {
        assetId: assetId,
        primaryKey: "_id",
        shape: { _id: "any" },
        count: "true",
        limit: 100000,
      };

      const data = await newtzero.dataProvider(newtQuery);

      console.log({ data });
      const count = data.length > 0 ? data[0].totalDataCount : 0;
      console.log({ count });
      return count ?? 0;
    },
});

export const learningResultsState = selector({
  key: "learningResultsState",
  get: async ({ get }) => {
    const bundle = get(cursorBundleState);
    if (!bundle) {
      return null;
    }

    const reference = bundle?.references.find(
      (ref) => ref.slug === "learningResult"
    );

    if (!reference) {
      return null;
    }

    const newtQuery = {
      limit: 1,
      shape: {
        "contents.rawdata": "any",
      },
    };

    try {
      const res = await fetchData({
        bundleId: bundle.bundleId,
        version: bundle.version,
        refName: reference.name,
        newtQuery,
      });

      return res.message.doc.length > 0
        ? res.message.doc[0].contents.rawdata
        : null;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
});

export const papermillNotebookState = selector({
  key: "papermillNotebookState",
  get: async ({ get }) => {
    const bundle = get(cursorBundleState);
    if (!bundle) {
      return null;
    }

    const reference = bundle?.references.find((ref) => ref.slug === "log");

    if (!reference) {
      return null;
    }

    try {
      const metaNewtQuery = {
        path: "/",
        metadata: "true",
        recursive: "true",
      };

      const metaRes = await fetchData({
        bundleId: bundle.bundleId,
        version: bundle.version,
        refName: reference.slug,
        newtQuery: metaNewtQuery,
      });

      console.log({ metaRes });

      const metadata = metaRes.message.length > 0 ? metaRes.message[0] : null;

      if (!metadata) {
        return null;
      }
      const fileQuery = {
        path: metadata.name,
      };
      const fileRes = await fetchData({
        bundleId: bundle.bundleId,
        version: bundle.version,
        refName: reference.slug,
        newtQuery: fileQuery,
      });

      console.log({ fileRes });
      return fileRes;
    } catch (err) {
      console.error(err);
    }
  },
});

export const aiConfigParameterState = selector({
  key: "aiConfigParameterState",
  get: async ({ get }) => {
    const bundle = get(cursorBundleState);
    if (!bundle) {
      return null;
    }

    const reference = bundle?.references.find((ref) => ref.slug === "aiconfig");

    if (!reference) {
      return null;
    }

    const newtQuery = {
      limit: 1,
      shape: {
        "contents.rawdata": "any",
      },
    };

    try {
      const res = await fetchData({
        bundleId: bundle.bundleId,
        version: bundle.version,
        refName: reference.name,
        newtQuery,
      });

      return res.message.doc.length > 0
        ? res.message.doc[0].contents.rawdata
        : null;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
});
