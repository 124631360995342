import React from "react";
import { NAMainViewTemplate } from "../templates";
import CHAssetSinglePageTemplate from "src/cheetah/components/templates/CHAssetSinglePageTemplate";
import { useParams } from "react-router-dom";

type Props = {};

export const NAAssetSinglePage: React.FC<Props> = (props) => {
  const params = useParams();

  return (
    <NAMainViewTemplate>
      <CHAssetSinglePageTemplate itemId={params.itemId ?? ""} />
    </NAMainViewTemplate>
  );
};
