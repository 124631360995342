//Libs
import { topDataStateFactory } from "src/opt-editor/utils/topDataBrowserFactory";
import apiConf from "src/opt-editor/apiConf.json";
//Components
//States
import { ScienceFile, defaultScienceFile } from "../models/ScienceFSItem";
import { scienceFileAdaptor } from "../models/File";
import { allegroConsts } from "allegro-models";
//Types

const scienceFile = topDataStateFactory<ScienceFile, any>(
  "ScienceFile",
  defaultScienceFile,
  allegroConsts.assetIds.knowledgeFile,
  apiConf.topdata.file.primaryKey,
  (d) => {
    const result = scienceFileAdaptor(d);
    // const result = createScienceFile({
    //   name: "hoge",
    // });
    return result;
    // return scienceFileAdaptor({
    //   ...d?.contents?.rawdata,
    //   type: "File",
    //   readers: d.readers ?? ["*"],
    //   writers: d.writers ?? ["*"],
    // });
  }
);

/**
 * @deprecated 後方互換のためのアダプタにつき、削除予定
 */
export const cursorFileIdState = scienceFile.cursorItemIdState;

/**
 * @deprecated 後方互換のためのアダプタにつき、削除予定
 */
export const fileState = scienceFile.item;

export default scienceFile;
