/**
 * Consoleで使用するアセットの固定IDを定義する
 */
export const assetIds = {
  // newtzero-console
  monitor: "NEWTZERO-DATAVIEW-MONITOR",

  // nai-console
  bundle: "NEWTZERO-BUNDLE",
  nclone: "NEWTZERO-NEWTCLONE",
  tagFilters: "NAI-TAG-FILTER",

  knowledgeFile: "NEWTZERO-KNOWLEDGE-FILE",
  knowledgeFolder: "NEWTZERO-KNOWLEDGE-FOLDER",
  knowledgeActivity: "NEWTZERO-KNOWLEDGE-ACTIVITY",
};
