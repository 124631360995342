import { User } from "allegro-api";
import { ASBrowserUiConf } from "src/cheetah/components/libs/ASLowCodeBrowser";

export const createManagementBrowserUi = (users: User[]): ASBrowserUiConf => {
  return {
    newtQ: async () => {
      return users;
    },

    tables: [
      {
        label: "",
        component: "LCUserIconComponent",
        data: {
          userNo: "userNo",
        },
        options: {},
      },
      {
        label: "Name",
        component: "LCTextLinkCellComponent",
        data: {
          id: "userNoText",
          label: "profile.userRealName",
        },
        options: {
          dateFormat: "wareki",
          editable: false,
          arrayLimitSize: 3,
        },
        statics: {
          path: "/activities/{id}",
        },
      },
      {
        label: "Email",
        component: "LCSimpleColComponent",
        data: {
          value: "email",
        },
        options: {
          dateFormat: "wareki",
          editable: false,
          arrayLimitSize: 3,
        },
      },
    ],
  };
};
