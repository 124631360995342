// import { UserList } from "../models";
// import { sampleUserList } from "../models";
import { useProfileList } from "src/portal/hooks/useProfileList";
import { createManagementBrowserUi } from "../ui/browserUi/managementBrowserUi";

export const useManagementList = () => {
  // const allUserList: UserList[] = [...sampleUserList];

  const { userList } = useProfileList();

  const handleSearchText = (event: any) => {
    const seachText = event?.target?.value;
    if (seachText.length > 0) {
      // const filterUserList: UserList[] = allUserList.filter(
      //   (user) =>
      //     user.userNoText.indexOf(seachText) !== -1 ||
      //     user.username.indexOf(seachText) !== -1
      // );
      // setUserList(filterUserList);
    } else {
      // setUserList(allUserList);
    }
  };

  const browserUi = createManagementBrowserUi(userList);

  return {
    userList,
    handleSearchText,
    browserUi,
  };
};
