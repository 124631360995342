import { PipelineX, defaultPipelineX } from "allegro-api";
import { ASLowCodeFormContainer } from "allegro-low-code-components";
import {
  CHTabComponentProps,
  CHTabs,
} from "src/cheetah/components/molecules/CHTabs";
import { editPipelineGroupPermissionFormDefinition } from "src/xerver-room/config/formDefinition/editPipelineGroupPermissionFormDefinition";
import { editPipelineUserPermissionFormDefinition } from "src/xerver-room/config/formDefinition/editPipelineUserPermissionFormDefinition";
import { usePipelineXPermissionControl } from "src/xerver-room/hooks/usePipelinePermissionControl";
import { XervicePermissions } from "src/xerver-room/types/xervicePermissions";
import styled from "styled-components";

const Container = styled.div`
  padding: 25px;
  width: 80vw;
  height: 75vh;
`;

const Body = styled.div``;

type Props = {
  pipelineX: PipelineX | null;
};

export const XRPipelinePermissionEditor: React.FC<Props> = (props) => {
  const permissionController = usePipelineXPermissionControl(
    props.pipelineX ?? defaultPipelineX
  );

  const UserComponents = (
    <ASLowCodeFormContainer
      isUpdate
      fullWidth
      formDefinition={editPipelineUserPermissionFormDefinition}
      defaultValues={permissionController.permissions}
      onSubmit={async (data) => {
        await permissionController.updatePermissions(
          data as XervicePermissions
        );
      }}
      disabledSnackbar
    />
  );

  const GroupComponents = (
    <ASLowCodeFormContainer
      isUpdate
      fullWidth
      formDefinition={editPipelineGroupPermissionFormDefinition}
      defaultValues={permissionController.permissions}
      onSubmit={async (data) => {
        await permissionController.updatePermissions(
          data as XervicePermissions
        );
      }}
      disabledSnackbar
    />
  );

  const tabs: CHTabComponentProps[] = [
    { label: "Users", component: UserComponents },
    { label: "Groups", component: GroupComponents },
  ];

  return (
    <Container>
      <Body>
        <CHTabs tabs={tabs} disableBack disableUrl />
      </Body>
    </Container>
  );
};
