import {
  Activity,
  ActivityInput,
  ActivityLevel,
  ActivityResource,
} from "allegro-api";
import { createNewtZeroAPI } from "src/api";

const activityResource: ActivityResource = "slot";
const defaultSlotTags = ["newtroid", "nxtal", "slot"];

/**
 * SlotのActivityをPOSTする関数
 * @params level
 * @params message
 * @params pipelineId slotが所属するpipelineXのID
 * @params slotId Acitivityの対象slotのID
 * @returns 書き込みに成功した場合、Acitivityを返却する
 */
export const saveSlotActivity = async (
  level: ActivityLevel,
  message: string,
  pipelineId: string,
  slotId?: string
): Promise<Activity | null> => {
  const newtzero = createNewtZeroAPI();
  const activityInput: ActivityInput = {
    level,
    message,
    tags: [...defaultSlotTags, `pipeline:${pipelineId}`],
    resource: activityResource,
    resourceId: slotId,
  };

  const resActivity = await newtzero.activity
    .addActivity(activityInput)
    .catch(() => null);
  return resActivity;
};
