import { AxiosInstance } from "axios";
import { NewtQuery } from "../../models";
import {
  OptXBuildHistory,
  createOptXBuildHistoryFromNewtData,
} from "../../models/OptXBuildHistory";

export const OptXBuildHistoryService = (instance: AxiosInstance) => {
  const basePath = "/newtzero";
  const add = async () => {};

  const fetchOne = async (
    optXId: string,
    historyId: string
  ): Promise<OptXBuildHistory | null> => {
    return null;
  };

  const fetchMany = async (optXId: string): Promise<OptXBuildHistory[]> => {
    return [];
  };

  const dataProvider = async (
    optXId: string,
    newtQuery: NewtQuery,
    options?: { count?: boolean; explain?: boolean }
  ): Promise<OptXBuildHistory[]> => {
    const path = basePath + `/optx/${optXId}/commit/dataprovider`;

    try {
      const res = await instance.post(path, newtQuery, { params: options });
      const histories = res.data.message.map(
        (historyObj: { [key: string]: any }) => {
          return createOptXBuildHistoryFromNewtData(historyObj);
        }
      );
      return histories;
    } catch (err) {
      console.error(err);
    }
    return [];
  };

  return {
    add,
    fetchMany,
    dataProvider,
  };
};
