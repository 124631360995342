import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { createnXtalAPI } from "src/api";
import {
  optXV2State,
  optXIdListState,
  optXSchemaTypeState,
  requestOptXListIdState,
} from "../recoils";
import { optXVersionSelections } from "../consts";
import { OptXSchemaType, OptXV2, OptXV2Input } from "allegro-api";
import { useNavigate } from "react-router-dom";
import { activityLogger } from "../activity-logger";

export const useOptXV2 = (optXId: string) => {
  const [self, setSelf] = useRecoilState(optXV2State(optXId ?? ""));
  const [reqId, setReqId] = useRecoilState(requestOptXListIdState);

  const [optXIdList, setOptXIdList] = useRecoilState(optXIdListState);

  const nxtal = createnXtalAPI();

  const refresh = useCallback(() => {
    setReqId((i) => i + 1);
  }, [setReqId]);

  const init = useCallback(
    async (optXId: string) => {
      if (!self && optXId) {
        const resOptX = await nxtal.optxv2.fetchOne(optXId);
        setSelf(resOptX);
      }
    },
    [nxtal.optxv2, self, setSelf]
  );

  const getOptX = () => {
    return self;
  };

  const remove = useCallback(async () => {
    if (optXId) {
      const isDeleted = await nxtal.optxv2.deleteOne(optXId);

      if (isDeleted) {
        setSelf(null);
        setOptXIdList(optXIdList.filter((id) => id !== optXId));
        refresh();

        return TreeWalker;
      } else {
        return false;
      }
    }
    return false;
  }, [nxtal.optxv2, optXId, optXIdList, refresh, setOptXIdList, setSelf]);

  const update = useCallback(
    async (updates): Promise<[boolean, string | null]> => {
      const defaultUpdateOptXErrorMsg = "Failed to update OptX";
      try {
        const isUpdated = await nxtal.optxv2.updateOne(optXId, updates);
        if (isUpdated) {
          setSelf({ ...self, ...updates });
          return [true, null];
        } else {
          return [false, defaultUpdateOptXErrorMsg];
        }
      } catch (err) {
        return [false, String(err)];
      }
    },
    [nxtal.optxv2, optXId, self, setSelf]
  );

  useEffect(() => {
    if (optXId) {
      init(optXId);
    }
  }, [init, optXId, reqId]);

  return { ...self, getOptX, remove, update, refresh };
};

// OptXの状態を持たないhooks
export const useOptXV2Actions = () => {
  const [currentOptXSchemaType, setCurrentOptXSchemaType] =
    useRecoilState(optXSchemaTypeState);

  const navigate = useNavigate();

  const nxtal = createnXtalAPI();

  // OptXVersionを切り替える関数
  const handleChangeVersion = async (version: OptXSchemaType) => {
    setCurrentOptXSchemaType(version);

    const searchParams = new URLSearchParams();
    searchParams.append("version", version.toString());
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  // OptXV2を新規作成する関数
  const add = async (
    newOptX: OptXV2Input
  ): Promise<[OptXV2 | null, string | null]> => {
    try {
      const res = await nxtal.optxv2.add(newOptX);

      if (res) {
        await activityLogger.saveOptXActivity(
          "info",
          `OptXを新規登録しました。`,
          res.optXId
        );
      }

      return [res, null];
    } catch (err) {
      const errMsg = String(err);
      return [null, errMsg];
    }
  };

  return {
    currentOptXSchemaType,
    setCurrentOptXSchemaType,
    optXVersionSelections,
    handleChangeVersion,
    add,
  };
};
