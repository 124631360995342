import { AxiosInstance } from "axios";
import { NewtQuery } from "../../models";
import { XFile, XFileInput, createXFileFromNewtData } from "../../models/XFile";
import { OptXConf, createOptXConf } from "../../models/OptXConf";

export const xfile = (instance: AxiosInstance) => {
  const basePath = "/newtzero";
  const add = async (
    optXId: string,
    variationId: string,
    newXFile: XFileInput
  ): Promise<XFile | null> => {
    const path = basePath + `/optx/${optXId}/version/${variationId}/file`;
    try {
      const res = await instance.post(path, newXFile);
      if (res.status === 200) {
        return createXFileFromNewtData(res.data.message);
      }
    } catch (err) {
      console.error(err);
    }
    return null;
  };

  const fetchOne = async (
    optXId: string,
    variationId: string,
    fileName: string
  ): Promise<XFile | null> => {
    const path =
      basePath + `/optx/${optXId}/version/${variationId}/file/${fileName}`;
    try {
      const res = await instance.get(path);
      if (res.status === 200) {
        return createXFileFromNewtData(res.data.message);
      }
    } catch (err) {
      console.error(err);
    }
    return null;
  };

  const fetchMany = async (
    optXId: string,
    variationId: string,
    filter: { limit?: number; skip?: number }
  ): Promise<XFile[]> => {
    const path = basePath + `/optx/${optXId}/version/${variationId}/file`;
    try {
      const res = await instance.get(path, { params: filter });
      if (res.status === 200) {
        const messages = res.data.message ?? [];
        return messages.map((xfile: { [key: string]: any }) => {
          return createXFileFromNewtData(xfile);
        });
      }
    } catch (err) {
      console.error(err);
    }

    return [];
  };

  const dataProvider = async (
    optXId: string,
    variationId: string,
    newtQuery: NewtQuery,
    options?: { count?: boolean; explain?: boolean }
  ): Promise<XFile[]> => {
    const path =
      basePath + `/optx/${optXId}/version/${variationId}/file/dataprovider`;
    try {
      const res = await instance.post(path, newtQuery, { params: options });
      if (res.status === 200) {
        const messages = res.data.message ?? [];
        return messages.map((xfile: { [key: string]: any }) => {
          return createXFileFromNewtData(xfile);
        });
      }
    } catch (err) {
      console.error(err);
    }

    return [];
  };

  const updateOne = async (
    optXId: string,
    variationId: string,
    fileName: string,
    file: XFileInput,
    params?: {
      upsert?: boolean;
    }
  ): Promise<boolean> => {
    const path =
      basePath + `/optx/${optXId}/version/${variationId}/file/${fileName}`;

    try {
      const res = await instance.put(path, file, { params });
      return res.status === 200;
    } catch (err) {}
    return false;
  };

  const deleteOne = async (
    optXId: string,
    variationId: string,
    fileName: string
  ): Promise<boolean> => {
    const path =
      basePath + `/optx/${optXId}/version/${variationId}/file/${fileName}`;
    try {
      const res = await instance.get(path);
      return res.status === 200;
    } catch (err) {
      console.error(err);
    }
    return false;
  };

  const fetchOptXConf = async (
    optXId: string,
    variationId: string
  ): Promise<OptXConf | null> => {
    const path =
      basePath + `/optx/${optXId}/version/${variationId}/file/optx-conf.json`;
    try {
      const res = await instance.get(path);
      if (res.status === 200) {
        const xfile = createXFileFromNewtData(res.data.message);
        const optXConfStr = xfile.text;
        if (optXConfStr) {
          return createOptXConf(JSON.parse(optXConfStr));
        }
      }
    } catch (err) {
      console.error(err);
    }
    return null;
  };

  return {
    add,
    fetchOne,
    fetchMany,
    dataProvider,
    updateOne,
    deleteOne,
    fetchOptXConf,
  };
};
