import React from "react";
import { ASMarkdown, ASPictureIcon } from "allegro-ui";
import styled from "styled-components";
import { OptX } from "src/xerver-room/models";
import { useFileXList } from "src/xerver-room/hooks";
import { XRUpdateOptXForm } from "./XRUpdateOptXForm";
import moment from "moment";
import { XRPageTemplate } from "../templates/XRPageTemplate";
import {
  CHTabComponentProps,
  CHTabs,
} from "src/cheetah/components/molecules/CHTabs";
import { XRXFileCard } from "../atoms/XRXFileCard";
// import remarkGfm from "remark-gfm";

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin-right: 12px;
  }
`;

const CompactModalcontent = styled.div`
  text-align: left;
  width: 100%;
  > h1 {
    margin-top: 8px;
    font-size: 20px;
  }
`;

const IconTitleBlock = styled.div`
  border-radius: ${(props) => props.theme.borderRadius}px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  > div {
    > h1 {
      font-size: 24px;
      font-weight: bold;
    }
    > p {
      font-size: 14px;
      line-height: 1.6;
      opacity: 0.8;
    }
    &:last-child {
      margin-left: 24px;
    }
  }
`;

const CompactIconTitleBlock = styled(IconTitleBlock)`
  background: none;
  align-items: center;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;

  > h1 {
    margin-left: 8px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    word-break: break-word;
  }
`;

const IconLayout = styled.div`
  float: right;
`;

const IconWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const IconShadowWrapper = styled.div`
  position: absolute;
  bottom: 40px;
  opacity: 0.8;
  > * {
    height: 20px;
  }

  filter: blur(15px);
  /* backdrop-filter: blur(8px); */
`;

const Block = styled.div`
  margin-top: 24px;
  padding-top: 12px;
  border-top: 1px solid #333;
`;

const BlockTitle = styled.h2`
  font-size: 14px;
  margin-bottom: 2px;
`;
const BuildLog = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > span {
    margin-right: 8px;
    line-height: 1.5;
    font-size: 13px;
  }

  border-bottom: 1px solid #444;
  padding: 8px 0;
  &:first-child {
    border: none;
  }
  &:last-child {
    border: none;
  }
`;

const Time = styled.span`
  opacity: 0.4;
  font-size: 12px;
`;

const NegativeLogText = styled.h1`
  font-weight: bold;
  color: ${(props) => props.theme.negativeColor};
`;

const PositiveLogText = styled.h1`
  font-weight: bold;
  color: ${(props) => props.theme.positiveColor};
`;

type Props = {
  optX: OptX;
};
export const XROptXDetails: React.FC<Props> = ({ optX }) => {
  const { files } = useFileXList(optX.optXId);
  const histories = optX.buildHistory;

  const renderBuildLog = (history: any, toNow: boolean = false) => {
    return (
      <BuildLog>
        <span>
          {history.message === "ビルド成功" ? (
            <PositiveLogText>{history.message}</PositiveLogText>
          ) : (
            <NegativeLogText>{history.message}</NegativeLogText>
          )}
        </span>
        <Time>
          {toNow
            ? moment(history.time).toNow()
            : moment(history.time).format("DD/MM/YYYY, h:mm:ss a")}
        </Time>
        <span>{history.ref}</span>
      </BuildLog>
    );
  };

  const tabs: CHTabComponentProps[] = [
    {
      label: "Info",
      component: (
        <Block>
          <BlockTitle>Description</BlockTitle>
          <ASMarkdown>{optX.description}</ASMarkdown>
        </Block>
      ),
    },
    {
      label: "Build History",
      component: (
        <Block>
          <BlockTitle>Build history</BlockTitle>
          <Block>
            <BlockTitle>ID</BlockTitle>
            {optX.optXId}
          </Block>
          <Block>
            <BlockTitle>File</BlockTitle>
            {files.length > 0 ? (
              files.map((file: any) => {
                return <XRXFileCard xfile={file} />;
              })
            ) : (
              <>File not found.</>
            )}
          </Block>
          {histories.length > 0 ? (
            histories.map((history: any) => {
              return renderBuildLog(history);
            })
          ) : (
            <>---</>
          )}
        </Block>
      ),
    },
    {
      label: "Settings",
      component: <XRUpdateOptXForm optX={optX} />,
    },
  ];

  return (
    <XRPageTemplate
      title={
        <Header>
          <ASPictureIcon
            size={50}
            src={optX.thumbnail}
            defaultSrc={optX.thumbnail}
          />
          <h1>{optX.name}</h1>
        </Header>
      }
    >
      <CHTabs tabs={tabs} />
    </XRPageTemplate>
  );
};

export const XROptXCompactCard: React.FC<Props> = (props) => {
  const { optX } = props;
  return (
    <CompactModalcontent>
      <CompactIconTitleBlock>
        <h1>{optX.name}</h1>
        <IconLayout>
          <IconWrapper>
            <ASPictureIcon
              size={70}
              src={optX.thumbnail}
              defaultSrc={optX.thumbnail}
            />
          </IconWrapper>

          <IconShadowWrapper>
            <ASPictureIcon
              size={70}
              src={optX.thumbnail}
              defaultSrc={optX.thumbnail}
            />
          </IconShadowWrapper>
        </IconLayout>
      </CompactIconTitleBlock>
      {/* <Block>
    <BlockTitle>Last build</BlockTitle>
    {histories.length > 0 ? (
      <>{renderBuildLog(histories[0], true)}</>
    ) : (
      <>Build not found.</>
    )}
  </Block> */}
      <></>
    </CompactModalcontent>
  );
};
