import { Paper } from "@material-ui/core";

import { useActivityList } from "src/opt-editor/hooks/useActivityList";
import styled from "styled-components";
import { ASPageNav } from "allegro-ui";
import { ASLowCodeDataBrowserContainer } from "src/cheetah/components/libs/ASLowCodeBrowser";

const Body = styled.div`
  display: flex;
  margin: 0px;
  width: 100%;
  /* padding: 50px 30px 50px 30px; */
`;

const ActivityArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ActivityView = styled(Paper)`
  width: 100%;
  /* padding: 20px; */
  margin-bottom: 20px;
  background-color: ${(props) => props.theme.bgPrimaryColor};
`;

type Props = {};

export const OEActivityListTemplate: React.FC<Props> = (props) => {
  const {
    browserUi,
    currentPage,
    totalPageCount,
    activities,
    handlePageChange,
  } = useActivityList();
  return (
    <Body>
      <ActivityArea>
        <ActivityView>
          <ASLowCodeDataBrowserContainer
            name={"activity-v2-list"}
            uiConf={browserUi}
          />
        </ActivityView>

        {activities.length > 0 ? (
          <ASPageNav
            count={totalPageCount}
            page={currentPage}
            onChange={handlePageChange}
          />
        ) : (
          <></>
        )}
      </ActivityArea>
    </Body>
  );
};
