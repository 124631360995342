//Libs
import { api, createnXtalAPI } from "src/api";
//Components
//States
import { userState } from "src/root/recoils/userState";
//Types

import { AtomEffect, DefaultValue } from "recoil";
import { defaultPipelineX, PipelineX } from "allegro-api";

type Options = {
  contractor: string;
  apiKey: string;
};

const getPipeline = async (
  pipelineId: string,
  { contractor, apiKey }: Options
): Promise<PipelineX | DefaultValue> => {
  const nxtal = createnXtalAPI();
  try {
    const pipelineX = await nxtal.pipelinex.fetchOne(contractor, pipelineId);
    return pipelineX ?? defaultPipelineX;
  } catch (err) {
    return defaultPipelineX;
  }
};

export const updatePipeline = async (
  newPipeline: PipelineX,
  { contractor, apiKey }: Options
) => {
  const res = await api({
    method: "PUT",
    url: `/contractors/${contractor}/pipelinex/${newPipeline.id}?apikey=${apiKey}`,
    params: {
      apikey: apiKey,
    },
    data: { name: newPipeline.name },
  });
  return res;
};

export const syncDBEffect: (pipelineId: string) => AtomEffect<PipelineX> =
  (pipelineId) =>
  ({ setSelf, trigger, onSet, getPromise }) => {
    if (trigger === "get") {
      getPromise(userState).then((auth) => {
        const contractor: string =
          typeof auth.contractorNo === "string" ? auth.contractorNo : "";
        const apiKey: string = typeof auth.token === "string" ? auth.token : "";

        getPipeline(pipelineId, { contractor, apiKey }).then((pipeline) =>
          setSelf(pipeline)
        );
      });
    }
  };
