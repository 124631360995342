import { AxiosInstance } from "axios";
import {
  Activity,
  ActivityInput,
  createActivityFromNewtData,
} from "../../models/Activity";
import { NewtQuery } from "../../models";

export const ActivityService = (instance: AxiosInstance) => {
  const basePath = "/newtzero/activity";

  const addActivity = async (
    newActivity: ActivityInput
  ): Promise<Activity | null> => {
    const path = basePath;
    try {
      const res = await instance.post(path, newActivity);

      return createActivityFromNewtData(res.data.message);
    } catch (err) {
      console.error(err);
    }

    return null;
  };

  const fetchOne = async (id: string): Promise<Activity | null> => {
    const path = basePath + `/${id}`;
    try {
      const res = await instance.get(path);
      return createActivityFromNewtData(res.data.message);
    } catch (err) {
      console.error(err);
    }
    return null;
  };

  const fetchMany = async (): Promise<Activity[]> => {
    const path = basePath;

    try {
      const res = await instance.get(path);
      return res.data.message.map((obj: any) => {
        return createActivityFromNewtData(obj);
      });
    } catch (err) {
      console.error(err);
    }
    return [];
  };

  const dataProvider = async (
    newtQuery: NewtQuery,
    options?: { count?: boolean }
  ): Promise<Activity[]> => {
    const path = basePath + "/dataprovider";

    try {
      const res = await instance.post(path, newtQuery, { params: options });
      return res.data.message.map((obj: any) => {
        return createActivityFromNewtData(obj);
      });
    } catch (err) {
      console.error(err);
    }

    return [];
  };

  return {
    addActivity,
    fetchOne,
    fetchMany,
    dataProvider,
  };
};
