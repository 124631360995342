import { useRecoilValueLoadable } from "recoil";
import { myProfileState } from "src/root/recoils/userState";
import { NAMainViewTemplate } from "../templates/NAMainViewTemplate";
import { PTSettingNav } from "src/portal/components/templates/PTSettingNav";
import { useParams } from "react-router-dom";
import { PTSettingGroupSingleTemplate } from "src/portal/components/templates/PTSettingGroupSingleTemplate";

type Props = {};
export const NASettingGroupSinglePage: React.FC<Props> = (props) => {
  const params = useParams();
  const groupId = params.groupId ?? "";
  const myProfile = useRecoilValueLoadable(myProfileState);

  switch (myProfile.state) {
    case "hasValue":
      if (myProfile && myProfile.contents)
        return (
          <NAMainViewTemplate>
            <PTSettingNav>
              <PTSettingGroupSingleTemplate groupId={groupId} />
            </PTSettingNav>
          </NAMainViewTemplate>
        );
      return <>error</>;
    case "loading":
      return <>loading</>;
    case "hasError":
      throw myProfile.contents;
  }
};
