import { instance, apiVersion } from "../initialize";
import react, { useState, useCallback, useMemo } from "react";
import { AxiosInstance } from "axios";

export * from "./static";
export * from "./actionsV2";

/**
 *
 * @param tags
 * @param primaryKey
 * @param f
 * @returns
 * @deprecated 削除予定
 */
export const useTopData = <T>(
  tags: string[],
  primaryKey: string,
  f?: (d: any) => T
) => {
  const factory: (d: unknown) => T = useMemo(
    () => (f ? f : (d: any): T => d),
    []
  );
  const API_VERSION = apiVersion;

  const api = useMemo(() => {
    return instance();
  }, [instance]);
  /**
   * apiのイニシャライズ
   */

  /**
   * 一件取得
   */
  const getOne = useCallback(
    async (pKeyValue: number | string): Promise<T | null> => {
      if (!api) {
        console.error("no api");
        return null;
      }
      const res = await api({
        method: "GET",
        url: `/api/${API_VERSION}/contractors/${sessionStorage.contractorNo}/topdata`,
        params: {
          apikey: sessionStorage.token,
          q: {
            where: {
              $and: tags.map((tag: string) => {
                return { "bases.tags": tag };
              }),
            },
            skip: 0,
            limit: 1,
          },
        },
        headers: { "Content-Type": "application/json" },
      });
      return factory(res.data[0]);
    },
    []
  );

  /**
   * n件取得
   */
  const getMany = useCallback(async (limit: number, skip: number): Promise<
    T[] | null
  > => {
    if (!api) {
      console.error("no api");
      return null;
    }
    const res = await api({
      method: "GET",
      url: `/api/${API_VERSION}/contractors/${sessionStorage.contractorNo}/topdata`,
      params: {
        apikey: sessionStorage.token,
        q: {
          where: {
            $and: tags.map((tag: string) => {
              return { "bases.tags": tag };
            }),
          },
          skip: skip,
          limit: limit,
        },
      },
      headers: { "Content-Type": "application/json" },
    });
    console.log("res", res);
    console.log("tags", tags);
    console.log("api", api);
    return res.data.map((d: unknown) => factory(d));
  }, []);

  /**
   * 一件作成
   */
  const postOne = useCallback(
    async (data: T, readers?: string[], writers?: string[]) => {
      if (!api) {
        console.error("no api");
        return null;
      }
      const d = data as any;
      if (d[primaryKey] == null) {
        console.log(d);
        console.log(primaryKey);
        console.error("invalid data were given.");
        return false;
      }

      const res = await api({
        method: "POST",
        url: `/api/${API_VERSION}/contractors/${sessionStorage.contractorNo}/datagate`,
        params: {
          apikey: sessionStorage.token,
          assetTags: tags,
          sendToTopData: "true",
          strict: true,
          readers: JSON.stringify(readers),
          writers: JSON.stringify(writers),
        },
        headers: { "Content-Type": "application/json" },
        data: data,
      });
      if (res.status === 200) {
        return true;
      } else {
        console.error(res);
        return false;
      }
    },
    []
  );

  /**
   * postOneのエイリアス
   */
  const post = postOne;

  /**
   * 一件更新
   */
  const updateOne = useCallback(
    async (data: T, cb, readers?: string[], writers?: string[]) => {
      if (!api) return false;
      const d = data as any;

      //0は除外する
      if (!d[primaryKey] && d[primaryKey] !== 0) {
        console.error("data", data);
        console.log("invalid data were given.");
        return false;
      }

      const res = await api({
        method: "POST",
        url: `/api/${API_VERSION}/contractors/${sessionStorage.contractorNo}/datagate`,
        params: {
          apikey: sessionStorage.token,
          assetTags: tags,
          sendToTopData: "true",
          strict: true,
          readers: JSON.stringify(readers),
          writers: JSON.stringify(writers),
        },
        headers: { "Content-Type": "application/json" },
        data: data,
      });
      if (res.status === 200) {
        return true;
      } else {
        console.error(res);
        return false;
      }
    },
    []
  );

  /**
   * updateOneのエイリアス
   */
  const update = updateOne;

  /**
   * n件更新
   */
  //@todo TODO

  /**
   * 一件削除
   */
  const removeOne = useCallback(async (primaryValue: number | string) => {
    if (!api) return null;
    // const q = {
    //   where:return {`contents.rawdata.${primaryKey}`: primaryKey}
    // };
    console.log("delete");
    console.log(primaryValue);
    const res = await api({
      method: "DELETE",
      url: `/api/${API_VERSION}/contractors/${sessionStorage.contractorNo}/datamanage/topdatas`,
      params: {
        apikey: sessionStorage.token,
        q: {
          where: {
            // $and: tags.map((tag: string) => {
            //   return { "bases.tags": tag };
            // }),
            $and: tags.map((tag: string) => {
              return { "bases.tags": tag };
            }),
            ["contents.rawdata." + primaryKey]: primaryValue,
          },
        },
      },
      headers: { "Content-Type": "application/json" },
    });
    return res;
  }, []);

  /**
   * removeOneのエイリアス
   */
  const remove = removeOne;

  return {
    getOne,
    getMany,
    post,
    postOne,
    update,
    updateOne,
    remove,
    removeOne,
  };
};
