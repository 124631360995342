/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { createOptX } from "../models";
import { userState } from "src/root/recoils/userState";
import { createnXtalAPI } from "src/api";
import { optXIdListState, optXState } from "../recoils";

export const useOptX = (optXId?: string) => {
  const [self, setSelf] = useRecoilState(optXState(optXId ?? ""));
  const auth = useRecoilValue(userState);
  const [reqId, setReqId] = useState(0);

  const [optXIdList, setOptXIdList] = useRecoilState(optXIdListState);

  const nxtal = createnXtalAPI();

  const refresh = () => {
    setReqId((i) => i + 1);
  };

  const init = useCallback(
    async (optXId: string) => {
      //fetch OptX;  const auth = useRecoilValue(userState);

      const contractor: string =
        typeof auth.contractorNo === "string" ? auth.contractorNo : "";

      if (optXId && !self) {
        try {
          const resOptX = await nxtal.optx.getOne(contractor, optXId);
          if (resOptX?.data) {
            setSelf(createOptX(resOptX.data));
          }
        } catch (err) {
          console.error(err);
        }
      }
    },
    [auth.contractorNo, nxtal.optx, self, setSelf]
  );

  const remove = useCallback(async () => {
    const contractor: string =
      typeof auth.contractorNo === "string" ? auth.contractorNo : "";

    if (optXId) {
      const res = await nxtal.optx.remove(contractor, optXId);

      setOptXIdList(optXIdList.filter((id) => id !== optXId));
      if (res?.status === 200) {
        return TreeWalker;
      } else {
        return false;
      }
    }
    return false;
  }, [auth.contractorNo, nxtal.optx, optXId, optXIdList, setOptXIdList]);

  const update = useCallback(
    async (updates) => {
      const contractor: string =
        typeof auth.contractorNo === "string" ? auth.contractorNo : "";
      const res = await nxtal.optx.update({
        contractor,
        optXId: optXId ?? "",
        data: updates,
      });

      if (res?.status === 200) {
        setSelf({ ...self, ...updates });
        return TreeWalker;
      } else {
        return false;
      }
    },
    [auth.contractorNo, nxtal.optx, optXId]
  );

  useEffect(() => {
    if (optXId) {
      init(optXId);
    }
  }, [init, optXId, reqId]);

  return { ...self, remove, update, refresh };
};

export const useOptXActions = () => {
  const nxtal = createnXtalAPI();
  const auth = useRecoilValue(userState);

  const add = async (data: { [key: string]: any }) => {
    const contractorNo: string =
      typeof auth.contractorNo === "string" ? auth.contractorNo : "";

    await nxtal.optx.create({
      contractorNo,
      data,
    });
    window.location.reload();
  };

  return { add };
};
