export const permissions = {
  nauth: {
    user: {
      invite: "nauth.user.invite",
      create: "nauth.user.create",
      readInContractor: "nauth.user.readInContractor",
      readOwn: "nauth.user.readOwn",
      updateOwn: "nauth.user.updateOwn",
      updateInContractor: "nauth.user.updateInContractor",
      deleteOwn: "nauth.user.deleteOwn",
      deleteInContractor: "nauth.user.deleteInContractor",
      updateRoleOwn: "nauth.user.updateRoleOwn",
      updateRoleInContractor: "nauth.user.updateRoleInContractor",
    },
    group: {
      create: "nauth.group.create",
      read: "nauth.group.read",
      update: "nauth.group.update",
      delete: "nauth.group.delete",
      invite: "nauth.group.invite",
      exclude: "nauth.group.exclude",
    },
    otherUser: {
      readInContractor: "readInContractor",
    },
  },
  xtal: {
    pipelineX: {
      createOwn: "xtal.pipelineX.createInOwn",
      createInContractor: "xtal.pipelineX.createInContractor",
      readOwn: "xtal.pipelineX.readOwn",
      readInContractor: "xtal.pipelineX.readInContractor",
      updateOwn: "xtal.pipelineX.updateOwn",
      updateInContractor: "xtal.pipelineX.updateInContractor",
      deleteOwn: "xtal.pipelineX.deleteOwn",
      deleteInContractor: "xtal.pipelineX.deleteInContractor",
    },
    xervice: {
      createOwn: "xtal.xervice.createInOwn",
      createInContractor: "xtal.xervice.createInContractor",
      readOwn: "xtal.xervice.readOwn",
      readInContractor: "xtal.xervice.readInContractor",
      updateOwn: "xtal.xervice.updateOwn",
      updateInContractor: "xtal.xervice.updateInContractor",
      deleteOwn: "xtal.xervice.deleteOwn",
      deleteInContractor: "xtal.xervice.deleteInContractor",
      startContainerOwn: "xtal.xervice.startContainerOwn",
      startContainerInContractor: "xtal.xervice.startContainerInContractor",
      stopContainerOwn: "xtal.xervice.stopContainerOwn",
      stopContainerInContractor: "xtal.xervice.stopContainerInContractor",
    },
    server: {
      create: "xtal.server.create",
      readOwn: "xtal.server.read",
      readInContractor: "xtal.server.readInContractor",
      updateOwn: "xtal.server.updateOwn",
      updateInContractor: "xtal.server.updateInContractor",
      deleteOwn: "xtal.server.deleteOwn",
      deleteInContractor: "xtal.server.deleteInContractor",
    },
    optx: {
      create: "xtal.optx.create",
      readOwn: "xtal.optx.readOwn",
      readInContractor: "xtal.optx.readInContractor",
      updateOwn: "xtal.optx.updateOwn",
      updateInContractor: "xtal.optx.updateInContractor",
      deleteOwn: "xtal.optx.deleteOwn",
      deleteInContractor: "xtal.optx.deleteInContractor",
    },
    optxVariation: {
      create: "xtal.optxVariation.create",
      readOwn: "xtal.optxVariation.readOwn",
      readInContractor: "xtal.optxVariation.readInContractor",
      updateOwn: "xtal.optxVariation.updateOwn",
      updateInContractor: "xtal.optxVariation.updateInContractor",
      deleteOwn: "xtal.optxVariation.deleteOwn",
      deleteInContractor: "xtal.optxVariation.deleteInContractor",
    },
    registry: {
      create: "xtal.registry.create",
      readOwn: "xtal.registry.readOwn",
      readInContractor: "xtal.registry.readInContractor",
      updateOwn: "xtal.registry.updateOwn",
      updateInContractor: "xtal.registry.updateInContractor",
      deleteOwn: "xtal.registry.deleteOwn",
      deleteInContractor: "xtal.registry.deleteInContractor",
    },
  },
  newtzero: {
    asset: {
      createOwn: "newtzero.asset.createInOwn",
      createInContractor: "newtzero.asset.createInContractor",
      readOwn: "newtzero.asset.readOwn",
      readInContractor: "newtzero.asset.readInContractor",
      updateOwn: "newtzero.asset.updateOwn",
      updateInContractor: "newtzero.asset.updateInContractor",
      deleteOwn: "newtzero.asset.deleteOwn",
      deleteInContractor: "newtzero.asset.deleteInContractor",
    },
    metric: {
      createOwn: "newtzero.metric.createInOwn",
      createInContractor: "newtzero.metric.createInContractor",
      readOwn: "newtzero.metric.readOwn",
      readInContractor: "newtzero.metric.readInContractor",
      updateOwn: "newtzero.metric.updateOwn",
      updateInContractor: "newtzero.metric.updateInContractor",
      deleteOwn: "newtzero.metric.deleteOwn",
      deleteInContractor: "newtzero.metric.deleteInContractor",
    },
  },
};
