import React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import routes from "./routes.json";
import { PrivateRoute } from "src/root";

import { LoadingCircleContainer } from "src/fortress/containers";
import { UIContextProvider, DataContextProvider } from "src/fortress/contexts";

import logo from "src/assets/newtroid-logo-small-capital-ver.svg";
import { createUserAuthPath } from "src/cheetah/components/UserAuthPage/models/userAuthRoutes";
import { createUserAuthRoutes } from "src/cheetah/components/UserAuthPage/createUserAuthRoutes";

import { NAAIListPage, NAAISingleManagementPage } from "./components/pages";
import { NASettingTeamPage } from "./components/pages/NASettingTeamPage";
import { NASettingProfilePage } from "./components/pages/NASettingProfilePage";
import { NASettingSecretsPage } from "./components/pages/NASettingSecretsPage";
import { NAAISingleOperationPage } from "./components/pages/NAAISingleOperationPage";
import { NAAssetSinglePage } from "./components/pages/NAAssetSinglePage";
import { NAKnowledgePage } from "./components/pages/NAKnowledgePage";
import { NASettingGroupSinglePage } from "./components/pages/NASettingGroupSinglePage";
import { NASettingGroupListPage } from "./components/pages/NASettingGroupListPage";

const Routes: React.FC = () => {
  const authRoutes = createUserAuthPath({
    home: routes.HOME,
    login: routes.LOGIN,
    signUp: routes.SIGNUP,
    confirmEmail: routes.CONFIRMEMAIL,
    provisionEmail: routes.PROVISIONEMAIL,
    forgotPassword: routes.FORGOT_PASSWORD,
    rescuePassword: routes.RESCUE_PASSWORD,
  });
  //pathは最短マッチされるのでSCIENCEは一番下にする。
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {createUserAuthRoutes({
          logo: <img src={logo} alt="Newtroid" />,
          appName: "AI Console",
          routes: authRoutes,
        })}

        <Route
          key={routes.SETTING_TEAMS}
          path={routes.SETTING_TEAMS}
          element={
            <PrivateRoute>
              <NASettingTeamPage />
            </PrivateRoute>
          }
        />
        <Route
          key={routes.SETTING_PROFILE}
          path={routes.SETTING_PROFILE}
          element={
            <PrivateRoute>
              <NASettingProfilePage />
            </PrivateRoute>
          }
        />
        <Route
          key={routes.SETTING_SECRETS}
          path={routes.SETTING_SECRETS}
          element={
            <PrivateRoute>
              <NASettingSecretsPage />
            </PrivateRoute>
          }
        />

        <Route
          path={routes.SETTING_GROUP}
          element={
            <PrivateRoute>
              <NASettingGroupListPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.SETTING_GROUP_SINGLE}
          element={
            <PrivateRoute>
              <NASettingGroupSinglePage />
            </PrivateRoute>
          }
        />

        <Route
          path={routes.AI_SINGLE_ASSET}
          element={
            <PrivateRoute>
              <NAAssetSinglePage />
            </PrivateRoute>
          }
        />

        {/* Knowledgeページ */}
        <Route
          path={routes.KNOWLEDGE}
          element={
            <PrivateRoute>
              <NAKnowledgePage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.KNOWLEDGE_ROOT}
          element={
            <PrivateRoute>
              <NAKnowledgePage />
            </PrivateRoute>
          }
        />

        <Route
          path={routes.AI_SINGLE_MANAGEMENT}
          element={
            <PrivateRoute>
              <NAAISingleManagementPage />
            </PrivateRoute>
          }
        />

        <Route
          path={routes.AI_SINGLE_OPERATION}
          element={
            <PrivateRoute>
              <NAAISingleOperationPage />
            </PrivateRoute>
          }
        />

        <Route
          path={routes.AI_SINGLE}
          element={
            <PrivateRoute>
              <NAAISingleManagementPage />
            </PrivateRoute>
          }
        />

        <Route
          path={routes.AI_LIST}
          element={
            <PrivateRoute>
              <NAAIListPage />
            </PrivateRoute>
          }
        />

        <Route
          path={routes.HOME}
          element={
            <PrivateRoute>
              <NAAIListPage />
            </PrivateRoute>
          }
        />
      </>
    )
  );
  return (
    <UIContextProvider>
      <DataContextProvider>
        <LoadingCircleContainer />
        <RouterProvider router={router} />
      </DataContextProvider>
    </UIContextProvider>
  );
};

export default Routes;
