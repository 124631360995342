import { User } from "allegro-models";
import React, { useState, useEffect, useCallback } from "react";
import { useParams, Params } from "react-router-dom";
import {
  useRecoilState,
  useRecoilRefresher_UNSTABLE,
  useSetRecoilState,
} from "recoil";
import { useProfileList } from "src/portal/hooks/useProfileList";
import { Activity } from "allegro-api";
import activityUtils from "../recoils/activityState";
import { fetchActivitiesByUserNo } from "../api/fetchActivities";
import { decodeUserNoText } from "src/fortress/utils/userNoEdit";
import { createActivityV2ListBrowser } from "../ui/browserUi/activityV2ListBrowserUi";

export const useActivityList = () => {
  const [page, setPage] = React.useState(1);
  const { userNoText } = useParams<Params>();

  const userNo = decodeUserNoText(userNoText ?? "");
  const browserUi = createActivityV2ListBrowser(userNo ?? 0);

  const [filter, setFilter] = useRecoilState(activityUtils.filterState);
  const [activities, setActivities] = useState<Activity[]>([]);
  const refreshActivityList = useRecoilRefresher_UNSTABLE(activityUtils.idList);

  const setReqId = useSetRecoilState(activityUtils.requestIdState);

  const { getUserById } = useProfileList();

  const [profile, setProfile] = useState<User | null>();

  useEffect(() => {
    if (userNoText) {
      const p = getUserById(userNoText);
      // setProfile(p)
      if (p) setProfile(p);
    }
  }, [getUserById, userNoText]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const updateActivityList = useCallback(async () => {
    if (filter) {
      const items = await fetchActivitiesByUserNo(userNo ?? -1);
      setActivities(items);
    }
  }, [filter, userNo]);

  useEffect(() => {
    updateActivityList();
  }, [updateActivityList]);

  /**
   * userNoTextをフィルターにセットする
   */
  useEffect(() => {
    if (userNoText) {
      const nextFilter = {
        "contents.rawdata.userNoText": userNoText,
      };
      setFilter(nextFilter);
    }

    refreshActivityList();
    // setReqId((p) => p + 1);
  }, [refreshActivityList, setFilter, setReqId, userNoText]);

  const rowsPerPage = 8;
  const totalPageCount =
    Math.floor(activities.length / rowsPerPage) +
    Number(activities.length % rowsPerPage > 0);

  return {
    profile,
    browserUi,
    currentPage: page,
    rowsPerPage,
    totalPageCount,
    handlePageChange,
    activities,
  };
};
