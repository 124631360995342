import React from "react";
import { OEManagementPageTemplate } from "src/opt-editor/components/templates/OEManagementPageTemplate";
import { NTMainViewTemplate } from "../templates/NTMainViewTemplate";
import { XRPageTemplate } from "src/xerver-room/components/templates/XRPageTemplate";

type Props = {};
export const NTManagementPage: React.FC<Props> = (props) => {
  return (
    <NTMainViewTemplate>
      <XRPageTemplate title="Management">
        <OEManagementPageTemplate />
      </XRPageTemplate>
    </NTMainViewTemplate>
  );
};
