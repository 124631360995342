import { useState } from "react";
import { useOptXV2 } from "./useOptXV2";
import { useMyRole } from "src/portal/hooks/useMyRole";
import { useAuth } from "src/root";
import { activityLogger } from "../activity-logger";
import { useNavigate } from "react-router-dom";
import routes from "../routes.json";

export const useOptXV2UpdateForm = (optXId: string) => {
  const optxV2 = useOptXV2(optXId);

  const auth = useAuth();
  const role = useMyRole();
  const navigate = useNavigate();

  const canUpdateOptX =
    role.canUpdateOptX && optxV2.contractorNo === auth.contractorNo;
  const canDeleteOptX =
    role.canDeleteOptX && optxV2.contractorNo === auth.contractorNo;

  const [deleteInputText, setDeleteInputText] = useState();

  const handleChangeDeleteOptXName = (e: any) => {
    setDeleteInputText(e.target.value);
  };
  const handleDeleteOptX = async () => {
    //入力したフィールドとOptXの名前が一致するなら削除する
    if (deleteInputText === optxV2.name) {
      //delete
      const ok = await optxV2.remove();
      if (ok) {
        alert(`${optxV2.name} is deleted.`);

        await activityLogger.saveOptXActivity(
          "info",
          `OptX:${optXId}を削除しました。`,
          optXId
        );
        navigate(routes.OPTX_BROWSER);
      } else {
        alert(`Failed to delete ${optxV2.name}.`);
      }
    } else {
      alert(`Failed to delete ${optxV2.name}. Invalid OptX name.`);
    }
  };

  const handleUpdateOptX = async (updates: { [key: string]: any }) => {
    const [ok, errMsg] = await optxV2.update(updates);
    if (ok) {
      // alert(`${optxV2.name} is updated.`);

      await activityLogger.saveOptXActivity(
        "info",
        `OptX:${optXId}を編集しました。`,
        optXId
      );
    } else {
      const alertMessage = errMsg ?? `Failed to update ${optxV2.name} .`;
      throw Error(alertMessage);
    }
  };

  const handleChangeThumbnail = async (file: any) => {
    const [ok, errMsg] = await optxV2.update({ thumbnail: file });
    if (ok) {
      alert(`Thumbnail is updated.`);

      await activityLogger.saveOptXActivity(
        "info",
        `OptX:${optXId}のサムネイルを編集しました。`,
        optXId
      );
    } else {
      const alertMessage =
        errMsg ?? `Failed set thumbnail to  ${optxV2.name} .`;
      alert(alertMessage);
    }
  };

  return {
    canUpdateOptX,
    canDeleteOptX,
    handleChangeDeleteOptXName,
    handleDeleteOptX,
    handleUpdateOptX,
    handleChangeThumbnail,
  };
};
