export type Target = {
  slotId: string;
  name: string;
};

export type Output = {
  name: string;
  targets: Target[];
  type?: "http" | "tcp";
};

export type Input = {
  name: string;
  url?: string;
  portInside: number;
  portOutside?: number;
  type?: "http" | "tcp";
  usePrivateGateway: boolean;
  customDomain?: string;
};

type ConnectorType =
  | "http"
  | "tcp"
  | "pubsub__NOT_SUPPORTED_YET"
  | "grpc__NOT_SUPPORTED_YET";

export type InputConf = Input & {
  type: ConnectorType;
};

export type OutputConf = Output & {
  type: ConnectorType;
};

export const defaultInput: Input = {
  name: "default",
  portInside: 80,
  portOutside: undefined,
  usePrivateGateway: false,
  customDomain: undefined,
};

export const defaultOutput: Output = {
  name: "default",
  targets: [],
};

export const defaultInputConf: InputConf = {
  ...defaultInput,
  type: "http",
};

export const defaultOutputConf: OutputConf = {
  ...defaultOutput,
  type: "http",
};
