//Libs
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { atom, useRecoilState } from "recoil";

//Components
import { ASIconButton } from "allegro-ui";
//States
//Types
import { Environments } from "src/xerver-room/models";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import produce from "immer";
import { ASLowCodeFormContainer } from "allegro-low-code-components";
import { useSlotInspector } from "src/xerver-room/hooks/useSlotInspector";
import { useSlotActions } from "src/xerver-room/hooks";

const FormContainer = styled.div`
  margin: 0 auto;
  margin-bottom: 30px;
  max-width: 100%;
  width: 300px;
  display: flex;
  flex-direction: column;
  text-align: left;
  overflow-wrap: break-word;
  > * {
    margin-top: 24px;
  }
`;

type Props = {
  slotId: string;
  disabled: boolean;
};

const EnvironmentClipBoard = atom<Environments>({
  key: "EnvironmentClipBoard",
  default: {},
});

export const XREnvironmentSettingForm: React.FC<Props> = ({
  slotId,
  disabled,
}) => {
  const {
    slotActions: slot,
    hasEnvirontments,
    buildFormDefinition,
  } = useSlotInspector();

  const [formData, setFormData] = useState<Environments>(slot.environments);
  const [envClip, setEnvClip] = useRecoilState(EnvironmentClipBoard);

  const copyEnv = () => {
    setEnvClip(formData);
  };

  const pasteEnv = useCallback(() => {
    const result = produce(formData, (draft) => {
      Object.keys(draft).forEach((key) => {
        if (envClip[key]) draft[key] = envClip[key];
      });
    });

    setFormData(result);
  }, [envClip, formData]);

  return (
    <>
      <ASIconButton
        size="small"
        kind="secondary"
        disabled={!hasEnvirontments}
        onClick={() => {
          copyEnv();
        }}
      >
        <FileCopyOutlinedIcon />
      </ASIconButton>
      <ASIconButton
        size="small"
        kind="secondary"
        disabled={!hasEnvirontments}
        onClick={() => {
          pasteEnv();
        }}
      >
        <AssignmentTurnedInOutlinedIcon />
      </ASIconButton>
      <FormContainer>
        <ASLowCodeFormContainer
          fullWidth
          editable={!disabled && hasEnvirontments}
          defaultValues={formData}
          formDefinition={buildFormDefinition()}
          onSubmit={async (data, options) => {
            slot.saveEnvironments(data);
          }}
        />
      </FormContainer>
    </>
  );
};
