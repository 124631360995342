import { AxiosInstance } from "axios";
import { getMany, getOne } from "./select";
import { wrapAsync } from "../../libs/wrapper";
import { Slot } from "../../models/Slot";

export const Xervice = (instance: AxiosInstance) => {
  return {
    getSlots: wrapAsync(instance, getMany),
    getSlot: wrapAsync(instance, getOne),
    add: async (contractorNo: string, pipelineId: string) => {
      const res = await instance({
        method: "POST",
        url: `/xervice/`,
        data: {
          contractor: contractorNo,
          pipelineId: pipelineId,
        },
      });
      return res;
    },

    update: async (slotId: string, partOfSlot: Partial<Slot>) => {
      return await instance({
        method: "PUT",
        url: `/xervice/${slotId}`,
        data: {
          ...partOfSlot,
        },
      });
    },

    deleteOne: async (slotId: string) => {
      const path = `/xervice/${slotId}`;
      return await instance.delete(path);
    },
  };
};
