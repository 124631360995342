import { SettingNavConf } from "src/cheetah/models/SettingNavConf";
import routes from "src/newtroid-console/routes.json";

export const NTGlobalNavConf: SettingNavConf = {
  sections: [
    {
      navigations: [
        {
          title: "Xervice",
          link: routes.PIPELINES,
        },
        {
          title: "OptX",
          link: routes.OPTX_BROWSER,
        },
        {
          title: "Insight",
          link: routes.ADMIN_XERVICE_BROWSER,
        },
        {
          title: "Infrastructure",
          link: routes.DEVOPS,
        },
      ],
    },
  ],
};
