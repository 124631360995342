import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ASTableRow, ASUserIcon } from "allegro-ui";
import { TableCell } from "@material-ui/core";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import { User } from "src/root/models/user";

type Props = {
  user: User;
};

const StyledTableRow = styled(ASTableRow)`
  background-color: ${(props) => props.theme.secondaryColor};
  height: 60px;
  border-radius: 20%;
`;

export const OEUserListItem: React.FC<Props> = (props) => {
  const { userNoText, nickName, email, avatar } = props.user;
  const history = useNavigate();
  const handleRowClick = () => {
    history(`/activities/${userNoText}`);
  };

  return (
    <StyledTableRow onClick={() => handleRowClick()}>
      <TableCell>
        <ASUserIcon userName={nickName} avatar={avatar} />
      </TableCell>
      <TableCell>{nickName}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{userNoText}</TableCell>

      {/* <TableCell>{lastUpdated}</TableCell> */}
      <TableCell>
        <ArrowForwardIos />
      </TableCell>
    </StyledTableRow>
  );
};
