import { createnXtalAPI } from "src/api";
import { ASBrowserUiConf } from "src/cheetah/components/libs/ASLowCodeBrowser";

export const createOptXBuildHistoryBrowserUi = (
  optXId: string
): ASBrowserUiConf => {
  return {
    newtQ: async (limit: number, skip: number, options: any) => {
      const { search, match } = options;

      const nxtal = createnXtalAPI();
      const newtQuery = {
        shape: {
          contents: "any",
          bases: "any",
        },
        search,
        match,
        order: {
          "bases.created": -1,
        },
        limit,
        skip,
      };

      try {
        const histories = await nxtal.optxv2.buildHistory.dataProvider(
          optXId,
          newtQuery
        );
        return histories;
      } catch (err) {
        console.error(err);
      }
      return [];
    },
    tables: [
      {
        label: "Docker Image Name",
        data: {
          value: "dockerImageFullName",
        },
        component: "LCSimpleColComponent",
        options: {},
      },
      {
        label: "Message",
        data: {
          value: "message",
        },
        component: "LCSimpleColComponent",
        options: {},
      },
      {
        label: "Created",
        data: {
          value: "created",
        },
        component: "LCUnixTimeCellComponent",
        options: {
          dateFormat: "fromNow",
        },
      },
    ],
  };
};
