import { ASLowCodeFormDefinition } from "allegro-low-code-components";

export const createOptXFormDeinition: ASLowCodeFormDefinition = {
  sections: [
    {
      title: "",
      fields: [
        {
          label: "OptX Name",
          type: "text",
          name: "name",
          required: true,
          errorText: "name is required.",
        },
      ],
    },
  ],
  buttonOptions: {
    submitText: "Create",
    updateText: "Update",
  },
};

export const updateOptXFormDeinition: ASLowCodeFormDefinition = {
  sections: [
    {
      title: "",
      fields: [
        {
          label: "OptX Name",
          type: "text",
          name: "name",
          required: true,
          errorText: "name is required.",
        },
        {
          label: "Description",
          type: "multiline",
          name: "description",
          required: false,
          values: undefined,
        },
      ],
    },
  ],
  buttonOptions: {
    submitText: "Create",
    updateText: "Update",
  },
};
