import * as React from "react";

import { ASLowCodeFormContainer } from "allegro-low-code-components";
import { CHTabs } from "src/cheetah/components/molecules/CHTabs";
import { useRegistryDetail } from "src/newtroid-console/hooks/useRegistryDetail";
import { createRegistryFormDefinition } from "src/newtroid-console/config/formUi/registryFormDefinition";
import { XRPageTemplate } from "src/xerver-room/components/templates/XRPageTemplate";

interface Props {
  registryId: string;
}

export const NTRegistrySingleTemplate: React.FunctionComponent<Props> = ({
  ...props
}) => {
  const { registry, canUpdateRegistry } = useRegistryDetail(props.registryId);

  const defaultValues = registry
    ? { ...registry, ...registry.credential }
    : undefined;
  const tabs = [
    {
      label: "Setting",
      component: (
        <React.Suspense fallback={<>loading</>}>
          <ASLowCodeFormContainer
            formDefinition={createRegistryFormDefinition(
              props.registryId,
              defaultValues
            )}
            editable={canUpdateRegistry}
            fullWidth
            isUpdate
            defaultValues={defaultValues}
          />
        </React.Suspense>
      ),
    },
  ];
  return (
    <XRPageTemplate title={registry?.name ?? "---"}>
      {registry ? (
        <CHTabs key="nt-registry-single-tabs" tabs={tabs} />
      ) : (
        <>loading</>
      )}
    </XRPageTemplate>
  );
};
