import React from "react";
import { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { useMyRole } from "src/portal/hooks/useMyRole";
import {
  cursorSlotIdState,
  cursorSlotTypeState,
  slotState,
  slotStatusState,
  cursorPipelineIdState,
  cursorSlotOptXV2VariationFetcherState,
  optXSchemaTypeState,
  optXConfFetcherState,
} from "../recoils";
import { cursorLinkObjectIdState } from "../recoils/linkObjectState";
import { useSwitchOptX } from "./useSwitchOptX";
import { useSlotActions } from "./useSlot";
import { OptXSchemaType, OptXVariation } from "allegro-api";
import { saveSlotActivity } from "../activity-logger/saveSlotActivity";
import { ASLowCodeFormDefinition } from "allegro-low-code-components";

export const useSlotInspector = () => {
  const cursorSlotId = useRecoilValue(cursorSlotIdState);
  const cursorSlotType = useRecoilValue(cursorSlotTypeState);
  const cursorLinkObjectId = useRecoilValue(cursorLinkObjectIdState);
  const cursorSlot = useRecoilValue(slotState(cursorSlotId));
  const slotStatus = useRecoilValue(slotStatusState(cursorSlot.slotId));
  const cursorPipelineId = useRecoilValue(cursorPipelineIdState);
  const [showOptXVersionModal, setShowOptXVersionModal] = useState(false);

  // const [optXVariations, setOptXVariations] = useRecoilState(
  //   optXV2VariationListState(cursorSlot.optXId ?? "")
  // );
  //useReacoilValueはエラーが起きる
  //https://github.com/facebookexperimental/Recoil/issues/439

  const cursorOptXVariationLoadable = useRecoilValueLoadable(
    cursorSlotOptXV2VariationFetcherState(cursorSlot.variationId ?? "")
  );
  const currentOptXSchemaType = useRecoilValue(optXSchemaTypeState);

  const cursorOptXVariation =
    cursorOptXVariationLoadable.state === "hasValue"
      ? cursorOptXVariationLoadable.contents
      : null;

  const slot = useSlotActions(cursorSlot.slotId);
  const optX = useSwitchOptX(cursorSlot.optXId ?? "");

  const role = useMyRole();

  const [showOptXBrowser, setShowOptXBrowser] = useState(false);
  const [tabId, setTabId] = React.useState(0);
  const [canEditPipeline, setCanEditPipeline] = useState(false);

  const optXConfLoadable = useRecoilValueLoadable(
    optXConfFetcherState(`${slot.optXId}/${slot.variationId}`)
  );

  const optXConf =
    optXConfLoadable.state === "hasValue" ? optXConfLoadable.contents : null;

  const hasEnvirontments =
    optXConf !== null && optXConf.environments.length > 0;

  /**
   * versionがfreezeされていなければtrue
   **/
  const isWarningCursorOptXVariation = cursorOptXVariation?.freezed === false;

  /**
   * Slotが起動できる状態か判定する変数
   * OptX Variationが選択されていればtrue
   */
  const canActivateSlot =
    optX?.schemaType === "optxv2"
      ? !!cursorOptXVariation?.variationId
      : !!optX?.optXId;

  const udpatePipelineEditableState = useCallback(async () => {
    const ok = await role.canUpdatePipeline(cursorPipelineId);
    setCanEditPipeline(ok);
  }, [cursorPipelineId, role]);

  /**
   * slot.environmentsからFormDefinitionを作成する関数
   */
  const buildFormDefinition = (): ASLowCodeFormDefinition => {
    return {
      sections: [
        {
          fields:
            optXConf?.environments.map((env) => {
              return { type: "text", ...env };
            }) ?? [],
        },
      ],
    };
  };

  /**
   * 選択したOptXを保存する関数
   */
  const handleChangeOptX = async (optXIds: string[]) => {
    const selectedOptXId = optXIds[0];

    switch (currentOptXSchemaType) {
      case "optxv1": {
        await slot.setOptX(selectedOptXId);
        break;
      }
      case "optxv2": {
        await slot.setOptXV2(selectedOptXId);
        break;
      }
    }

    await saveSlotActivity(
      "info",
      `Slot:${slot.id}のOptXが選択されました。`,
      slot.pipelineId,
      slot.id
    );

    setShowOptXBrowser(false);
  };

  const handleChangeSettingTab = (event: any, newValue: number) => {
    if (tabId !== newValue) {
      setTabId(newValue);
    }
  };

  const handleOpenOptXBrowser = () => {
    setShowOptXBrowser(true);
  };

  const handleCloseOptXBrowser = () => {
    setShowOptXBrowser(false);
  };

  const handleOpenSelectOptXVersionModal = () => {
    setShowOptXVersionModal(true);
  };

  const handleCloseSelectOptXVersionModal = () => {
    setShowOptXVersionModal(false);
  };

  /**
   * 選択したOptX Variationを保存する関数
   * optXのバージョンがv2の場合のみ有効
   */
  const handleChangeOptXVersion = async (optXVariation: OptXVariation) => {
    if (optX && optX.schemaType === OptXSchemaType.optxv2) {
      try {
        await slot.handleChangeOptXVersion(optX.optXId, optXVariation);

        await saveSlotActivity(
          "info",
          `Slot:${slot.id}のOptXバージョンが選択されました。`,
          slot.pipelineId,
          slot.id
        );

        setShowOptXVersionModal(false);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    udpatePipelineEditableState();
  }, [udpatePipelineEditableState]);

  useEffect(() => {
    slot.setSlot(cursorSlot);
  }, [cursorSlot, slot]);

  return {
    cursorSlotId,
    cursorSlot,
    cursorOptXVariation,
    slotActions: slot,
    slotType: cursorSlotType,
    linkObjectId: cursorLinkObjectId,
    slotStatus,
    optX,
    optXConf,

    hasEnvirontments,
    permissions: {
      canRunSlot: true,
      canStopSlot: true,

      canChangeOptX: true,
      canChangeOptXVariation: true,

      canEditSlot: true,
    },

    showOptXBrowser,
    canEditPipeline,
    canActivateSlot,
    tabId,
    showOptXVersionModal,
    isWarningCursorOptXVariation,

    buildFormDefinition,

    handleChangeOptXVersion,
    handleChangeOptX,

    handleChangeSettingTab,

    handleOpenSelectOptXVersionModal,
    handleCloseSelectOptXVersionModal,
    handleOpenOptXBrowser,
    handleCloseOptXBrowser,
  };
};
