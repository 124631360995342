import React from "react";
import styled from "styled-components";

import { OEManagementListTemplate } from "./OEManagementListTemplate";

const BodyCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const MainPageFooter = styled.div`
  margin-top: 20px;
`;

export const OEManagementPageTemplate: React.FC = () => {
  return (
    <BodyCenter>
      <OEManagementListTemplate />
      <MainPageFooter></MainPageFooter>
    </BodyCenter>
  );
};
