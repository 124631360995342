import { OptXSchemaType } from "allegro-api";
import { ASSelect } from "allegro-ui";
import { useOptXV2Actions } from "src/xerver-room/hooks/useOptXV2";
import styled from "styled-components";

const StyledSelect = styled(ASSelect)`
  border: 1px solid ${(props) => props.theme.secondaryBorderColor};
  height: 37px;
`;

export const XROptXVersionSelection: React.FC = () => {
  const optxV2Actions = useOptXV2Actions();

  return (
    <StyledSelect
      kind="secondary"
      data={optxV2Actions.optXVersionSelections}
      value={optxV2Actions.currentOptXSchemaType}
      onChange={(event) => {
        const version = event.target.value as OptXSchemaType;
        optxV2Actions.handleChangeVersion(version);
      }}
    />
  );
};
