import { useCallback, useEffect } from "react";
import { createnXtalAPI } from "src/api";
import { useRecoilState } from "recoil";
import { xfileListState } from "../recoils";
import { XFile } from "allegro-api";

export const useOptXFiles = (optXId: string, variationId: string) => {
  const [xfiles, setXFiles] = useRecoilState(
    xfileListState(`${optXId}_${variationId}`)
  );

  const nxtal = createnXtalAPI();

  const defaultXfiles = useCallback((): XFile[] => {
    return [
      {
        name: "optx-conf.json",
        text: "",
        parentOptXId: optXId,
        parentVariationId: variationId,
        created: new Date(),
        modified: new Date(),
        contractorNo: sessionStorage.contractorNo,
        createdBy: "default",
        readers: ["*"],
        writers: ["*"],
      },
    ];
  }, [optXId, variationId]);
  const initializeXFiles = useCallback(
    async (optXId: string, variationId: string) => {
      if (!optXId || !variationId) {
        return;
      }
      try {
        const xfiles = await nxtal.xfile.fetchMany(optXId, variationId, {
          limit: 100,
          skip: 0,
        });

        setXFiles(xfiles.length > 0 ? xfiles : defaultXfiles());
      } catch (err) {
        console.error(err);
      }
    },
    [defaultXfiles, nxtal.xfile, setXFiles]
  );

  useEffect(() => {
    if (xfiles.length === 0) {
      initializeXFiles(optXId, variationId);
    }
  }, [initializeXFiles, optXId, variationId, xfiles.length]);

  return {
    xfiles,
  };
};
