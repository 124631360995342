import { AxiosInstance } from "axios";
import {
  PipelineX as IPipelineX,
  PipelineXInput,
  createPipelineX,
  createPipelineXInput,
} from "../../models/PipelineX";

export const PipelineX = (instance: AxiosInstance) => {
  /**
   * PipelineX登録APIをリクエストする関数
   */
  const add = async (props: {
    contractorNo: string;
    xervice: PipelineXInput;
  }) => {
    const path = `/contractors/${props.contractorNo}/pipelinex`;
    return await instance.post(path, props.xervice);
  };

  /**
   * PipelineX更新APIをリクエストする関数
   */
  const update = async (props: {
    contractorNo: string;
    pipelineId: string;
    xervice: PipelineXInput;
  }) => {
    const path = `/contractors/${props.contractorNo}/pipelinex/${props.pipelineId}`;
    return await instance.put(path, props.xervice);
  };

  /**
   * PipelineX1件取得APIをリクエストする関数
   */
  const fetchOne = async (
    contractorNo: string,
    pipelineId: string
  ): Promise<IPipelineX | null> => {
    try {
      const res = await instance({
        method: "GET",
        url: `/contractors/${contractorNo}/pipelinex`,
        params: {
          q: {
            where: {
              id: pipelineId,
            },
          },
        },
      });
      if (!res.data[0]) {
        return null;
      }
      const nextPipeline = createPipelineX(res.data[0]);
      return nextPipeline ?? null;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  /**
   * PipelineX一覧取得APIをリクエストする関数
   */
  const fetchMany = async (
    contractorNo: string,
    query: { [key: string]: any }
  ): Promise<IPipelineX[]> => {
    try {
      const res = await instance.get(`/contractors/${contractorNo}/pipelinex`, {
        params: {
          q: query,
        },
      });

      const nextList: Array<IPipelineX> = res.data.map((data: any) => {
        return createPipelineX(data);
      });

      return nextList;
    } catch (err) {
      return [];
    }
  };

  /**
   * PipelineX削除APIをリクエストする関数
   */
  const deleteOne = async (
    contractorNo: string,
    pipelineId: string
  ): Promise<boolean> => {
    const path = `/contractors/${contractorNo}/pipelinex/${pipelineId}`;
    const isDeleted = await instance.delete(path);
    return !!isDeleted;
  };

  return { add, update, fetchOne, deleteOne, fetchMany };
};
