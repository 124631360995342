import React from "react";
import styled from "styled-components";
import { ASNoDataPlaceHolder, ASPageNav } from "allegro-ui";
import { LoadingBox } from "src/opt-editor/components/atoms/OELoddingBox";
import { OESearchTextField } from "src/opt-editor/components";
import {
  MAX_SELECTABLE_OPTX,
  useOptXList,
} from "src/xerver-room/hooks/useOptXList";
import { XROptXV2CardItem } from "../molecules/XROptXV2CardItem";

type Props = {
  selectable?: boolean;
  maxSelectable?: number;
  onChangeSelections?: (optXIds: string[]) => void;
};

const Container = styled.div``;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow: scroll;
  flex-wrap: wrap;
`;

const PageWrapper = styled.div`
  width: 100%;
  > * {
    margin: 0 auto;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  display: flex;
`;

const SearchLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  > *:first-child {
    margin-left: auto;
  }
  > * {
    margin-right: 16px;
  }
  > *:last-child {
    margin-right: 0px;
  }
`;

export const XROptXV2Browser: React.FC<Props> = ({
  maxSelectable = MAX_SELECTABLE_OPTX,
  selectable,
  onChangeSelections,
  ...props
}) => {
  const {
    optXV2List,
    optXSearchWord,
    isLoading,
    hasOptX,
    selectedOptXIds,
    totalPage,
    currentPage,
    handleCardClick,
    handleSearchOptXName,
    handleNavigatePage,
  } = useOptXList({
    maxSelectable,
    selectable,
    onChangeSelections,
  });

  return (
    <Container>
      <SearchLayout>
        <OESearchTextField
          defaultValue={optXSearchWord}
          placeholder="OptX Name"
          onSubmit={(event: any) => {
            handleSearchOptXName(event.target.value);
          }}
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              handleSearchOptXName(event.target.value);
            }
          }}
        />
      </SearchLayout>

      <CardContainer>
        {isLoading ? (
          <LoadingBox />
        ) : (
          <>
            {/* OptXの取得に失敗もしくは取得件数が0件であればPlace holderを表示する */}
            {hasOptX ? (
              optXV2List.map((optX) => {
                return (
                  <XROptXV2CardItem
                    key={`optx-v2-card-${optX.optXId}`}
                    optxv2={optX}
                    selected={
                      selectable ? selectedOptXIds.includes(optX.optXId) : false
                    }
                    onClick={(optXId) => handleCardClick(optXId)}
                  />
                );
              })
            ) : (
              <ASNoDataPlaceHolder>
                データを取得できませんでした。
              </ASNoDataPlaceHolder>
            )}
          </>
        )}

        <PageWrapper>
          <ASPageNav
            count={totalPage}
            page={currentPage}
            onChange={(_, pageId) => {
              handleNavigatePage(pageId);
            }}
          />
        </PageWrapper>
      </CardContainer>
    </Container>
  );
};
