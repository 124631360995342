import {
  Activity,
  ActivityInput,
  ActivityLevel,
  ActivityResource,
} from "allegro-api";
import { createNewtZeroAPI } from "src/api";

const activityResource: ActivityResource = "optx-variation";
const defaultSlotTags = ["newtroid", "nxtal", activityResource];

/**
 * SlotのActivityをPOSTする関数
 * @params level
 * @params message
 * @params optXId Acitivityの対象slotのID
 * @returns 書き込みに成功した場合、Acitivityを返却する
 */
export const saveOptXVariationActivity = async (
  level: ActivityLevel,
  message: string,
  optXId: string,
  variationId: string,
  additionalTags: string[] = []
): Promise<Activity | null> => {
  const newtzero = createNewtZeroAPI();
  const activityInput: ActivityInput = {
    level,
    message,
    tags: [...defaultSlotTags, `optx:${optXId}`, ...additionalTags],
    resource: activityResource,
    resourceId: variationId,
  };

  const resActivity = await newtzero.activity
    .addActivity(activityInput)
    .catch(() => null);
  return resActivity;
};
