import { SettingNavConf } from "src/cheetah/models/SettingNavConf";
import routes from "src/nai-console/routes.json";

export const NAGlobalNavConf: SettingNavConf = {
  sections: [
    {
      navigations: [
        {
          title: "Knowledge",
          link: routes.KNOWLEDGE_ROOT,
        },
        {
          title: "AI Models",
          link: routes.HOME,
        },
      ],
    },
  ],
};
