import { ASButton, ASModal } from "allegro-ui";
import React, { useState } from "react";
import styled from "styled-components";

import { ASLowCodeFormContainer } from "allegro-low-code-components";
import { ASLowCodeFormDefinition } from "allegro-low-code-components";
import { ASLowCodeDataBrowserContainer } from "../LCDataBrowserComponent/newtDataContainer";
import { LCVerticalFiltersContainer } from "../LCFilterComponent";
import { ASBrowserUiConf } from "../../types/LowCodeUiDefinition";

const Header = styled.div`
  width: 100%;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.textColor};
  > * {
    margin-left: 8px;
  }

  > *:first-child {
    margin-left: 0px;
  }
`;

const ButtonGroup = styled.div`
  margin-left: auto;
`;

const MainArea = styled.div`
  background: ${(props) => props.theme.bgPrimaryColor};
`;
const ModalContainer = styled.div`
  width: 70vw;
  max-height: 90vh;
  overflow-y: scroll;
  padding: 25px;
`;

export interface ILCListViewTemplateProps {
  name: string;
  browserUi: ASBrowserUiConf;
  formUi?: ASLowCodeFormDefinition;
  disabledCreate?: boolean;
  createModalTitle?: string;
  createButtonText?: string;
}

export const LCListViewTemplate: React.FC<ILCListViewTemplateProps> = (
  props
) => {
  const { name, browserUi, formUi, disabledCreate } = props;

  const [open, setOpen] = useState<boolean>(false);

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };
  return (
    <>
      <React.Suspense fallback={<></>}>
        <Header>
          <div>
            <LCVerticalFiltersContainer uiConf={browserUi} name={name} />
          </div>

          <ButtonGroup>
            {formUi ? (
              <ASButton
                kind="primary"
                onClick={() => {
                  handleModalOpen();
                }}
                disabled={disabledCreate}
              >
                {props.createButtonText ?? "Create"}
              </ASButton>
            ) : null}
          </ButtonGroup>
        </Header>

        <MainArea>
          <ASLowCodeDataBrowserContainer name={name} uiConf={browserUi} />
        </MainArea>
      </React.Suspense>
      {formUi ? (
        <ASModal
          open={open}
          onClose={handleModalClose}
          title={props.createModalTitle}
          // hidden={!(0 === tabId)}
        >
          <ModalContainer>
            <ASLowCodeFormContainer
              fullWidth
              formDefinition={formUi}
              onClose={handleModalClose}
            />
          </ModalContainer>
        </ASModal>
      ) : null}
    </>
  );
};
