//Libs
import { topDataStateFactory } from "src/opt-editor/utils/topDataBrowserFactory";
import apiConf from "src/opt-editor/apiConf.json";
//Components
//States
import {
  ScienceFolder,
  defaultScienceFolder,
  createScienceFolder,
} from "../models/ScienceFSItem";
import { allegroConsts } from "allegro-models";
//Types

const scienceFolder = topDataStateFactory<ScienceFolder, {}>(
  "ScienceFolder",
  defaultScienceFolder,
  allegroConsts.assetIds.knowledgeFolder,
  apiConf.topdata.treeItem.primaryKey,
  (d) => {
    return createScienceFolder({
      ...d?.contents?.rawdata,
      readers: d.readers ?? ["*"],
      writers: d.writers ?? ["*"],
    });
  }
);

/**
 * @deprecated 後方互換のためのアダプタにつき、削除予定
 */
export const cursorFolderIdState = scienceFolder.cursorItemIdState;

/**
 * @deprecated 後方互換のためのアダプタにつき、削除予定
 */
export const folderState = scienceFolder.item;

export default scienceFolder;
