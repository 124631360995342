import { OptXVariationInput } from "allegro-api";
import { ASLowCodeFormDefinition } from "allegro-low-code-components";
import produce from "immer";
import { createnXtalAPI } from "src/api";
import { fetchGroupMany } from "src/portal/api/fetchGroup";
import { fetchUsersByEmail } from "src/portal/api/fetchUser";
import { activityLogger } from "src/xerver-room/activity-logger";
import { validateUri } from "src/xerver-room/utils/validateUri";

const defaultOptXVariationInput: OptXVariationInput = {
  variationId: undefined,
  optXId: "",
  version: "",
  dockerImageTag: undefined,
  files: [],
  shareLevel: "private",
  allowReadUsers: ["*"],
  allowReadGroups: [],
  allowWriteUsers: ["*"],
  allowWriteGroups: [],
};

const createNewOptXVariationInput = (data: {
  [key: string]: any;
}): OptXVariationInput => {
  return produce(defaultOptXVariationInput, (draft) => {
    draft.variationId =
      data.variationId !== "" ? data.variationId : draft.variationId;
    draft.optXId = data.optXId;

    draft.version = data.version;
    draft.description = data.description;

    draft.dockerImageTag =
      data.dockerImageTag !== "" ? data.dockerImageTag : draft.dockerImageTag;
    draft.files = data.files ?? draft.files;
    draft.shareLevel = data.shareLevel ?? draft.shareLevel;

    draft.allowReadUsers = data.allowReadUsers ?? draft.allowReadUsers;
    draft.allowReadGroups = data.allowReadGroups ?? draft.allowReadGroups;

    draft.allowWriteUsers = data.allowWriteUsers ?? draft.allowWriteUsers;
    draft.allowWriteGroups = data.allowWriteGroups ?? draft.allowWriteGroups;
  });
};

export const createOptXV2VariationFormDefinition = (
  optXId: string,
  variationId?: string,
  shareLevel?: "public" | "private",
  isUpdate?: boolean
): ASLowCodeFormDefinition => {
  const advancedSettings: any[] = !isUpdate
    ? [
        {
          label: "Advanced Setting",
          type: "section",
        },
        {
          label: "Version ID",
          name: "variationId",
          type: "text",
          required: false,
          supportText:
            "Enter OptX Variation ID. By default, it will be generated automatically by API.",
        },
        {
          label: "Docker image tag",
          name: "dockerImageTag",
          supportText:
            "Tag for the Docker image. By default, the tag is the OptX ID plus 8 random alphanumeric characters.",
          type: "text",
        },
      ]
    : [];
  return {
    sections: [
      {
        fields: [
          {
            label: "Version",
            name: "version",
            type: "text",
            required: true,
            errorText: "version is required.",
          },
          {
            label: "Description",
            name: "description",
            type: "multiline",
            required: false,
            values: undefined,
          },
          {
            label: "shareLevel",
            name: "shareLevel",
            type: "radio",
            values: [
              {
                label: "公開",
                value: "public",
              },
              {
                label: "非公開",
                value: "private",
              },
            ],
            active: shareLevel ?? "private",
            supportText:
              "If it is public, you can get the data across all contractors.",
          },
          {
            label: "Readable Users",
            name: "allowReadUsers",
            type: "chip",
            required: true,
            active: ["*"],
            errorText: "readable users are required.",
            supportText:
              "Please enter * to allow access to all contractor users.",
            values: async (searchText) => {
              if (searchText === "*") {
                return [];
              }
              const users = await fetchUsersByEmail(searchText ?? "");
              return users.map((user) => {
                return {
                  label: user.email,
                  value: user.userNoText,
                };
              });
            },
          },
          {
            label: "Readable Groups",
            name: "allowReadGroups",
            type: "chip",
            required: false,
            active: [],
            values: async (searchText) => {
              if (searchText === "*") {
                return [];
              }
              const searchQuery = {
                $or: [
                  { "contents.rawdata.group_name": searchText },
                  { "contents.rawdata.group_id": searchText },
                ],
              };
              const groups = await fetchGroupMany({ matchQ: searchQuery });
              return groups.map((group) => {
                return {
                  label: group.group_name,
                  value: group.group_id,
                };
              });
            },
          },
          {
            label: "Writable Users",
            name: "allowWriteUsers",
            type: "chip",
            required: true,
            errorText: "writable users are required.",
            supportText:
              "Please enter * to allow access to all contractor users.",
            active: ["*"],
            values: async (searchText) => {
              if (searchText === "*") {
                return [];
              }
              const users = await fetchUsersByEmail(searchText ?? "");
              return users.map((user) => {
                return {
                  label: user.email,
                  value: user.userNoText,
                };
              });
            },
          },
          {
            label: "Writable Groups",
            name: "allowWriteGroups",
            type: "chip",
            required: false,
            active: [],
            values: async (searchText) => {
              if (searchText === "*") {
                return [];
              }
              const searchQuery = {
                $or: [
                  { "contents.rawdata.group_name": searchText },
                  { "contents.rawdata.group_id": searchText },
                ],
              };
              const groups = await fetchGroupMany({ matchQ: searchQuery });
              return groups.map((group) => {
                return {
                  label: group.group_name,
                  value: group.group_id,
                };
              });
            },
          },
          ...advancedSettings,
        ],
      },
    ],
    buttonOptions: {
      submitText: "Create",
      updateText: "Update",
    },

    onSubmit: async (data, options) => {
      const nxtal = createnXtalAPI();

      try {
        if (options?.isUpdate) {
          if (!variationId) {
            throw Error("variationId is undefined.");
          }

          const res = await nxtal.optxv2.optXVariation.updateOne(
            optXId,
            variationId,
            data as OptXVariationInput
          );

          if (res) {
            await activityLogger.saveOptXVariationActivity(
              "info",
              `OptX Variation:${variationId}を編集しました。`,
              optXId,
              variationId
            );

            window.location.reload();
            return;
          } else {
            throw Error("Failed to update OptX Variation");
          }
        } else {
          const saveData = createNewOptXVariationInput(data);
          const res = await nxtal.optxv2.optXVariation.add(optXId, saveData);

          if (res) {
            await activityLogger.saveOptXVariationActivity(
              "info",
              `OptX Variation:${res.variationId}を新規登録しました。`,
              res.optXId,
              res.variationId
            );

            window.location.reload();
            return;
          }
        }
      } catch (err) {
        const alertMessage = String(err);
        throw Error(alertMessage);
      }
    },

    onValid: async (data) => {
      const variationId = data.variationId;
      if (variationId && validateUri(variationId)) {
        return {
          error: "error",
          message:
            "Reserved character should not be contained by OptX Version ID.",
        };
      }
    },
  };
};
