import { ASCard } from "allegro-ui";
import styled from "styled-components";

type Attrs = {
  borderColor?: string | null;
};

type Props = {
  selected?: boolean;
};

export const OptXCard = styled(ASCard).attrs<Props>(
  (props): Attrs => ({
    borderColor: props.selected
      ? props.theme.primaryColor
      : props.theme.cardColor,
  })
)<{ selected: boolean | undefined } & Attrs>`
  margin: 8px;
  min-width: 300px;
  width: 360px;
  /* https://www.joshwcomeau.com/css/designing-shadows/ */
  box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
    0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
    0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
  border: ${(props) => `2px solid ${props.borderColor}`};
  /* background: none; */
`;

export const OptXName = styled.h1`
  margin-top: 20px;
  font-weight: bold;
  font-size: 16px;
  overflow-wrap: break-word;
`;

export const OptXId = styled.h6`
  margin-top: 16px;
  opacity: 0.3;
  font-size: 12px;
  /* text-align: right; */
`;

export const OptXIconPlaceHolder = styled.div`
  position: absolute;
  right: 0;
  top: 24px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 24px;
  background: ${(props) => props.theme.bgPrimaryColor};
`;

export const OptXHeader = styled.div`
  width: calc(100% - 75px);
`;

export const OptXCreator = styled.h3`
  margin-top: 12px;
  font-size: 12px;
  color: ${(props) => props.theme.primaryColor};
`;
