import Markdown from "react-markdown";
import styled from "styled-components";

type ASMarkdownProps = {
  children: string | null | undefined;
  className?: string;
};

const MarkdownContainer = styled.div`
  min-height: 100px;
  /* max-height: 400px; */
  color: ${(props) => props.theme.textColor};
  overflow-y: scroll;
  margin-top: 24px;
  line-height: 1.5;
  /* opacity: 0.7; */
  font-size: 14px;

  overflow: scroll;
  white-space: pre-wrap;

  > h1 {
    font-size: 28px;
    font-weight: bold;
  }
  > h2 {
    font-size: 24px;
    font-weight: bold;
  }
  > h3 {
    font-size: 20px;
    font-weight: bold;
  }
  > h4 {
    font-size: 18px;
    font-weight: bold;
  }
  > p {
    font-size: 14px;
    line-height: 1.6;
    opacity: 0.8;
  }
`;

export const ASMarkdown: React.FC<ASMarkdownProps> = (props) => {
  return (
    <MarkdownContainer className={props.className}>
      <Markdown>{props.children}</Markdown>
    </MarkdownContainer>
  );
};
