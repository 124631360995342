/* eslint-disable react-hooks/exhaustive-deps */
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";
import {
  optXCountState,
  optXListPageState,
  optXListSelector,
  optXListFetcherState,
  optXSchemaTypeState,
  optXSearchWordState,
  optXV2ListSelector,
  optxFetchCountOfARequest,
} from "../recoils";
import { useEffect, useState } from "react";
import { OptXSchemaType } from "allegro-api";
import { optXVersionSelections } from "../consts";

/**
 * デフォルト値設定
 */
export const MAX_SELECTABLE_OPTX = 1;

type Props = {
  selectable?: boolean;
  maxSelectable?: number;
  onChangeSelections?: (optXIds: string[]) => void;
};

export const useOptXList = (props: Props) => {
  const { onChangeSelections } = props;
  const maxSelectable = props.maxSelectable ?? MAX_SELECTABLE_OPTX;

  const optXListLoadable = useRecoilValueLoadable(optXListFetcherState);
  const [fetchedOptXInfo, storeOptXInfo] = useRecoilState(optXListFetcherState);
  const optXList = useRecoilValue(optXListSelector);
  const optXV2List = useRecoilValue(optXV2ListSelector);
  const optXCount = useRecoilValue(optXCountState);
  const [optXSearchWord, setOptXSerachWord] =
    useRecoilState(optXSearchWordState);
  const [currentPage, setPage] = useRecoilState(optXListPageState);
  const [currentOptXSchemaType, setCurrentOptXSchemaType] =
    useRecoilState(optXSchemaTypeState);

  const [selectedOptXIds, setSelectedOptXIds] = useState<string[]>([]);

  const isLoading = optXListLoadable.state === "loading";
  const hasOptX =
    currentOptXSchemaType === OptXSchemaType.optxv1
      ? optXList.length > 0
      : optXV2List.length > 0;

  const totalPage =
    optXCount > 0 ? Math.ceil(optXCount / optxFetchCountOfARequest) : 1;

  useEffect(() => {
    // fetchした新規OptXをキャッシュする
    const [fetchedOptXList, count] = fetchedOptXInfo;
    const newOptXList = fetchedOptXList
      .map((fetchedOptX, idx) => {
        const optXCollection = [...optXList, ...optXV2List].find((optX) => {
          return optX.optXId === fetchedOptX.optXId;
        });
        return optXCollection ?? fetchedOptX;
      })
      .filter((optx) => optx);
    storeOptXInfo([newOptXList, count]);
  }, [fetchedOptXInfo]);

  useEffect(() => {
    if (onChangeSelections) onChangeSelections(selectedOptXIds);
  }, [onChangeSelections, selectedOptXIds, optXCount]);

  // OptXのカードがクリックされた際の処理
  const handleCardClick = (optXId: string) => {
    const nextSelected = selectedOptXIds;
    if (!selectedOptXIds.includes(optXId)) nextSelected.push(optXId);
    while (selectedOptXIds.length > maxSelectable) {
      nextSelected.shift();
    }
    setSelectedOptXIds([...nextSelected]);
  };

  // OptX名でOptXを検索する
  const handleSearchOptXName = (name: string) => {
    // setIsCachedOptXList(false);
    setOptXSerachWord(name);
    // storeOptXList(fetchedOptXList);
  };

  // ページング処理
  const handleNavigatePage = (newPage: number) => {
    // setIsCachedOptXList(false);
    setPage(newPage);
    // storeOptXList(fetchedOptXList);
  };

  // バージョン変更処理
  const handleChangeOptXVersion = (version: OptXSchemaType) => {
    setCurrentOptXSchemaType(version);
  };

  return {
    optXList,
    optXV2List,
    totalPage,
    optXSearchWord,
    selectedOptXIds,
    isLoading,
    hasOptX,
    currentPage,
    optXVersionSelections,
    currentOptXSchemaType,
    handleCardClick,
    handleSearchOptXName,
    handleNavigatePage,
    handleChangeOptXVersion,
  };
};
