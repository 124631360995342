import produce from "immer";

// TODO: asset | xerviceにする
export type BundleReferenceKind =
  | "minio"
  | "RawData"
  | "TopData"
  | "TxnData"
  | "xervice";

export type BundleRefenrece = {
  name: string;
  slug: string;
  kind: BundleReferenceKind;
  object?: {
    assetId?: string;
    assetNo?: string;
    assetTags?: string[];
    xerviceId?: string;
  };
};

export type Bundle = {
  bundleId: string;
  slug: string;
  name: string;
  version: string;
  references: BundleRefenrece[];
  comment?: string;
  isDefault?: boolean;
  tags: string[];
};

export const defaultBundle: Bundle = {
  bundleId: "",
  slug: "",
  name: "",
  version: "latest",
  tags: [],
  references: [],
  isDefault: true,
};

export const defaultBundleReference: BundleRefenrece = {
  name: "",
  slug: "",
  kind: "TopData",
  object: {},
};

export const createBundleReference = (data: any): BundleRefenrece => {
  return produce(defaultBundleReference, (draft) => {
    draft.name = data.name ?? defaultBundleReference.name;
    draft.slug = data.slug ?? defaultBundleReference.slug;
    draft.kind = data.kind ?? defaultBundleReference.kind ?? "TopData";
    draft.object = data.object ?? defaultBundleReference.object;
  });
};

export const createBundle = (data: any) => {
  return produce(defaultBundle, (draft) => {
    draft.bundleId = data.bundleId ?? draft.bundleId;
    draft.name = data.name ?? defaultBundle.name;
    draft.slug = data.slug ?? defaultBundle.slug;
    draft.version = data.version ?? defaultBundle.version;
    const referencesObj: any[] = data.references ?? defaultBundle.references;
    draft.references = referencesObj.map((referenceObj: any) => {
      return createBundleReference(referenceObj);
    });
    draft.comment = data.comment;
    draft.tags = data.tags ?? draft.tags;

    draft.isDefault =
      data.isDefault !== undefined ? data.isDefault : draft.isDefault;
  });
};

export const createBundleFromNewtQuery = (data: any) => {
  return createBundle(data.contents.rawdata);
};
