import { OptXVariation } from "allegro-api";
import { ASLowCodeFormContainer } from "allegro-low-code-components";
import { fetchOptXVariationsByVersion } from "src/xerver-room/api/fetchOptXVariations";
import { createSelectOptXVersionFromDefinition } from "src/xerver-room/config/formDefinition/selectOptXVersionFormDefinition";
import styled from "styled-components";

const ModalContainer = styled.div`
  width: 30vw;

  padding: 25px;
`;

type Props = {
  slotId: string;
  optXId: string;
  defaultVersion?: string;
  onSubmit: (optXVariation: OptXVariation) => void;
};

export const XRSelectOptXVersionModalContent: React.FC<Props> = (props) => {
  const { optXId, defaultVersion } = props;

  return (
    <ModalContainer>
      <ASLowCodeFormContainer
        fullWidth
        formDefinition={createSelectOptXVersionFromDefinition(optXId)}
        defaultValues={{
          version: defaultVersion,
        }}
        onSubmit={async (data) => {
          const version = data.version;
          if (version) {
            const optXVariation = await fetchOptXVariationsByVersion(optXId, {
              version,
            });
            if (optXVariation[0]) {
              await props.onSubmit(optXVariation[0]);
            }
          }
        }}
      />
    </ModalContainer>
  );
};
