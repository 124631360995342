import { Bundle } from "allegro-api";
import { Summary } from "../models";

export const createBundleSummaryConf = (bundle: Bundle): Summary => {
  return {
    id: bundle.bundleId,
    name: bundle.name,
    blocks: [],
  };
};
