import React, { useState } from "react";
import { ASButton, ASInputBase } from "allegro-ui";
import styled from "styled-components";
import { PipelineX } from "allegro-api";
import { FormControl } from "@material-ui/core";
import { updatePipeline } from "src/xerver-room/effects/pipelineEffect";
import { useAuth } from "src/root";
import { useSetRecoilState } from "recoil";
import { pipelineListRequestId } from "src/xerver-room/recoils";
import { activityLogger } from "src/xerver-room/activity-logger";

const Form = styled.div`
  margin: 0 auto;
  margin-bottom: 30px;
  max-width: 100%;
  width: 300px;
  display: flex;
  flex-direction: column;
  text-align: left;
  > * {
    margin-top: 24px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
  width: 400px;
  padding: 24px;
  h1 {
    font-size: 32px;
    font-weight: bold;
  }
`;
const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  > * {
    margin-left: 16px;
  }
`;
const StyledSpan = styled.div`
  color: ${(props) => (props.theme.darkMode ? "white" : "black")};
  margin-bottom: 8px;
`;

type Props = {
  pipeline: PipelineX;
  onCancel: () => void;
};
export const RenamePipelineModal: React.FC<Props> = (props) => {
  const { onCancel } = props;
  const [pipelineNameField, setPipelineNameField] = useState(
    props.pipeline.name
  );
  const setPipelineRequestId = useSetRecoilState(pipelineListRequestId);
  const auth = useAuth();

  const onSubmit = async () => {
    await updatePipeline(
      { ...props.pipeline, name: pipelineNameField },
      {
        contractor: auth.contractorNo ?? "",
        apiKey: auth.token ?? "",
      }
    );

    await activityLogger.saveXerviceActivity(
      "info",
      `PipelineX:${props.pipeline.name}の名称を変更しました。`,
      props.pipeline.id
    );

    setPipelineRequestId((prevValue) => prevValue + 1);
    onCancel();
  };

  return (
    <Container>
      名前を変更します。
      <Form>
        <FormControl>
          <StyledSpan>コントラクタ</StyledSpan>
          <ASInputBase
            id="id"
            kind="outline"
            placeholder="入力してください"
            defaultValue={props.pipeline.name}
            onChange={(e) => {
              setPipelineNameField(e.target.value);
            }}
          />
        </FormControl>
      </Form>
      <ButtonGroup>
        <ASButton kind={"primary"} onClick={() => onSubmit()}>
          はい
        </ASButton>
        <ASButton kind={"primary"} onClick={() => onCancel()}>
          いいえ
        </ASButton>
      </ButtonGroup>
    </Container>
  );
};
