import React from "react";

import styled from "styled-components";
import { ASTabs, ASIconButton } from "allegro-ui";
import { Tab } from "@material-ui/core";

//コンポーネント
import { FTTabPanel } from "src/fortress/components/molecules";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useTabs } from "src/cheetah/hooks/useTabViewer";

function a11yProps(index: number) {
  return {
    key: `ch-tab-${index}`,
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ContentArea = styled.div`
  height: 100%;
`;

const CenterLayout = styled.div`
  margin: 0 auto;
`;

const TabContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  /* padding: 24px; */
  padding-bottom: 0;

  align-items: center;
`;

const BackButton = styled(ASIconButton)`
  width: 45px;
  height: 45px;
  margin-right: 16px;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 32px;
  /* width: 100%; */
  /* text-align: center; */
  font-weight: bold;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#000")};
`;

const StyledTabs = styled(ASTabs)`
  /* width: 100%; */
  color: ${(props) => (props.theme.darkMode ? "white" : "black")};
`;

export type CHTabComponentProps = {
  label: string;
  component: React.FC | JSX.Element;
};

export type CHTabsProps = {
  id?: string | number;
  tabs: CHTabComponentProps[];
  title?: string;
  disableBack?: boolean;
  disableUrl?: boolean;
  headerComponent?: any;
  defaultId?: number;
  handleChange?: (value: number) => void;
};

export const CHTabs: React.FC<CHTabsProps> = (props) => {
  const { tabId, handleChange, back } = useTabs(props);

  const renderHeader = () => {
    return (
      props.headerComponent ??
      (props.title ? <Title>{props.title}</Title> : <></>)
    );
  };

  const renderTabContent = () => {
    return props.tabs.map((tab, id) => {
      return (
        <FTTabPanel value={tabId} index={id} key={`chtabs-tab-id-${id}`}>
          <React.Suspense
            fallback={<div key={`chtabs-tab-id-${id}-loading`}>Loading...</div>}
          >
            {tab.component}
          </React.Suspense>
        </FTTabPanel>
      );
    });
  };

  return (
    <div>
      {renderHeader()}
      <ContentArea>
        <TabContainer>
          {!props.disableBack ? (
            <BackButton onClick={back}>
              <ArrowBackIcon fontSize="inherit" />
            </BackButton>
          ) : (
            <></>
          )}

          <StyledTabs value={tabId} onChange={handleChange}>
            {props.tabs.map((tab, id) => (
              <Tab label={tab.label} {...a11yProps(id)} />
            ))}
          </StyledTabs>
        </TabContainer>

        <CenterLayout>{renderTabContent()}</CenterLayout>
      </ContentArea>
    </div>
  );
};
