import React from "react";
import { useParams } from "react-router-dom";
import { NTMainViewTemplate } from "../templates/NTMainViewTemplate";
import { XROptXVersionSingleTemplate } from "src/xerver-room/components/templates/XROptXVersionSingleTemplate";

type PathParams = {
  variationId: string;
  optXId: string;
};
export const NTOptXVersionSinglePage: React.FC = () => {
  const { optXId, variationId } = useParams<PathParams>();

  return (
    <NTMainViewTemplate>
      <XROptXVersionSingleTemplate
        optXId={optXId ?? ""}
        variationId={variationId ?? ""}
      />
    </NTMainViewTemplate>
  );
};
