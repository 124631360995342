import { ASLowCodeDataBrowserContainer } from "src/cheetah/components/libs/ASLowCodeBrowser";
import { createBundle } from "allegro-api";
import { createBundleResourcesBrowserUiConf } from "src/nai-console/config/browserUi/bundleResourcesBrowserUiConf";
import { useBundle } from "src/nai-console/hooks/useBundle";
import styled from "styled-components";

const Container = styled.div`
  /* width: 600px; */
  background: ${(props) => props.theme.bgPrimaryColor};
`;

export const NABundleResources: React.FC = () => {
  const { bundleLoadable } = useBundle();
  return (
    <Container>
      {bundleLoadable.state === "hasValue" ? (
        <ASLowCodeDataBrowserContainer
          name="ai-bundle-resources"
          uiConf={createBundleResourcesBrowserUiConf(
            createBundle(bundleLoadable.contents)
          )}
        />
      ) : (
        <></>
      )}
    </Container>
  );
};
