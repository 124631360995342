import { Controller, Control } from "react-hook-form";
import { ASFormDesign, ASTextFormField } from "../../models/ASLowCodeFormField";
import { ASInputBase } from "allegro-ui";
import { ASFormFieldBase } from "./ASFormFieldBase";
import { useState } from "react";

type ASFormFieldProps = {
  id?: number | string;
  control: Control;
  defaultValue?: string;
  error: any;
  editable?: boolean;
  active?: string;
  design?: ASFormDesign;
  supportText?: string;
  inputOnly?: boolean;
  onChange?: (text: string) => void;
};

export const ASSecretFormField = (
  props: ASFormFieldProps & ASTextFormField
) => {
  const {
    id,
    label,
    name,
    required,
    control,
    active,
    values,
    editable,
    error,
    errorText,
    design,
    supportText,
    inputOnly,
    placeholder,
  } = props;

  const defaultValue =
    props.defaultValue && props.defaultValue !== ""
      ? props.defaultValue
      : active;

  const [text, setText] = useState(defaultValue);

  return (
    <div key={`lc-create-data-form-${id}-${name}`}>
      <Controller
        key={`as-secret-form-controller-${id}-${label}-${defaultValue}`}
        name={name}
        control={control}
        rules={{ required: required }}
        defaultValue={defaultValue ?? active ?? undefined}
        render={({ field: { onChange } }) => (
          <ASFormFieldBase
            error={error}
            errorText={errorText}
            label={label}
            design={design}
            required={required}
            editable={editable}
            supportText={supportText}
          >
            <ASInputBase
              value={text}
              key={`as-secret-form-component-${name}-${defaultValue}`}
              // multiline
              placeholder={placeholder}
              type="password"
              fullWidth
              disabled={!(editable ?? false)}
              kind={design === "digicho" ? "digicho" : "secondary"}
              onChange={(e) => {
                const newText = e.target.value;
                if (props.onChange) {
                  props.onChange(newText);
                }
                setText(newText);
                onChange(newText);
              }}
            />
          </ASFormFieldBase>
        )}
      />
    </div>
  );
};
