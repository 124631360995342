import { ASSelectItem } from "allegro-ui";

export const optXVersionSelections: ASSelectItem[] = [
  {
    value: "optxv2",
    label: "Version 2.0",
  },
  {
    value: "optxv1",
    label: "Version 1.0",
  },
];
