import { Registry } from "allegro-api";
import { useCallback, useEffect, useState } from "react";
import { createnXtalAPI } from "src/api";
import { useMyRole } from "src/portal/hooks/useMyRole";
import { useAuth } from "src/root";

export const useRegistryDetail = (registryId: string) => {
  const auth = useAuth();
  const role = useMyRole();

  const nxtal = createnXtalAPI();

  const [registry, setRegistry] = useState<Registry | null>(null);
  const [initialized, setInitialized] = useState(false);

  const canUpdateRegistry =
    registry?.contractorNo === auth.contractorNo && role.canUpdateRegistry;

  const initialize = useCallback(async () => {
    const newRegistry = await nxtal.registry.fetchOne(registryId);
    if (newRegistry) {
      setRegistry(newRegistry);
    }
  }, [nxtal.registry, registryId]);
  useEffect(() => {
    if (!initialized) {
      initialize();
      setInitialized(true);
    }
  }, [initialize, initialized]);

  return {
    registry,
    canUpdateRegistry,
  };
};
