import { OptXVariation } from "allegro-api";
import { ASLowCodeFormContainer } from "allegro-low-code-components";
import { ASModal, ASSwitchButton } from "allegro-ui";
import { createOptXV2VariationFormDefinition } from "src/xerver-room/config/formDefinition/optXV2VariationFormDefinition";
import styled from "styled-components";
import { XROptXBlock } from "../molecules/XROptXBlock";
import { useState } from "react";
import { createnXtalAPI } from "src/api";
import { ConfirmModalContents } from "src/kinzaza/components";
import { useAuth } from "src/root";
import { useMyRole } from "src/portal/hooks/useMyRole";
import { activityLogger } from "src/xerver-room/activity-logger";

const Container = styled.div``;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin-left: auto;
  /* justify-content: end; */
`;

type OptXV2VersionUpdateFormProps = {
  optXVariation?: OptXVariation | null;
};

export const XROptXV2VersionUpdateForm: React.FC<OptXV2VersionUpdateFormProps> =
  (props) => {
    const { optXVariation } = props;

    const auth = useAuth();
    const role = useMyRole();
    const nxtal = createnXtalAPI();
    const [showFreezeModal, setShowFreezeModal] = useState(false);
    const [isFreeze, setIsFreeze] = useState(optXVariation?.freezed ?? false);

    const canUpdateOptXVersion =
      auth.contractorNo === optXVariation?.contractorNo &&
      role.canUpdateOptXVariation;

    const handleOpenFreezeModal = () => {
      setShowFreezeModal(true);
    };

    const handleCloseFreezeModal = () => {
      setShowFreezeModal(false);
    };

    const handleToggleFreeze = async () => {
      const newIsFreeze = !isFreeze;

      if (!optXVariation) {
        throw Error("optXVariation is undefined.");
      }

      try {
        if (newIsFreeze) {
          const hasFreezed = await nxtal.optxv2.optXVariation.freeze(
            optXVariation?.optXId ?? "",
            optXVariation?.variationId ?? ""
          );
          if (hasFreezed) {
            await activityLogger.saveOptXVariationActivity(
              "info",
              `OptX Variation:${optXVariation?.variationId}がフリーズされました。`,
              optXVariation.optXId,
              optXVariation.variationId
            );
          }
        } else {
          const hasUnlocked = await nxtal.optxv2.optXVariation.unlockFreeze(
            optXVariation?.optXId ?? "",
            optXVariation?.variationId ?? ""
          );

          if (hasUnlocked) {
            await activityLogger.saveOptXVariationActivity(
              "info",
              `OptX Variation:${optXVariation?.variationId}のフリーズが解除されました。`,
              optXVariation.optXId,
              optXVariation.variationId
            );
          }
        }

        setShowFreezeModal(false);
        setIsFreeze(newIsFreeze);
      } catch (err) {
        console.error(err);
      }
    };

    return (
      <Container>
        {optXVariation ? (
          <>
            <XROptXBlock title="Version Setting">
              <ASLowCodeFormContainer
                fullWidth
                isUpdate
                editable={canUpdateOptXVersion && !isFreeze}
                formDefinition={createOptXV2VariationFormDefinition(
                  optXVariation.optXId,
                  optXVariation.variationId,
                  undefined,
                  true
                )}
                defaultValues={optXVariation ?? {}}
              />
            </XROptXBlock>

            <XROptXBlock
              title="Freeze this version"
              description="If you turn on this button, you will not be able to update this
                version."
            >
              <ButtonContainer>
                <ASSwitchButton
                  checked={isFreeze}
                  disabled={!canUpdateOptXVersion}
                  onClick={handleOpenFreezeModal}
                />
              </ButtonContainer>
              <ASModal open={showFreezeModal} onClose={handleCloseFreezeModal}>
                <ConfirmModalContents
                  onCancel={handleCloseFreezeModal}
                  onSubmit={handleToggleFreeze}
                >
                  {isFreeze
                    ? `Unlock Version:${optXVariation.version}?`
                    : `Freeze Version:${optXVariation.version}?`}
                </ConfirmModalContents>
              </ASModal>
            </XROptXBlock>
          </>
        ) : (
          <></>
        )}
      </Container>
    );
  };
