import axios, { AxiosInstance } from "axios";
import { DEFAULT_HOST, API_VERSION } from "./statics";
import * as topData from "./topdata";
import * as optX from "./optx";
import * as initializer from "./initialize";
import * as users from "./users";
import { NewtZero } from "./newtzero";
import { nAuth } from "./nauth";
import { nXtal } from "./nxtal";
import * as models from "./models";

export * from "./models";
namespace favy {
  export const init = initializer.init;
  export const instance = initializer.instance;

  export const useTopData = topData.useTopData;
  export const topdata = topData.actions;
  export const topdataV2 = topData.actionsV2;

  export const useOptX = optX.useOptX;
  export const optx = optX.actions;

  export const deActivateUser = users.deActivateUser;
  export const reActivateUser = users.reActivateUser;

  export const newtzero = NewtZero;
  export const nauth = nAuth;
  export const nxtal = nXtal;
  export const types = models;
}

export default favy;
