import React from "react";
import styled from "styled-components";
import CHServerSinglePageTemplate from "src/cheetah/components/templates/CHServerSinglePageTemplate";
import { useParams } from "react-router-dom";
import { NTMainViewTemplate } from "../templates/NTMainViewTemplate";

const Container = styled.div`
  padding: 25px;
`;

type Props = {};

export const NTServerSinglePage: React.FunctionComponent<Props> = ({
  ...props
}) => {
  const params = useParams();

  return params.itemId ? (
    <NTMainViewTemplate>
      <Container>
        <CHServerSinglePageTemplate itemId={params.itemId} />
      </Container>
    </NTMainViewTemplate>
  ) : (
    <>ERROR</>
  );
};
