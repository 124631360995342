import axios, { AxiosInstance } from "axios";
import {
  NewtQuery,
  OptXV2 as OptX,
  OptXV2Input,
  createOptXV2FromNewtData,
} from "../../models";
import { OptXVariation } from "./variation";
import { OptXBuildHistoryService } from "./buildHistory";

export const OptXV2 = (instance: AxiosInstance) => {
  const basePath = "/newtzero";

  const optXVariation = OptXVariation(instance);
  const buildHistory = OptXBuildHistoryService(instance);

  const add = async (optx: OptXV2Input): Promise<OptX | null> => {
    const path = basePath + "/optx";
    try {
      const res = await instance.post(path, optx);
      if (res.status === 200) {
        return createOptXV2FromNewtData(res.data.message);
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errMsg = err.response?.data.message ?? "Failed to create OptX";
        throw Error(errMsg);
      }
    }
    return null;
  };

  const fetchOne = async (optXId: string): Promise<OptX | null> => {
    const path = basePath + `/optx/${optXId}`;
    try {
      const res = await instance.get(path);
      if (res.status === 200) {
        return createOptXV2FromNewtData(res.data.message);
      }
    } catch (err) {
      console.error(err);
    }
    return null;
  };

  /**
   *  OptXの一覧を取得する関数
   * @returns OptX一覧とデータ件数を返却する
   * */

  const fetchMany = async (options?: {
    limit?: number;
    skip?: number;
    count?: boolean;
    explain?: boolean;
  }): Promise<[OptX[], number]> => {
    const path = basePath + "/optx";
    const params = {
      ...options,
    };

    try {
      const res = await instance.get(path, {
        params: params,
      });

      if (res.status === 200) {
        return [
          res.data.message.map((data: { [key: string]: any }) => {
            return createOptXV2FromNewtData(data);
          }),
          res.data.totalDataCount ?? 0,
        ];
      }
    } catch (err) {
      console.error(err);
    }

    return [[], 0];
  };

  /**
   *  OptXの一覧を取得する関数, DataProviderAPIでNewtQueryを使用して一覧を取得する
   * @returns OptX一覧とデータ件数を返却する
   * */
  const dataProvider = async (
    newtQuery: NewtQuery,
    options?: { count?: boolean; explain?: boolean }
  ): Promise<[OptX[], number]> => {
    const path = basePath + "/optx/dataprovider";
    try {
      const res = await instance.post(path, newtQuery, { params: options });
      if (res.status === 200) {
        return [
          res.data.message.map((data: { [key: string]: any }) => {
            return createOptXV2FromNewtData(data);
          }),
          res.data.totalDataCount ?? 0,
        ];
      }
    } catch (err) {
      console.error(err);
    }

    return [[], 0];
  };

  const updateOne = async (
    optXId: string,
    optx: OptXV2Input
  ): Promise<boolean> => {
    const path = basePath + `/optx/${optXId}`;
    try {
      const res = await instance.put(path, optx);
      if (res.status === 200) {
        return true;
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errMsg = err.response?.data.message ?? "Failed to update OptX";
        throw Error(errMsg);
      }
    }

    return false;
  };

  const deleteOne = async (optXId: string): Promise<boolean> => {
    const path = basePath + `/optx/${optXId}`;
    try {
      const res = await instance.delete(path);
      if (res.status === 200) {
        return true;
      }
    } catch (err) {
      console.error(err);
    }

    return false;
  };

  return {
    add,
    fetchOne,
    fetchMany,
    dataProvider,
    updateOne,
    deleteOne,
    optXVariation,
    buildHistory,
  };
};
