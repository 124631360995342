import { createnXtalAPI } from "src/api";
import { ASBrowserUiConf } from "src/cheetah/components/libs/ASLowCodeBrowser";
import { activityLogger } from "src/xerver-room/activity-logger";

export const createOptxV2VariationBrowserUi = (
  optXId: string
): ASBrowserUiConf => {
  return {
    newtQ: async (limit: number, skip: number, options: any) => {
      const nxtal = createnXtalAPI();

      try {
        const [variations] = await nxtal.optxv2.optXVariation.fetchMany(
          optXId,
          {
            limit,
            skip,
          }
        );
        return variations;
      } catch (err) {
        console.error(err);
      }

      return [];
    },
    tables: [
      {
        label: "Version",
        component: "LCTextLinkCellComponent",
        data: {
          label: "version",
          id: "variationId",
        },
        statics: {
          path: `/optx/${optXId}/version/{id}`,
        },
        options: {
          dateFormat: "wareki",
          editable: false,
        },
      },
      {
        label: "Description",
        component: "LCSimpleColComponent",
        data: {
          value: "description",
        },
        options: {
          dateFormat: "wareki",
          editable: false,
        },
      },
      {
        label: "Freezed",
        component: "LCSimpleColComponent",
        data: {
          value: "freezed",
        },
        options: {
          dateFormat: "wareki",
          editable: false,
        },
      },
      {
        label: "Last Updated",
        component: "LCUnixTimeCellComponent",
        data: {
          value: "modified",
        },
        options: {
          dateFormat: "default",
          editable: false,
        },
      },
      {
        label: "Variation ID",
        component: "LCSimpleColComponent",
        data: {
          value: "variationId",
        },
        options: {
          dateFormat: "wareki",
          editable: false,
        },
      },
      {
        label: "",
        component: "LCDeleteButtonComponent",
        data: {
          value: "variationId",
        },
        options: {
          onSubmit: async (variationId: string) => {
            const nxtal = createnXtalAPI();
            try {
              const res = await nxtal.optxv2.optXVariation.deleteOne(
                optXId,
                variationId
              );
              if (res) {
                await activityLogger.saveOptXVariationActivity(
                  "info",
                  `OptX Variation:${variationId}を削除しました。`,
                  optXId,
                  variationId
                );
              }
            } catch (err) {
              console.error(err);
            }
          },
        },
      },
    ],
  };
};
