import { createnXtalAPI } from "src/api";
import { ASBrowserUiConf } from "src/cheetah/components/libs/ASLowCodeBrowser";
import { activityLogger } from "src/xerver-room/activity-logger";

export const registryBrowserUi: ASBrowserUiConf = {
  // fetchType: "dataFetcher",
  newtQ: async (limit: number, skip: number, options) => {
    const { search, match } = options ?? {};
    const nxtal = createnXtalAPI();
    const newtQuery = {
      limit,
      skip,
      search,
      match,
      order: {
        "bases.created": -1,
      },
      shape: {
        contents: "any",
        bases: "any",
      },
    };

    const [registries] = await nxtal.registry.dataProvider(newtQuery);
    return registries ?? [];
  },
  verticalFilters: [
    {
      label: "Search",
      design: "search",
      selections: [],
      callback: (value: string) => {
        return {
          $or: [
            {
              "contents.rawdata.name": {
                $regex: value,
                $options: "i",
              },
            },
          ],
        };
      },
    },
  ],
  tables: [
    {
      label: "Name",
      component: "LCTextLinkCellComponent",
      data: {
        label: "name",
        id: "registryId",
      },
      statics: {
        path: "/devops/registry/{id}",
      },
      options: {
        dateFormat: "wareki",
        editable: false,
      },
    },
    {
      label: "Host",
      component: "LCSimpleColComponent",
      data: {
        value: "host",
      },
      options: {
        dateFormat: "wareki",
        editable: false,
      },
    },
    {
      label: "version",
      component: "LCSimpleColComponent",
      data: {
        value: "version",
      },
      options: {
        dateFormat: "wareki",
        editable: false,
      },
    },
    {
      label: "Last Updated",
      component: "LCUnixTimeCellComponent",
      data: {
        value: "modified",
      },
      options: {
        dateFormat: "wareki",
        editable: false,
      },
    },
    {
      label: "Registry ID",
      component: "LCSimpleColComponent",
      data: {
        value: "registryId",
      },
      options: {
        dateFormat: "wareki",
        editable: false,
      },
    },
    {
      label: "",
      component: "LCDeleteButtonComponent",
      data: {
        value: "registryId",
      },
      options: {
        onSubmit: async (registryId: string) => {
          const nxtal = createnXtalAPI();
          try {
            const hasDeleted = await nxtal.registry.deleteOne(registryId);

            if (hasDeleted) {
              await activityLogger.saveRegistryActivity(
                "info",
                `Registry:${registryId}を削除しました。`,
                registryId
              );
            }
          } catch (err) {
            console.error(err);
          }
        },
      },
    },
  ],
};
