import { LCListViewTemplate } from "src/cheetah/components/libs/ASLowCodeBrowser";
import { registryBrowserUi } from "src/newtroid-console/config/browserUi/registryBrowserUi";
import { createRegistryFormDefinition } from "src/newtroid-console/config/formUi/registryFormDefinition";
import { useMyRole } from "src/portal/hooks/useMyRole";
import styled from "styled-components";

const Container = styled.div``;

type RegistryListTemplateProps = {};

export const NTRegistryListTemplate: React.FC<RegistryListTemplateProps> = (
  props
) => {
  const role = useMyRole();
  const canCreateRegistry = role.canCreateRegistry;
  return (
    <Container>
      <LCListViewTemplate
        name="nt-registry-page"
        formUi={createRegistryFormDefinition()}
        browserUi={registryBrowserUi}
        disabledCreate={!canCreateRegistry}
        createModalTitle="New Registry"
        createButtonText="New Registry"
      />
    </Container>
  );
};
