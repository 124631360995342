import { ASLowCodeFormDefinition } from "allegro-low-code-components";
import { createNewtZeroAPI } from "src/api";

export const alertCreatingFormDefinition: ASLowCodeFormDefinition = {
  sections: [
    {
      title: "Alert Settings",
      description: "Creates alert settings.",
      fields: [
        {
          label: "Alert Id",
          name: "alertJobId",
          type: "text",
          active: "",
          values: undefined,
          required: false,
          errorText: "",
        },
        {
          label: "Alert Name",
          name: "alertJobName",
          type: "text",
          active: undefined,
          values: undefined,
          required: true,
          errorText: "Alert Name is required.",
        },
        {
          label: "Tags",
          name: "tags",
          type: "chip",
          active: [],
          values: undefined,
          required: true,
          errorText: "Tags is required.",
        },
        {
          label: "Collection Type",
          name: "collectionType",
          type: "checkbox",
          active: [],
          values: ["Cold", "Warm", "Hot"],
        },

        {
          label: "Collection",
          name: "collection",
          type: "text",
          active: "",
          required: true,
          values: undefined,
        },

        {
          label: "Script",
          name: "script",
          type: "multiline",
          active: "",
          values: undefined,
          required: true,
          supportText: "runtime: TypeScript, libs: axios, moment",
          errorText: "Script is required.",
        },

        {
          label: "Executer (SlotId)",
          name: "executer",
          type: "text",
          active: "",
          values: undefined,
          required: true,
          supportText: "",
          errorText: "Executer is required",
        },

        {
          label: "Cron",
          name: "cron",
          type: "text",
          active: "",
          values: undefined,
          required: true,
          supportText: "*/5 * * * * e.g.",
          errorText: "Cron is required",
        },

        {
          label: "Enable",
          name: "enable",
          type: "radio",
          active: "",
          values: ["Enabled", "Disabled"],
          required: true,
          supportText: "*/5 * * * * e.g.",
          errorText: "Cron is required",
        },
      ],
    },
  ],
  onValid: async (d, options) => {
    // validation
    // if (options?.isUpdate) {
    //   //更新時
    //   return {};
    // } else {
    //   //新規作成
    //   const [fetchalert] = await newtOut({
    //     collection: "alert",
    //     limit: 1,
    //     primaryKey: "name",
    //     search: {
    //       name: d.name,
    //     },
    //     shape: {
    //       name: "any",
    //     },
    //   });
    //   console.log(fetchalert);

    //   if (fetchalert.length > 0) {
    //     return {
    //       error: "duplicated name",
    //       message: "同じアセット名が既に存在します。",
    //     };
    //   }
    // }
    return {};
  },
  onSubmit: async (d, options) => {
    //互換性のあるアセットを作成する。
    let monitorId: string | undefined = undefined;
    const urlString = window.location.href; // 現在のURLを取得
    const regex = /monitor\/(.*)/; // "monitor/"の後に続くすべての文字列をキャプチャする正規表現

    const match = urlString.match(regex);
    if (match) {
      const desiredString = match[1]; // キャプチャされた部分の取得
      monitorId = desiredString;
      // ここで必要な処理を行う
    } else {
      console.log("URLが指定された形式ではありません。");
    }

    const body = {
      monitorId,
      alertJobName: d["alertJobName"],
      collectionType: d["collectionType"],
      tags: d["tags"],
      collection: d["collection"],
      script: d["script"],
      executer: d["executer"],
      enable: d["enable"] === "Enabled",
      cron: d["cron"],
      alertJobId: d["alertJobId"],
      status: "UPDATED",
    };
    try {
      const newtzero = createNewtZeroAPI(2);
      if (options?.isUpdate) {
        if (!body.alertJobId) {
          console.error("no alertJobId");
          return;
        }
        await newtzero.alertJob.update(body);
      } else {
        await newtzero.alertJob.create(body);
        window.location.reload();
      }

      // const res = await newtzero.asset.save(body, true);

      //   if (res.status === 200) {
      //     window.location.reload();
      //   }
      //   return res.data;
      return;
    } catch (err) {
      console.error(err);
      return;
    }
  },
};
