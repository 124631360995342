import React from "react";
import styled from "styled-components";
import AddIcon from "@material-ui/icons/Add";
import { Grid } from "@material-ui/core";

//za
//components
//
import { ASModal, ASIconButton } from "allegro-ui";
import { PipelineCard } from "../molecules";
import { CreatePipelineModal } from "../organisms";
import { usePipelineList } from "src/xerver-room/hooks";
import { LoadingBox } from "src/opt-editor/components/atoms/OELoddingBox";
import { useMyRole } from "src/portal/hooks/useMyRole";
import { MDNoDataPlaceHolder } from "src/medican/components/atoms";
import { DeleteConfirmModalContents } from "src/kinzaza/components";
import { OESearchTextField } from "src/opt-editor/components";
import { XRPageTemplate } from "./XRPageTemplate";

const Root = styled.div``;

const Body = styled.div`
  padding: 12px;
`;

const RightBottomContent = styled.div`
  position: fixed;

  right: 24px;
  bottom: 24px;
`;

type Props = {};

const PipelineAddButton = styled(ASIconButton)`
  border-radius: 100px;
`;

export const XRPipelineListPageTemplate: React.FC<Props> = (props) => {
  const role = useMyRole();
  const pipelineList = usePipelineList();

  const PipelineGroup = () => {
    if (pipelineList.hasPipelines) {
      return (
        <MDNoDataPlaceHolder>
          データがありません。リロードをお試しください。
        </MDNoDataPlaceHolder>
      );
    }

    return pipelineList.isLoadingPipelineX ? (
      <LoadingBox />
    ) : (
      pipelineList.pipelines.map((pipeline, id) => {
        return (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={`pcd-${pipeline.name}-${id}`}
          >
            <PipelineCard
              pipeline={pipeline}
              handleDeleteModal={pipelineList.handleDeleteModalOpen}
            ></PipelineCard>
          </Grid>
        );
      })
    );
  };
  return (
    <Root>
      <XRPageTemplate
        title="Xervice"
        headerComponents={
          <OESearchTextField
            defaultValue={pipelineList.searchWord}
            placeholder="Xervice Name"
            onSubmit={(event: any) => {
              pipelineList.handleSubmitSearchPipelineWord(event.target.value);
            }}
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                pipelineList.handleSubmitSearchPipelineWord(event.target.value);
              }
            }}
          />
        }
      >
        <Body>
          <Grid container spacing={2}>
            {PipelineGroup()}
          </Grid>
        </Body>
        <ASModal
          title="New Xervice"
          open={pipelineList.showCreateModal}
          onClose={pipelineList.handleCreateModalClose}
        >
          <CreatePipelineModal onSubmit={pipelineList.handleCreatePipelineX} />
        </ASModal>
        <ASModal
          open={pipelineList.showDeleteModal}
          onClose={pipelineList.handleDeleteModalClose}
        >
          <DeleteConfirmModalContents
            onSubmit={() => pipelineList.handleDeletePipelineX()}
            onCancel={pipelineList.handleDeleteModalClose}
          />
        </ASModal>
        <RightBottomContent>
          {role.canCreatePipelineX ? (
            <PipelineAddButton
              kind={"primary"}
              onClick={pipelineList.handleCreateModalOpen}
            >
              <AddIcon />
            </PipelineAddButton>
          ) : null}
        </RightBottomContent>
      </XRPageTemplate>
    </Root>
  );
};
