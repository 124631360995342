import React from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import routes from "./routes.json";
import { PrivateRoute } from "src/root";

import {
  NTPipelineListPage,
  NTOptXBrowsePage,
  NTPipelineEditorPage,
  NTAdminXerviceBrowserPage,
  NTManagementPage,
  NTActivityPage,
  NTSettingProfilePage,
  NTSettingTeamPage,
  NTSettingSecretsPage,
  NTRegistrySinglePage,
} from "src/newtroid-console/components/pages";

//
// portal
//
import { PTAppListPage } from "src/portal/components/pages/";

import { NTServerSinglePage } from "src/newtroid-console/components/pages/NTServerSinglePage";
import { createUserAuthPath } from "src/cheetah/components/UserAuthPage/models/userAuthRoutes";
import { createUserAuthRoutes } from "src/cheetah/components/UserAuthPage/createUserAuthRoutes";
import { NTHeaderLogo } from "./components/atoms/NTHeaderLogo";
import { NTInfrastructurePage } from "./components/pages/NTInfrastructurePage";
import { NTSettingGroupPage } from "./components/pages/NTSettingGroupPage";
import { NTSettingGroupSinglePage } from "./components/pages/NTSettingGroupSinglePage";
import { NTOptXSinglePage } from "./components/pages/NTOptXSinglePage";
import { NTOptXVersionSinglePage } from "./components/pages/NTOptXVersionSinglePage";

const Routes: React.FC = () => {
  const authRoutes = createUserAuthPath({
    home: routes.HOME,
    login: routes.LOGIN,
    signUp: routes.SIGNUP,
    confirmEmail: routes.CONFIRMEMAIL,
    provisionEmail: routes.PROVISIONEMAIL,
    forgotPassword: routes.FORGOT_PASSWORD,
    rescuePassword: routes.RESCUE_PASSWORD,
  });

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {createUserAuthRoutes({
          logo: <NTHeaderLogo />,
          appName: "Newtroid",
          routes: authRoutes,
        })}
        <Route
          path={routes.APPS}
          element={
            <PrivateRoute>
              <PTAppListPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.SETTING_SECRETS}
          element={
            <PrivateRoute>
              <NTSettingSecretsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.SETTING_PROFILE}
          element={
            <PrivateRoute>
              <NTSettingProfilePage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.SETTING_GROUP}
          element={
            <PrivateRoute>
              <NTSettingGroupPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.SETTING_GROUP_SINGLE}
          element={
            <PrivateRoute>
              <NTSettingGroupSinglePage />
            </PrivateRoute>
          }
        />

        <Route
          path={routes.PIPELINE_EDITOR}
          element={
            <PrivateRoute>
              <NTPipelineEditorPage />
            </PrivateRoute>
          }
        />
        {/* グローバルメニューのリンク */}

        <Route
          path={routes.PIPELINES}
          element={
            <PrivateRoute>
              <NTPipelineListPage />
            </PrivateRoute>
          }
        />

        <Route
          path={routes.OPTX_VERSION_SINGLE}
          element={
            <PrivateRoute>
              <NTOptXVersionSinglePage />
            </PrivateRoute>
          }
        />

        <Route
          path={routes.OPTX_SINGLE}
          element={
            <PrivateRoute>
              <NTOptXSinglePage />
            </PrivateRoute>
          }
        />

        <Route
          path={routes.OPTX_BROWSER}
          element={
            <PrivateRoute>
              <NTOptXBrowsePage />
            </PrivateRoute>
          }
        />
        {/* その他のリンク */}
        <Route
          path={routes.SETTING_TEAMS}
          element={
            <PrivateRoute>
              <NTSettingTeamPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.SERVER_SINGLE}
          element={
            <PrivateRoute>
              <NTServerSinglePage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.REGISTRY_SINGLE}
          element={
            <PrivateRoute>
              <NTRegistrySinglePage />
            </PrivateRoute>
          }
        />

        <Route
          path={routes.DEVOPS}
          element={
            <PrivateRoute>
              <NTInfrastructurePage />
            </PrivateRoute>
          }
        />

        <Route
          path={routes.ADMIN_XERVICE_BROWSER}
          element={
            <PrivateRoute>
              <NTAdminXerviceBrowserPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.MANAGEMENT}
          element={
            <PrivateRoute>
              <NTManagementPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.ACTIVITIES}
          element={
            <PrivateRoute>
              <NTActivityPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.HOME}
          element={
            <PrivateRoute>
              <NTPipelineListPage />
            </PrivateRoute>
          }
        />
      </>
    )
  );
  //pathは最短マッチされるのでSCIENCEは一番下にする。
  return <RouterProvider router={router} />;
};

export default Routes;
