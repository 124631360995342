import produce from "immer";
import { BasesSchema } from "./Base";

export type XFileInput = {
  name: string;
  text?: string;
  parentOptXId: string;
  parentVariationId: string;
};

export type XFile = XFileInput & BasesSchema;

const defaultXFile: XFile = {
  name: "",
  parentOptXId: "",
  parentVariationId: "",
  created: new Date(),
  modified: new Date(),
  contractorNo: "",
  createdBy: "",
  readers: ["*"],
  writers: ["*"],
};

export const createXFileFromNewtData = (data: { [key: string]: any }) => {
  const rawdata = data.contents.rawdata;
  const bases = data.bases;
  return produce(defaultXFile, (draft) => {
    draft.name = rawdata.name;
    draft.text = rawdata.text;
    draft.parentOptXId = rawdata.parentOptXId;
    draft.parentVariationId = rawdata.parentVariationId;

    draft.created = bases.created;
    draft.modified = bases.modified;
    draft.createdBy = bases.userNo;
    draft.readers = bases.readers ?? data.readers ?? draft.readers;
    draft.writers = bases.writers ?? data.writers ?? draft.writers;
  });
};
