import { useCallback, useState } from "react";
import { createnXtalAPI } from "src/api";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";
import { userState } from "src/root/recoils/userState";
import { createPipelineXInput } from "allegro-api";
import {
  pipelineListRequestId,
  pipelineListState,
  pipelineSearchWordState,
} from "../recoils";
import { PipelineX } from "allegro-api";
import { activityLogger } from "../activity-logger";

export const usePipelineList = () => {
  const pipelinesLoadable = useRecoilValueLoadable(pipelineListState);
  const [searchWord, setSearchWord] = useRecoilState(pipelineSearchWordState);

  const auth = useRecoilValue(userState);
  const [reqId, setReqId] = useRecoilState(pipelineListRequestId);

  const [error] = useState();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletePipeline, setDelete] =
    useState<PipelineX | undefined>(undefined);

  const pipelines =
    pipelinesLoadable.state === "hasValue" ? pipelinesLoadable.contents : [];

  const isLoadingPipelineX = pipelinesLoadable.state === "loading";
  const hasPipelines =
    pipelinesLoadable.state === "hasValue" && pipelines.length === 0;
  const nxtal = createnXtalAPI();

  /**
   * Pipelineを新規作成する関数
   */
  const addPipelineX = useCallback(
    async (name: string) => {
      const item = createPipelineXInput({ name });
      try {
        await nxtal.pipelinex.add({
          contractorNo: auth.contractorNo ?? "",
          xervice: {
            name: item.name,
            readableGroups: item.readableGroups,
            writableGroups: item.writableGroups,
            readableUsers: item.readableUsers,
            writableUsers: item.writableUsers,
          },
        });

        await activityLogger.saveXerviceActivity(
          "info",
          `PipelineX:${item.name}を新規登録しました。`
        );

        //@todo update list
      } catch (err) {
        console.error(err);
        // setError(err);
      }
      setReqId(reqId + 1);
    },
    [auth.contractorNo, nxtal.pipelinex, reqId, setReqId]
  );

  /**
   * PipelineX新規作成モーダルを開く関数
   */
  const handleCreateModalOpen = () => {
    setShowCreateModal(true);
  };

  /**
   * PipelineX新規作成モーダルを閉じる関数
   */
  const handleCreateModalClose = () => {
    setShowCreateModal(false);
  };

  /**
   * PipelineX削除モーダルを開く関数
   */
  const handleDeleteModalOpen = useCallback((pipeline: PipelineX) => {
    setDelete(pipeline);
    setShowDeleteModal(true);
  }, []);

  /**
   * PiplineX削除モーダルを閉じる関数
   */
  const handleDeleteModalClose = () => {
    setDelete(undefined);
    setShowDeleteModal(false);
  };

  // パイプラインの新規作成
  const handleCreatePipelineX = (formdata: { pipelineName: string }) => {
    addPipelineX(formdata.pipelineName);
    handleCreateModalClose();
  };

  // パイプラインの削除
  const handleDeletePipelineX = useCallback(async () => {
    if (deletePipeline && deletePipeline.id) {
      try {
        const contractor: string =
          typeof auth.contractorNo === "string" ? auth.contractorNo : "";

        const hasDeleted = await nxtal.pipelinex.deleteOne(
          contractor,
          deletePipeline.id
        );

        if (hasDeleted) {
          await activityLogger.saveXerviceActivity(
            "info",
            `PipelineX:${deletePipeline.name}を削除しました。`,
            deletePipeline.id
          );
          setReqId(reqId + 1);
        }
      } catch (err) {
        console.error(err);
        // setError(err);
      }
    }
    handleDeleteModalClose();
  }, [auth.contractorNo, deletePipeline, nxtal.pipelinex, reqId, setReqId]);

  /**
   * PipelineXの検索ワードをセットする関数
   */
  const handleSubmitSearchPipelineWord = (newText: string) => {
    setSearchWord(newText);
  };

  return {
    pipelines,
    showCreateModal,
    showDeleteModal,
    searchWord,
    isLoadingPipelineX,
    hasPipelines,
    handleCreatePipelineX,
    handleDeletePipelineX,
    handleCreateModalClose,
    handleCreateModalOpen,
    handleDeleteModalClose,
    handleDeleteModalOpen,
    handleSubmitSearchPipelineWord,
    error,
  };
};
