import React from "react";

import { OEOptEditorPageTemplate } from "src/opt-editor/components/templates/OEOptEditorPageTemplate";
import { NAGlobalNav } from "../organisms";

type Props = {};

export const NAKnowledgePage: React.FC<Props> = (props) => {
  return (
    <div>
      <NAGlobalNav />
      <OEOptEditorPageTemplate />
    </div>
  );
};
