import { AxiosInstance } from "axios";
import {
  Registry as IRegistry,
  NewtQuery,
  RegistryInput,
  createRegistryFromNewtData,
} from "../../models";

export const Registry = (instance: AxiosInstance) => {
  const basePath = "/newtzero";
  const add = async (registry: RegistryInput): Promise<IRegistry | null> => {
    const path = basePath + "/registry";

    try {
      const res = await instance.post(path, registry);
      if (res.status === 200) {
        return createRegistryFromNewtData(res.data.message);
      }
    } catch (err) {
      console.error(err);
    }

    return null;
  };

  const fetchOne = async (registryId: string): Promise<IRegistry | null> => {
    const path = basePath + `/registry/${registryId}`;

    try {
      const res = await instance.get(path);
      if (res.status === 200) {
        return createRegistryFromNewtData(res.data.message);
      }
    } catch (err) {
      console.error(err);
    }

    return null;
  };

  const fetchMany = async (options?: {
    limit?: number;
    skip?: number;
    count?: boolean;
    explain?: boolean;
  }): Promise<[IRegistry[], number]> => {
    const path = basePath + "/registry";

    try {
      const res = await instance.get(path, { params: options });
      if (res.status === 200) {
        return [
          res.data.message.map((data: any) => createRegistryFromNewtData(data)),
          res.data.totalDataCount,
        ];
      }
    } catch (err) {
      console.error(err);
    }

    return [[], 0];
  };

  /**
   * Registryの一覧を取得する関数
   * DataProviderによってNewtQueryをリクエストしてデータを取得する
   * @returns Registryの一覧とデータ総件数を返却する
   */
  const dataProvider = async (
    newtQuery: NewtQuery,
    options?: { count?: boolean; explain?: boolean }
  ): Promise<[IRegistry[], number]> => {
    const path = basePath + "/registry/dataprovider";

    try {
      const res = await instance.post(path, newtQuery, { params: options });
      if (res.status === 200) {
        return [
          res.data.message.map((data: any) => createRegistryFromNewtData(data)),
          res.data.totalDataCount ?? 0,
        ];
      }
    } catch (err) {
      console.error(err);
    }

    return [[] as IRegistry[], 0];
  };

  const updateOne = async (
    registryId: string,
    registry: RegistryInput
  ): Promise<boolean> => {
    const path = basePath + `/registry/${registryId}`;

    try {
      const res = await instance.put(path, registry);
      if (res.status === 200) {
        return true;
      }
    } catch (err) {
      console.error(err);
    }

    return false;
  };

  const deleteOne = async (registryId: string): Promise<boolean> => {
    const path = basePath + `/registry/${registryId}`;

    try {
      const res = await instance.delete(path);
      if (res.status === 200) {
        return true;
      }
    } catch (err) {
      console.error(err);
    }

    return false;
  };

  return {
    add,
    fetchOne,
    fetchMany,
    dataProvider,
    updateOne,
    deleteOne,
  };
};
