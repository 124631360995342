import { MonitorThumb, createMonitorThumb } from "src/kinzaza/models";
import { postMonitor } from "./monitorList";
import { allegroConsts } from "allegro-models";
import { createNewtZeroAPI } from "src/api";

export const saveMonitor = async (
  nextMonitor: MonitorThumb
): Promise<MonitorThumb | null> => {
  if (!nextMonitor.id) {
    console.error("no monitor id");
    return null;
  }

  try {
    await postMonitor(nextMonitor);
    const newMonitor = await fetchSingleMonitor(nextMonitor.id);
    return newMonitor;
  } catch (err) {
    console.error(err);
  }
  return null;
};

export const fetchSingleMonitor = async (
  id: string
): Promise<MonitorThumb | null> => {
  try {
    const newtzero = createNewtZeroAPI();
    const data = await newtzero.dataProvider({
      assetId: allegroConsts.assetIds.monitor,
      order: { _created: -1 },
      search: {
        id: id,
      },
      limit: 1,
      shape: {
        id: "any",
        _created: "any",
        "contents.rawdata": "any",
      },
    });
    //TODO エラーハンドル

    return createMonitorThumb({
      id: data[0]?.id,
      ...data[0]?.contents.rawdata,
    });
  } catch (err) {
    console.error(err);
    return null;
  }
};
