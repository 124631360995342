import React from "react";
import ReactFlow, {
  Background,
  BackgroundVariant,
  MiniMap,
} from "react-flow-renderer";
import styled from "styled-components";
import AddIcon from "@material-ui/icons/Add";
import { XRCreateSlotModal } from "../organisms";
import { CustomNode } from "../atoms";
import { ASIconButton, ASModal } from "allegro-ui";
import { XRHttpEdge } from "../atoms/XRHttpEdge";
import { LoadingBox } from "src/opt-editor/components/atoms/OELoddingBox";
import { transparentize } from "polished";
import { useGridEditor } from "src/xerver-room/hooks/useGridEditor";

export const gridSize = 24;
export const gridOffset = 4;

const edgeTypes = {
  httpedge: XRHttpEdge,
};

const Frame = styled.div`
  position: relative;
  background: ${(props) => props.theme.bgPrimaryColor};
  /* border: 1px solid #808080; */
  /* border-top: 1px solid #808080; */
  border-bottom: 1px solid #1d1d1d;
  /* border-radius: 8px; */
  height: 100%;
  width: 100%;
`;

const CircleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
`;

const StyledMiniMap = styled(MiniMap)`
  border-radius: 8px;
  left: 24px;
  background: ${(props) => transparentize(0.7, props.theme.bgSecondaryColor)};
  border: 1px solid ${(props) => props.theme.secondaryBorderColor};
`;

const RightBottomContent = styled.div`
  position: absolute;
  bottom: 24px;
  right: 24px;
  z-index: 999;
`;

const PipelineAddButton = styled(ASIconButton)`
  border-radius: 100px;
`;
type Props = {};

export const XRGridEditorViewLoading: React.FC<Props> = (props) => {
  return (
    <>
      <Frame>
        <div style={{ height: "calc(100vh - 120px)" }}>
          <CircleWrapper>
            <LoadingBox />
          </CircleWrapper>
        </div>
      </Frame>
    </>
  );
};

export const XRGridEditorView: React.FC<Props> = (props) => {
  const {
    nodes,
    edges,
    nodeTypes,
    needFit,
    viewPort,
    canEditPipeline,
    showCreateModal,
    measuredRef,
    setCursorSlotId,
    setCursorSlotType,
    setCursorLinkObjectId,
    handleCreateModalOpen,
    handleCreateModalClose,
    handleCreate,
    onConnect,
    onNodesChange,
    onNodeDragStart,
    onNodeDragStop,
    onViewPortChange,
  } = useGridEditor();

  return (
    <>
      <Frame>
        <ReactFlow
          ref={measuredRef}
          nodes={[...nodes]}
          nodeTypes={nodeTypes}
          snapToGrid={true}
          snapGrid={[gridSize, gridSize]}
          onNodeClick={(_: any, n: CustomNode) => {
            switch (n.type) {
              case "slot":
                setCursorSlotType(n.type);
                setCursorSlotId(n.id);
                break;
              case "linkObject":
                setCursorSlotType(n.type);
                setCursorLinkObjectId(n.id);
                break;
              default:
                console.error("invalid node is clicked.");
            }
          }}
          minZoom={0.08}
          maxZoom={1.1}
          defaultZoom={viewPort?.zoom}
          defaultPosition={[viewPort?.x ?? 0, viewPort?.y ?? 0]}
          fitView={needFit}
          elementsSelectable={true}
          onNodeDragStart={onNodeDragStart}
          onNodeDragStop={onNodeDragStop}
          edges={edges}
          edgeTypes={edgeTypes}
          onNodesChange={onNodesChange}
          onConnect={onConnect}
          onMove={(e, viewPort) => {
            onViewPortChange(viewPort);
          }}
        >
          <StyledMiniMap
            nodeColor={"#505050"}
            nodeStrokeWidth={3}
            maskColor={"rgb(0, 0, 0, 0.3)"}
          />
          <Background
            variant={"dots" as BackgroundVariant}
            gap={gridSize}
            size={1}
            color={"#45464e"}
          />
        </ReactFlow>
        <RightBottomContent>
          {canEditPipeline ? (
            <PipelineAddButton kind={"primary"} onClick={handleCreateModalOpen}>
              <AddIcon />
            </PipelineAddButton>
          ) : null}
        </RightBottomContent>
      </Frame>
      <ASModal
        open={showCreateModal}
        onClose={() => {
          handleCreateModalClose();
        }}
      >
        <XRCreateSlotModal onSubmit={handleCreate}></XRCreateSlotModal>
      </ASModal>
    </>
  );
};
