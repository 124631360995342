import { ReactNode } from "react";
import styled from "styled-components";

const Block = styled.div`
  margin-top: 24px;
  padding-top: 12px;
  border-top: 1px solid #333;

  > p {
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;

const BlockHeader = styled.div`
  margin-bottom: 16px;
`;
const BlockBody = styled.div`
  color: ${(props) => props.theme.textColor};
  font-size: ${(props) => props.theme.regularFontSizeMd}px;
`;

const Description = styled.div`
  color: ${(props) => props.theme.descriptionTextColor};
  font-size: ${(props) => props.theme.labelFontSizeMd}px;
`;

type XROptXBlockProps = {
  title?: ReactNode;
  description?: string;
};

export const XROptXBlock: React.FC<XROptXBlockProps> = (props) => {
  return (
    <Block>
      <BlockHeader>
        <h1>{props.title}</h1>
        {props.description ? (
          <Description>{props.description}</Description>
        ) : null}
      </BlockHeader>

      <BlockBody> {props.children}</BlockBody>
    </Block>
  );
};
