import React from "react";
import { ASMarkdown, ASPictureIcon } from "allegro-ui";
import styled from "styled-components";
import { OptXV2 } from "allegro-api";
import { XROptXV2UpdateForm } from "./XROptXV2UpdateForm";
import {
  CHTabComponentProps,
  CHTabs,
} from "src/cheetah/components/molecules/CHTabs";
import { XROptXV2VersionList } from "./XROptXV2VersionList";
import { XRPageTemplate } from "../templates/XRPageTemplate";
import { XROptXBlock } from "../molecules/XROptXBlock";
import { ASLowCodeDataBrowserContainer } from "src/cheetah/components/libs/ASLowCodeBrowser";
import { createOptXBuildHistoryBrowserUi } from "src/xerver-room/config/ui/optXBuildHistoryBrowserUi";

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin-right: 12px;
  }
`;

const Block = styled.div`
  margin-top: 24px;
  padding-top: 12px;
`;

type OptXInfoProps = {
  optxv2: OptXV2;
};

// OptX詳細のInfoタブ
const OptXInfoBlock: React.FC<OptXInfoProps> = (props) => {
  const { optxv2 } = props;

  return (
    <Block>
      <XROptXBlock title="ID">{optxv2.optXId}</XROptXBlock>
      <XROptXBlock title="Description">
        <ASMarkdown>{optxv2.description ?? "---"}</ASMarkdown>
      </XROptXBlock>
      <XROptXBlock title="Docker Repository Name">
        {optxv2.dockerRepositoryName}
      </XROptXBlock>
    </Block>
  );
};

// OptX詳細のBuildHistoryタブ
const OptXBuildHistoryBlock: React.FC<OptXInfoProps> = (props) => {
  const { optxv2 } = props;

  return (
    <div>
      <XROptXBlock title="Build history">
        <ASLowCodeDataBrowserContainer
          name={`xr-optx-build-history-${optxv2.optXId}`}
          uiConf={createOptXBuildHistoryBrowserUi(optxv2.optXId)}
        />
      </XROptXBlock>
    </div>
  );
};

type XROptXV2DetailsProps = {
  optXV2: OptXV2;
};

export const XROptXV2Details: React.FC<XROptXV2DetailsProps> = ({ optXV2 }) => {
  const tabs: CHTabComponentProps[] = [
    {
      label: "Info",
      component: <OptXInfoBlock optxv2={optXV2} />,
    },
    {
      label: "Version",
      component: <XROptXV2VersionList optxv2={optXV2} />,
    },
    {
      label: "Build History",
      component: <OptXBuildHistoryBlock optxv2={optXV2} />,
    },
    {
      label: "Setting",
      component: <XROptXV2UpdateForm optxv2={optXV2} />,
    },
  ];

  return (
    <XRPageTemplate
      title={
        <Header>
          <ASPictureIcon
            size={50}
            src={optXV2.thumbnail}
            defaultSrc={optXV2.thumbnail}
          />
          <h1>{optXV2.name}</h1>
        </Header>
      }
    >
      <CHTabs key={`xr-optx-tabs-${optXV2.optXId}`} tabs={tabs} />
    </XRPageTemplate>
  );
};
