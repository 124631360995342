import React from "react";
import styled from "styled-components";
import {
  ASLowCodeFormField,
  ASLowCodeFormDefinition,
} from "../../models/ASLowCodeFormField";
import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";

import { ASButton } from "allegro-ui";
import {
  ASTextFormField,
  ASSelectionFormField,
  ASCheckBoxFormField,
  ASChipsFormField,
  ASRadioFormField,
  ASDatePickerFormField,
  ASSectionFormField,
  ASMultilineTextFormField,
  ASNumberFormField,
} from "../molecules";
import { ASSecretFormField } from "../molecules/ASSecretFormField";
import { LowCodeFormOptions, LowCodeFormData } from "../../../models/FormData";
import { SubmitCallback, ValidateCallback } from "../../../models/callback";
import { useSnackbar } from "notistack";
import axios from "axios";
import { useLowCodeForm } from "../../../hooks/useLowCodeForm";

export interface ASLowCodeFormContainerProps {
  key?: string | number;
  kind?: "digicho" | "secondary";
  formDefinition: ASLowCodeFormDefinition;
  fullWidth?: boolean;
  buttonsOptions?: any;
  isUpdate?: boolean;
  isApproval?: boolean;
  defaultValues?: { [key: string]: any };
  editable?: boolean;
  columns?: number;
  requiredMarker?: string;
  optionalMarker?: string;
  disabledSnackbar?: boolean;
  onClose?: () => void;
  onValid?: ValidateCallback;
  onSubmit?: SubmitCallback;
}

const marginBase = 8;

const Container = styled.div`
  height: 100%;
`;

const FormContainer = styled.div<{ fullWidth?: boolean }>`
  /* border: 1px solid ${(props) => props.theme.secondaryBorderColor}; */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.fullWidth ? "100%" : "80%")};
  height: 100%;
  max-width: ${(props) => (props.fullWidth ? "100%" : "700px")};
  overflow-y: scroll;
  overflow-x: hidden;
  /* padding: 24px; */
`;

const NegativeMessage = styled.span`
  color: ${(props) => props.theme.negativeColor};
`;

const FieldContainer = styled.div`
  /* > * {
    margin-top: ${marginBase * 4}px;
  } */
`;

const SectionTitle = styled.div`
  font-size: 24px;
  color: ${(props) => props.theme.textColor};
  margin-bottom: ${marginBase * 2}px;
  padding: 0 2px;
`;

const SectionDescription = styled.div`
  padding: 0 2px;
  font-size: 14px;
  color: ${(props) => props.theme.descriptionTextColor};
  margin-bottom: ${marginBase * 2}px;
`;

const SubsectionContainer = styled.div`
  margin-top: ${marginBase * 2}px;
  margin-bottom: ${marginBase * 1}px;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;

  padding-top: 8px;
  margin-top: auto;
  > *:last-child {
    margin-left: auto;
  }
  float: right;
`;

const FooterRightBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin-left: ${marginBase * 2}px;
  }
`;

const StyledGridItem = styled(Grid)`
  /* margin-bottom: 12px; */
`;

const Indent = styled.div`
  padding: 0 2px;
  /* margin-top: 20px; */
  margin-bottom: ${marginBase * 2}px;
`;

const formFieldComponents: { [key: string]: any } = {
  text: ASTextFormField,
  selection: ASSelectionFormField,
  checkbox: ASCheckBoxFormField,
  radio: ASRadioFormField,
  secret: ASSecretFormField,
  chip: ASChipsFormField,
  date: ASDatePickerFormField,
  section: ASSectionFormField,
  multiline: ASMultilineTextFormField,
  number: ASNumberFormField,
};

export const ASLowCodeFormContainer: React.FunctionComponent<ASLowCodeFormContainerProps> =
  (props) => {
    const {
      formDefinition,
      form,
      cellSizeX,
      gridSpacing,
      editable,
      values,
      validMessage,
      buttonText,
      cancelButtonText,
      lowCodeOptions,
      handleSubmitButton,
      handleChangeValue,
    } = useLowCodeForm(props);

    const { sections } = formDefinition;

    const {
      control,
      formState: { errors },
      handleSubmit,
    } = form;

    const renderFields = (
      fields: ASLowCodeFormField[],
      defaultValues?: { [key: string]: any }
    ) => {
      const key = props.key ?? "as-lc-form-container";
      return (
        <FieldContainer key={key}>
          <Grid container spacing={gridSpacing}>
            {fields.map((f, idx) => {
              switch (f.type) {
                // skeltonの場合、描画せずに値として保持しておく
                case "skelton":
                  const value = defaultValues
                    ? defaultValues[f.name]
                    : f.active;
                  handleChangeValue(f.name, value);
                  return null;
                // sectionの場合、cellSizeXに関わらず必ず1行使って描画するようにする
                case "section":
                  return (
                    <StyledGridItem
                      key={`as-lowcode-form-section-${f.label}`}
                      item
                      xs={12}
                    >
                      <SubsectionContainer>
                        <ASSectionFormField
                          key={`as-form-field-${key}-${idx}`}
                          title={f.label}
                        />
                      </SubsectionContainer>
                    </StyledGridItem>
                  );
                default:
                  const Component = formFieldComponents[f.type];

                  return (
                    <StyledGridItem
                      key={`as-form-field-grid-${key}-${idx}`}
                      item
                      xs={cellSizeX}
                    >
                      <Indent>
                        {f.type in formFieldComponents ? (
                          <Component
                            key={`as-form-field-${key}-${idx}`}
                            design={props.kind ?? "digicho"}
                            control={control}
                            id={`${key}-${idx}`}
                            defaultValue={
                              defaultValues ? defaultValues[f.name] : undefined
                            }
                            requiredMarker={props.requiredMarker}
                            optionalMarker={props.optionalMarker}
                            editable={editable}
                            error={errors[f.name]}
                            active={
                              defaultValues ? defaultValues[f.name] : undefined
                            }
                            {...f}
                          />
                        ) : (
                          <div key={`lc-create-data-form-${key}-${idx}`}>
                            {f.name}
                          </div>
                        )}
                      </Indent>
                    </StyledGridItem>
                  );
              }
            })}
          </Grid>
        </FieldContainer>
      );
    };

    const renderButtons = () => {
      return (
        <FooterRightBox>
          {props.onClose ? (
            <ASButton kind="secondary" onClick={props.onClose}>
              {cancelButtonText}
            </ASButton>
          ) : null}

          <ASButton
            kind="primary"
            onClick={handleSubmit(
              async (values: any) =>
                await handleSubmitButton(values, lowCodeOptions)
            )}
          >
            {buttonText}
          </ASButton>
        </FooterRightBox>
      );
    };

    const renderSections = () => {
      const key = props.key ?? "as-lc-form-container";
      return sections.map((section, id) => (
        <FormContainer
          key={`section-lc-create-data-form-container-${key}-${id}`}
          fullWidth={props.fullWidth}
        >
          {section.title ? <SectionTitle>{section.title}</SectionTitle> : null}
          {section.description ? (
            <SectionDescription>{section.description}</SectionDescription>
          ) : null}

          <form
            onSubmit={handleSubmit((values: any) =>
              handleSubmitButton(values, lowCodeOptions)
            )}
          >
            {renderFields(section.fields, values)}
          </form>
          <Footer>
            <NegativeMessage>{validMessage}</NegativeMessage>
            {editable ? renderButtons() : null}
          </Footer>
        </FormContainer>
      ));
    };

    return <Container>{renderSections()}</Container>;
  };
