import { AtomEffect, DefaultValue } from "recoil";
import { api, createnXtalAPI } from "src/api";
import { createSlot, Slot, SlotStatus } from "../models";

export const guardRecoilDefaultValue = (
  candidate: any
): candidate is DefaultValue => {
  if (candidate instanceof DefaultValue) return true;
  return false;
};

const _fetchXervice = async (slotId: string): Promise<Slot | DefaultValue> => {
  const nxtal = createnXtalAPI();
  const res = await nxtal.xervice.getSlot(slotId);
  if (res) {
    return createSlot(res);
  } else {
    console.error("failed fetch a slot", slotId);
    return new DefaultValue();
  }
};

const _updateXerviceOfPart = (slotId: string, partOfSlot: Partial<Slot>) => {
  //TODO fetch previous before... if other person edit this already. avoid.
  api({
    method: "PUT",
    url: `/xervice/${slotId}`,
    params: {
      apikey: sessionStorage.token,
    },
    data: {
      ...partOfSlot,
    },
  });
};

export const syncDBEffect: (slotId: string) => AtomEffect<Slot> =
  (slotId) =>
  ({ setSelf, trigger, onSet, getPromise }) => {
    onSet((newSlot, prevSlot, isReset) => {
      let prevSlotAny: any = prevSlot;
      let newSlotAny: any = newSlot;
      let diffs: { [key: string]: any } = {};
      Object.entries(newSlot).forEach(([key, value]) => {
        if (
          (newSlotAny[key] || prevSlotAny[key]) &&
          prevSlotAny[key] !== value
        ) {
          diffs[key] = value;
        }
      });
      if (newSlot) _updateXerviceOfPart(newSlot.id, diffs);
    });

    if (trigger === "get") {
      if (!slotId || slotId === "") {
        console.warn("WARNING: fetchXervice is called without slotId");
        return;
      }
      // getPromise(webSocketState).then((socket) => {
      //   if (socket) socket.emit("join", slotId);
      // });
    }

    if (trigger === "get") {
      setSelf(_fetchXervice(slotId));
      return;
    }
  };

/**
 *
 * @param slotId
 * @returns
 */
export const statusEffectByRest: (slotId: string) => AtomEffect<SlotStatus> =
  (slotId) =>
  ({ setSelf, trigger, getPromise }) => {
    const updateStatus = (slotId: string) => {
      if (!slotId || slotId === "") {
        console.warn("WARNING: fetchXervice is called without slotId");
        return;
      }
      _fetchXervice(slotId).then((slot) => {
        if (!guardRecoilDefaultValue(slot)) {
          switch (slot.status.current) {
            case "run":
              setSelf((prevSelf) => {
                return "running";
              });
              break;
            case "stop":
              setSelf((prevSelf) => {
                return "stopped";
              });
              break;
            default:
              setSelf("init");
          }
        }
      });
    };

    const loop = () => {
      updateStatus(slotId);
      setTimeout(() => {
        loop();
      }, 5000);
    };

    loop();
  };
/**
 * Slotが更新されたら
 */
export const syncSlotEffects: (slotId: string) => AtomEffect<Slot>[] = (
  slotId
) => [
  syncDBEffect(slotId),
  // statusEffect(slotId),
];
