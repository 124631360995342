import {
  selector,
  atom,
  atomFamily,
  DefaultValue,
  selectorFamily,
} from "recoil";
import { createnXtalAPI } from "src/api";
import { createOptX, OptX } from "../models";
import { userState } from "src/root/recoils/userState";
import { OptXConf, OptXSchemaType, OptXV2, OptXVariation } from "allegro-api";
import { cursorSlotState } from "./slotState";
import { fetchOptXVariationsByVersion } from "../api/fetchOptXVariations";
import { XFile } from "allegro-api/lib/models/XFile";

export const optxFetchCountOfARequest = 40;

export const cursorOptXIdState = atom<string | undefined>({
  key: "CursorOptXId",
  default: undefined,
});

export const optXSearchWordState = atom<string | undefined>({
  key: "optXSearchWordState",
  default: "",
});

export const optXListPageState = atom<number>({
  key: "optXListPageState",
  default: 1,
});

export const optXState = atomFamily<OptX | null, string>({
  key: "optXState",
  default: null,
});

export const optXV2State = atomFamily<OptXV2 | null, string>({
  key: "optXV2State",
  default: null,
});

export const optXCountState = atom<number>({
  key: "OptXCountState",
  default: 0,
});

export const optXIdListState = atom<string[]>({
  key: "OptXIdListState",
  default: [],
});

export const requestOptXListIdState = atom<number>({
  key: "requestOptXListIdState",
  default: 0,
});

export const optXSchemaTypeState = atom<OptXSchemaType>({
  key: "optXSchemaTypeState",
  default: OptXSchemaType.optxv2,
});

export const optXListFetcherState = selector<[Array<OptX | OptXV2>, number]>({
  key: "OptXListState",
  get: async ({ get }) => {
    const auth = get(userState);
    const searchWord = get(optXSearchWordState);
    const page = get(optXListPageState);
    const schemaType = get(optXSchemaTypeState);
    get(requestOptXListIdState);
    const contractor: string =
      typeof auth.contractorNo === "string" ? auth.contractorNo : "";

    const searchWordQuery = { $regex: searchWord, $options: "i" };
    const limit = optxFetchCountOfARequest;
    const skip = optxFetchCountOfARequest * (page - 1);

    const nxtal = createnXtalAPI();

    switch (schemaType) {
      case "optxv1":
        const query = {
          where: {
            name: searchWordQuery,
          },
          limit: limit,
          skip: skip,
        };
        const res = await nxtal.optx.getMany(contractor, query, {
          count: true,
        });

        const count = Number(res?.headers["x-total-count"]) ?? 0;

        const nextList: OptX[] =
          res?.data.map((data: any) => {
            return createOptX(data);
          }) ?? [];
        return [nextList, count];
      case "optxv2":
        const newtQuery = {
          shape: {
            bases: "any",
            contents: "any",
          },
          limit: limit,
          skip: skip,
          search: {
            "contents.rawdata.name": searchWordQuery,
          },
          order: {
            "bases.created": -1,
          },
        };

        const [optXV2List, dataCount] = await nxtal.optxv2.dataProvider(
          newtQuery,
          {
            count: true,
          }
        );

        return [optXV2List, dataCount];
    }

    return [[], 0];
  },
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      // キャッシュクリア用のお約束
      return newValue;
    } else {
      // atomにキャッシュとして保存する
      const [newOptXList, count] = newValue;
      for (const value of newOptXList) {
        switch (value.schemaType) {
          case OptXSchemaType.optxv1:
            const id = value.optXId;
            set(optXState(id), value);
            break;
          case OptXSchemaType.optxv2:
            const idv2 = value.optXId;
            set(optXV2State(idv2), value);
        }
      }

      const idList = newOptXList.map((value) => {
        return value.optXId;
      });

      set(optXCountState, count);
      set(optXIdListState, idList);
    }
  },
});

export const optXSingleFetcherState = selectorFamily<
  OptX | OptXV2 | null,
  string
>({
  key: "optXSingleFetcherState",
  get:
    (optXId) =>
    async ({ get }) => {
      if (!optXId) {
        return null;
      }

      const nxtal = createnXtalAPI();
      try {
        const optv2 = await nxtal.optxv2.fetchOne(optXId).catch((err) => null);
        if (optv2) {
          return optv2;
        }

        const resOptv1 = await nxtal.optx
          .getOne(sessionStorage.contractorNo, optXId)
          .catch((err) => null);
        const optv1 = resOptv1?.data ?? null;

        if (optv1) {
          return optv1;
        }
      } catch (err) {
        console.error(err);
      }
      return null;
    },
});

export const optXListSelector = selector<Array<OptX>>({
  key: "optXListSelector",
  get: ({ get }) => {
    const optXIdList = get(optXIdListState);
    return optXIdList
      .map((optXId) => {
        return get(optXState(optXId));
      })
      .filter((optx) => optx) as OptX[];
  },
});

export const optXV2ListSelector = selector<Array<OptXV2>>({
  key: "optXV2ListSelector",
  get: ({ get }) => {
    const optXIdList = get(optXIdListState);
    return optXIdList
      .map((optXId) => {
        const optx = get(optXV2State(optXId));
        return optx;
      })
      .filter((optx) => optx) as OptXV2[];
  },
});

/**
 * cursorSlotのoptXVariationをフェッチしキャッシュするためのselector
 * key: variationId
 */
export const cursorSlotOptXV2VariationFetcherState = selectorFamily<
  OptXVariation | null,
  string
>({
  key: "cursorOptXV2VariationState",
  get:
    (key) =>
    async ({ get }) => {
      const cursorSlot = get(cursorSlotState);
      const optXId = cursorSlot.optXId;
      const variationId = cursorSlot.variationId;

      if (!optXId || !variationId) {
        return null;
      }

      try {
        const variations = await fetchOptXVariationsByVersion(optXId, {
          variationId: variationId,
        });
        return variations[0] ?? null;
      } catch (err) {
        console.error(err);
      }
      return null;
    },
});

export const optXV2VariationListState = atomFamily<
  Array<OptXVariation>,
  string
>({
  key: "optXV2VariationListState",
  default: [],
});

export const optXVariationState = atomFamily<OptXVariation | null, string>({
  key: "optXVariationState",
  default: null,
});

export const xfileListState = atomFamily<XFile[], string>({
  key: "xFileListState",
  default: [],
});

/**
 * optXConfをXFIle APIによりフェッチしてキャッシュするためのselector
 * key: optXId/variationId ex. opt_AAABBB/optver_CCCDDD
 */
export const optXConfFetcherState = selectorFamily<OptXConf | null, string>({
  key: "optXConfFetcherState",
  get:
    (key) =>
    async ({ get }) => {
      const [optXId, variationId] = key.split("/");

      if (!optXId || !variationId) {
        return null;
      }

      const nxtal = createnXtalAPI();

      try {
        const optXConf = await nxtal.xfile.fetchOptXConf(optXId, variationId);
        return optXConf;
      } catch (err) {
        return null;
      }
    },
});
