import { createNewtZeroAPI } from "src/api";
import { ASBrowserUiConf } from "src/cheetah/components/libs/ASLowCodeBrowser";

export const serverPipelineBroweserUi: ASBrowserUiConf = {
  newtQ: async (limit: number, skip: number, options) => {
    const newtzero = createNewtZeroAPI();

    const res = await newtzero.server.dataProvider({
      match: options?.match,
      search: options?.search,
      order: {
        "bases.created": -1,
      },
      limit,
      skip,
      shape: {
        "contents.rawdata": "any",
      },
    });
    return res;
  },
  filters: [],
  verticalFilters: [
    {
      label: "Search",
      design: "search",
      selections: [],
      callback: (value: string) => {
        return {
          $or: [
            {
              "contents.rawdata.non_numeric_server_name": {
                $regex: value,
                $options: "i",
              },
            },
            {
              "contents.rawdata.non_numeric_ip1": {
                $regex: value,
                $options: "i",
              },
            },
            {
              "contents.rawdata.non_numeric_ip2": {
                $regex: value,
                $options: "i",
              },
            },
            {
              "contents.rawdata.non_numeric_ip3": {
                $regex: value,
                $options: "i",
              },
            },
          ],
        };
      },
    },
  ],
  tables: [
    {
      label: "Server name",
      component: "LCSimpleColComponent",
      data: {
        value: "contents.rawdata.non_numeric_server_name",
      },
      options: {
        dateFormat: "wareki",
        editable: false,
      },
    },

    {
      label: "IP1",
      component: "LCSimpleColComponent",
      data: {
        value: "contents.rawdata.non_numeric_ip1",
      },
      options: {
        dateFormat: "wareki",
        editable: false,
      },
    },
    {
      label: "IP2",
      component: "LCSimpleColComponent",
      data: {
        value: "contents.rawdata.non_numeric_ip2",
      },
      options: {
        dateFormat: "wareki",
        editable: false,
      },
    },
    {
      label: "IP3",
      component: "LCSimpleColComponent",
      data: {
        value: "contents.rawdata.non_numeric_ip3",
      },
      options: {
        dateFormat: "wareki",
        editable: false,
      },
    },

    {
      label: "Tags",
      component: "LCSimpleColComponent",
      data: {
        value: "contents.rawdata.non_numeric_tags",
      },
      options: {
        dateFormat: "wareki",
        editable: false,
      },
    },
  ],
  pager: { perPage: 50, position: "static" },
};
