import React, { useState, Suspense, useEffect } from "react";
import styled from "styled-components";
import { ASButton, ASModal } from "allegro-ui";

//
//components
//
import { XROptXBrowser } from "..";
import { LoadingBox } from "src/opt-editor/components/atoms/OELoddingBox";
import { XRPageTemplate } from "./XRPageTemplate";
import { useOptXV2Actions } from "src/xerver-room/hooks/useOptXV2";
import { OptXSchemaType, OptXV2Input } from "allegro-api";
import { XROptXV2Browser } from "../organisms/XROptXV2Browser";
import { useOptXActions } from "src/xerver-room/hooks";
import { ASLowCodeFormContainer } from "allegro-low-code-components";
import { createOptXFormDeinition } from "src/xerver-room/config/formDefinition/createOptXFormDefinition";
import { createOptXV2FormDeinition } from "src/xerver-room/config/formDefinition";
import { XROptXVersionSelection } from "../atoms/XROptXVersionSelect";
import { useMyRole } from "src/portal/hooks/useMyRole";
import { validateUri } from "src/xerver-room/utils/validateUri";

const Root = styled.div`
  background: ${(props) => props.theme.bgPrimaryColor};
  min-height: 100vh;
  width: 100%;
  color: ${(props) => (props.theme.darkMode ? "#eee" : "#000")};
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin-left: 8px;
  }
`;

const ButtonGroup = styled.div`
  margin-left: auto;
  > * {
    margin-left: 8px;
  }
`;

const ModalContainer = styled.div`
  width: 70vw;
  height: 70vh;

  padding: 25px;
  overflow-y: scroll;
`;

type Props = {
  schemaVersion?: OptXSchemaType;
};

export const XROptXBrowsePageTemplate: React.FC<Props> = (props) => {
  const [showCreate, setShowCreate] = useState(false);

  const { canCreateOptX } = useMyRole();
  const optxV2Actions = useOptXV2Actions();
  const optxV1Actions = useOptXActions();

  useEffect(() => {
    if (props.schemaVersion) {
      optxV2Actions.setCurrentOptXSchemaType(props.schemaVersion);
    }
  }, [optxV2Actions, props.schemaVersion]);

  return (
    <Root>
      <XRPageTemplate
        title={
          <Title>
            OptXStore
            <XROptXVersionSelection />
          </Title>
        }
        headerComponents={
          <>
            <ButtonGroup>
              <ASButton
                kind="primary"
                disabled={
                  optxV2Actions.currentOptXSchemaType ===
                    OptXSchemaType.optxv2 && !canCreateOptX
                }
                onClick={() => setShowCreate(true)}
              >
                New OptX
              </ASButton>
            </ButtonGroup>
          </>
        }
      >
        <Suspense fallback={<LoadingBox />}>
          {optxV2Actions.currentOptXSchemaType === OptXSchemaType.optxv2 ? (
            <>
              <XROptXV2Browser />
              <ASModal
                title="New OptX V2"
                open={showCreate}
                onClose={() => setShowCreate(false)}
              >
                <ModalContainer>
                  <ASLowCodeFormContainer
                    fullWidth
                    formDefinition={createOptXV2FormDeinition({
                      isUpdate: false,
                    })}
                    onValid={async (data) => {
                      const optXId = data.optXId;
                      if (optXId && validateUri(optXId)) {
                        return {
                          error: "error",
                          message:
                            "Reserved character should not be contained by OptX ID.",
                        };
                      }
                    }}
                    onSubmit={async (data) => {
                      const optXId =
                        data.optXId !== "" ? data.optXId : undefined;

                      const dockerRepositoryName =
                        data.dockerRepositoryName !== ""
                          ? data.dockerRepositoryName
                          : undefined;

                      const saveData = {
                        ...data,
                        optXId,
                        dockerRepositoryName,
                      } as OptXV2Input;
                      const [newOptX, errorMsg] = await optxV2Actions.add(
                        saveData
                      );
                      if (newOptX) {
                        setShowCreate(false);
                        window.location.reload();
                      } else {
                        const alertMessage =
                          errorMsg ?? "Failed to create OptX";

                        throw Error(alertMessage);
                      }
                    }}
                    editable
                  />
                </ModalContainer>
              </ASModal>
            </>
          ) : (
            <>
              <XROptXBrowser />
              <ASModal
                title="New OptX"
                open={showCreate}
                onClose={() => setShowCreate(false)}
              >
                <ModalContainer>
                  <ASLowCodeFormContainer
                    fullWidth
                    formDefinition={createOptXFormDeinition}
                    onSubmit={async (data) => {
                      optxV1Actions.add(data);
                    }}
                  />
                </ModalContainer>
              </ASModal>
            </>
          )}
        </Suspense>
      </XRPageTemplate>
    </Root>
  );
};
