import { ASBrowserUiConf } from "src/cheetah/components/libs/ASLowCodeBrowser";
import { fetchGroupMemberByGroupId } from "src/portal/api/fetchGroupMember";

type Props = {
  contractorNo: string;
  groupId: string;
  onDelete: (value: any) => Promise<void>;
  canDelete?: boolean;
};

export const createGroupMembersBrowserUi = (props: Props): ASBrowserUiConf => {
  return {
    newtQ: async (limit: number, skip: number, options) => {
      return await fetchGroupMemberByGroupId(
        props.contractorNo,
        props.groupId,
        { limit, skip, searchQ: options?.search }
      );
    },
    verticalFilters: [
      {
        label: "Search",
        design: "search",
        selections: [],
        callback: (value: string) => {
          return {
            email: { $regex: value.replace("+", "\\+") },
          };
        },
      },
    ],
    tables: [
      {
        label: "",
        component: "LCUserIconComponent",
        data: {
          userNo: "userNo",
        },
        options: {
          dateFormat: "wareki",
          editable: false,
        },
      },
      {
        label: "Name",
        component: "LCSimpleColComponent",
        data: {
          value: "profile.userRealName",
        },
        options: {},
      },
      {
        label: "Email",
        component: "LCSimpleColComponent",
        data: {
          value: "email",
        },
        options: {},
      },
      {
        label: "",
        component: "LCDeleteButtonComponent",
        data: (data) => {
          return {
            value: data,
          };
        },
        options: {
          dateFormat: "wareki",
          disabled: !props.canDelete,
          editable: false,
          onSubmit: props.onDelete,
        },
      },
    ],
  };
};
