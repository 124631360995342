import { selectorFamily, waitForAll } from "recoil";
import {
  userListPageIndexState,
  userNameSearchWordState,
} from "../atoms/filterState";

import {
  matchFilterState,
  extendMatchFilterState,
  searchFilterState,
  sortFilterState,
  newtQueryBase,
  lowCodeVerticalFilterState,
} from "../atoms/filterState";
import { createNewtZeroAPI } from "src/api";

const isValidQuery = (query: any): boolean => {
  if (query === null || query === undefined) {
    return false;
  }

  if (typeof query !== "object") {
    return false;
  }

  if (Array.isArray(query)) {
    return query.length > 0;
  }

  return Object.keys(query).length > 0;
};

export const newtDataSelectorState = selectorFamily<any, string>({
  key: "low-code-newtdata-selector",
  get:
    (key) =>
    async ({ get }) => {
      //matchfilterにサーチフィルターを混ぜる。
      const [pageIndex, matchFilter, searchFilter, extendMatchFilter] = get(
        waitForAll([
          userListPageIndexState(key),
          matchFilterState(key),
          searchFilterState(key),
          extendMatchFilterState(key),
        ])
      );

      const lcVerticalFilter = get(lowCodeVerticalFilterState(key));
      const sortQuery = get(sortFilterState(key));
      const ui = get(newtQueryBase(key));

      if (!ui) {
        return [];
      }

      const newtQMatchQuery =
        typeof ui.newtQ === "function" ? undefined : ui.newtQ?.match;
      const newtQSearchQuery =
        typeof ui.newtQ === "function" ? undefined : ui.newtQ?.search;

      // build matchQuery
      const matchPipelines = [
        matchFilter,
        lcVerticalFilter,
        newtQMatchQuery,
        extendMatchFilter,
      ].filter((query) => {
        return isValidQuery(query);
      });

      const matchQuery =
        matchPipelines.length > 0
          ? {
              $and: matchPipelines,
            }
          : {};

      // build searchQuery
      const searchPipelines = [searchFilter, newtQSearchQuery].filter(
        (query) => {
          return isValidQuery(query);
        }
      );
      const searchQuery =
        searchPipelines.length > 0
          ? {
              $and: searchPipelines,
            }
          : {};

      const limit = ui?.pager?.perPage ?? 8;
      //pageIndexは1からなので-1する。
      const skip = (pageIndex - 1) * limit;

      if (typeof ui.newtQ === "function") {
        try {
          const searchNameText = get(userNameSearchWordState(key));
          const res = await ui.newtQ(limit, skip, {
            name: searchNameText ?? undefined,
            order: sortQuery,
            search: searchQuery,
            match: matchQuery,
          });
          return res;
        } catch (err) {}
      } else {
        const newtQuery = {
          ...ui?.newtQ,
          search: searchQuery,
          match: matchQuery,
          order: sortQuery,
          limit,
          skip,
        };
        try {
          // const [resNewtData, _c] = await api.newtOut(q, { count: false });
          const newtzero = createNewtZeroAPI();
          const resNewtData = await newtzero.dataProvider(newtQuery, {
            count: false,
            filterByContractor: ui.filterByContractor,
          });
          return resNewtData;
        } catch (err) {
          return [];
        }
      }
      return [];
    },
});
