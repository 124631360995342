import React from "react";
import { OEActivityListPageTemplate } from "src/opt-editor/components/templates/OEActivitiyListPageTemplate";
import { NTMainViewTemplate } from "../templates/NTMainViewTemplate";
import { XRPageTemplate } from "src/xerver-room/components/templates/XRPageTemplate";

type Props = {};
export const NTActivityPage: React.FC<Props> = (props) => {
  return (
    <NTMainViewTemplate>
      <XRPageTemplate title="Activity">
        <OEActivityListPageTemplate />
      </XRPageTemplate>
    </NTMainViewTemplate>
  );
};
