import { CardContent } from "@material-ui/core";
import { ASCard } from "allegro-ui";
import styled from "styled-components";
import { XRTextArea } from "./XRTextArea";
import { XFile } from "allegro-api/lib/models/XFile";

const FileCard = styled(ASCard)`
  margin-top: 4px;
`;

const FileName = styled.h2`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 24px;
`;

const FileText = styled(XRTextArea)`
  font-size: 12px;
  opacity: 0.6;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;

type XRXFileCardProps = {
  xfile: XFile;
};

export const XRXFileCard: React.FC<XRXFileCardProps> = (props) => {
  const { xfile } = props;
  return (
    <FileCard key={`filecard-${xfile.name}`}>
      <CardContent>
        <FileName>{xfile.name}</FileName>
        <FileText>{xfile.text}</FileText>
      </CardContent>
    </FileCard>
  );
};
