import { CardContent } from "@material-ui/core";
import { ASCard, ASIconButton, ASModal } from "allegro-ui";
import styled from "styled-components";
import { XRTextArea } from "../atoms/XRTextArea";
import { XFile } from "allegro-api";
import EditIcon from "@material-ui/icons/Edit";
import { ASLowCodeFormContainer } from "allegro-low-code-components";
import { editXFileFormDefinition } from "src/xerver-room/config/formDefinition/editXFileFormDefinition";
import { useXFile } from "src/xerver-room/hooks/useXFile";

const FileCard = styled(ASCard)`
  margin-top: 4px;
`;

const FileHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 8px;

  > *:last-child {
    margin-left: auto;
  }
`;

const FileName = styled.h2`
  font-size: 14px;
  font-weight: bold;
`;

const EditButton = styled(ASIconButton)`
  width: 30px;
  height: 30px;
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
`;

const FileText = styled(XRTextArea)`
  color: ${(props) => props.theme.textColor};
  font-size: 12px;
  overflow-y: scroll;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;

const ModalContainer = styled.div`
  padding: 25px;
  width: 70vw;
  height: 70vh;
  overflow-y: scroll;
`;

type XROptXFileCardProps = {
  optXId: string;
  variationId: string;
  file: XFile;
  disabled?: boolean;
};

export const XRXFileV2Card: React.FC<XROptXFileCardProps> = (props) => {
  const { optXId, variationId, file, disabled } = props;

  const {
    cursorFile,
    showModal,
    handleSubmitXFile,
    handleOpenEditModal,
    handleCloseEditModal,
  } = useXFile(optXId, variationId, file);

  return (
    <FileCard key={`filecard-${cursorFile.name}`}>
      <CardContent>
        <FileHeader>
          <FileName>{cursorFile.name}</FileName>
          <EditButton
            kind="secondary"
            disabled={disabled}
            onClick={handleOpenEditModal}
          >
            <EditIcon />
          </EditButton>
        </FileHeader>

        <FileText>{cursorFile.text}</FileText>

        <ASModal
          title={cursorFile.name}
          open={showModal}
          onClose={handleCloseEditModal}
        >
          <ModalContainer>
            <ASLowCodeFormContainer
              fullWidth
              isUpdate
              formDefinition={editXFileFormDefinition}
              defaultValues={cursorFile}
              onValid={async (data) => {
                try {
                  JSON.parse(data.text);
                } catch (err) {
                  return {
                    error: "failed to parse JSON.",
                    message: "failed to parse JSON.",
                  };
                }
              }}
              onSubmit={async (data) => {
                handleSubmitXFile(data);
              }}
            />
          </ModalContainer>
        </ASModal>
      </CardContent>
    </FileCard>
  );
};
