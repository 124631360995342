import { PipelineX } from "allegro-api";
import produce from "immer";
import { createnXtalAPI } from "src/api";
import { useAuth } from "src/root";
import { useMyGroups } from "src/portal/hooks/useMyGroups";
import { useMyRole } from "src/portal/hooks/useMyRole";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { XervicePermissions } from "../types/xervicePermissions";

export const usePipelineXPermissionControl = (pipelineX: PipelineX) => {
  const auth = useAuth();
  const myRole = useMyRole();
  const myGroup = useMyGroups();

  const [permissions, setPermissions] = useState<XervicePermissions>({
    readableUsers: pipelineX.readableUsers ?? [],
    writableUsers: pipelineX.writableUsers ?? [],
    readableGroups: pipelineX.readableGroups ?? [],
    writableGroups: pipelineX.writableGroups ?? [],
  });
  const nxtal = createnXtalAPI();

  const snackbar = useSnackbar();

  /**
   *  pipelinXが更新権限のフラグ
   *  - ロールが更新権限のpermissionを持っている
   *  - writableUsersが”*”もしくは自分のユーザー番号が指定される
   *  - writableGroupsに指定されているグループに自身が所属する
   * */

  const canEditPipeline =
    myRole.canUpdateAnyPipelineX &&
    (pipelineX.writableUsers?.includes("*") ||
      pipelineX.writableUsers?.includes(auth.userNoText ?? "") ||
      (pipelineX.writableGroups ?? [])?.filter((groupId) => {
        return myGroup.isBelongToGroup(groupId);
      }).length > 0);

  // PipelineXのPermissionを更新する
  const updatePermissions = async (data: XervicePermissions) => {
    const newPipelineX = produce(pipelineX, (draft) => {
      draft.readableUsers = data.readableUsers
        ? data.readableUsers
        : draft.readableUsers;
      draft.writableUsers = data.writableUsers
        ? data.writableUsers
        : draft.writableUsers;
      draft.readableGroups = data.readableGroups
        ? data.readableGroups
        : draft.readableGroups;
      draft.writableGroups = data.writableGroups
        ? data.writableGroups
        : draft.writableGroups;
    });

    try {
      const res = await nxtal.pipelinex.update({
        contractorNo: sessionStorage.contractorNo,
        pipelineId: pipelineX.id,
        xervice: newPipelineX,
      });

      if (res.status === 200) {
        snackbar.enqueueSnackbar("権限の変更に成功しました。", {
          variant: "success",
        });
        setPermissions({ ...permissions, ...data });
      } else {
        snackbar.enqueueSnackbar("権限の変更に失敗しました。", {
          variant: "error",
        });
      }
    } catch (err) {
      snackbar.enqueueSnackbar("権限の変更に失敗しました。", {
        variant: "error",
      });
    }
  };

  return {
    permissions,
    updatePermissions,
    canEditPipeline,
  };
};
