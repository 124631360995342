import React, { useCallback, useEffect } from "react";

import { Handle, Position, Node, NodeProps } from "react-flow-renderer";
import styled from "styled-components";
import { gridSize, gridOffset } from "../organisms/XRGridEditorView";
import { darken, transparentize } from "polished";
import LinkIcon from "@material-ui/icons/Link";
import {
  LinkObject,
  useLinkObjectList,
} from "src/xerver-room/hooks/useLinkObjectList";
import produce from "immer";
import { activityLogger } from "src/xerver-room/activity-logger";

const Container = styled.div.attrs<{ selected: boolean }>((props) => ({}))<{
  selected: boolean;
}>`
  width: ${() => gridSize * 13 + gridOffset * 2}px;

  border-top: 2px solid
    ${(props) => (props.selected ? props.theme.primaryColor : "#4c4c4c")};
  border-bottom: 2px solid
    ${(props) => (props.selected ? props.theme.primaryColor : "#3c3b3b")};
  border-right: 2px solid
    ${(props) => (props.selected ? props.theme.primaryColor : "#3d3d3d")};
  border-left: 2px solid
    ${(props) => (props.selected ? props.theme.primaryColor : "#585858")};
  position: relative;
  border-radius: 20px;
  background: ${(props) => transparentize(0.1, props.theme.cardColor)};
  min-height: ${() => gridSize * 3 + gridOffset * 2}px;

  box-shadow: 5px 5px 31px #0e0e0e, -5px -5px 31px #202020;
`;

const Content = styled.div`
  height: 100%;
`;

const SmallTitle = styled.div`
  margin-top: 4px;
  font-size: 16px;
  opacity: 0.6;
`;

type HandleProps = {
  kind: "http" | "tcp" | "default";
};
const StyledHandle = styled(Handle).attrs((props: HandleProps) => {
  const { kind } = props;
  const color =
    kind === "http" ? "#3bdf48" : kind === "tcp" ? "#ef9b2e" : "#c2c2c2";

  return { color };
})<HandleProps>`
  width: 16px;
  height: 16px;
  border: 2px solid ${(props) => props.color};
  background: ${(props) => darken(0.4, props.color)};
`;

const StyledSlot = styled.div`
  text-align: left;

  padding: 18px 24px;

  > h1 {
    font-size: 12px;
    font-weight: bold;
  }
  > h3 {
    margin-top: 8px;
    margin-bottom: 3px;
    opacity: 0.1;
    font-size: 10px;
  }
  > span {
    font-size: 14px;
    color: #86e47d;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  > *:first-child {
    margin-right: 8px;
  }
`;

const StatusLayout = styled.div`
  position: absolute;
  right: 18px;
  bottom: 12px;
`;

const TitleLayout = styled.h2`
  display: flex;
  font-size: 20px;
  > *:first-child {
    margin-right: 8px;
  }
`;

const LinkObjectIcon = styled(LinkIcon)`
  color: #298cef;
  /* opacity: 0.5; */
  font-size: 20px;
`;

const DisabledText = styled.span`
  color: ${(props) => (props.theme.darkMode ? "#666" : "#999")};
`;

const IOWrapper = styled.div`
  position: relative;
  display: flex;
`;
const InputLabel = styled.div`
  position: absolute;
  bottom: 12px;
  right: 24px;
  font-size: 14px;
  color: #777;
`;

const OutputLabel = styled.div`
  position: absolute;
  left: 24px;
  bottom: 12px;
  font-size: 14px;
  color: #777;
`;

const LeftSideHandleArea = styled.div`
  position: absolute;
  display: flex;
  left: 0;
  top: 0;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
`;
const RightSideHandleArea = styled.div`
  position: absolute;
  display: flex;

  flex-direction: column;
  justify-content: space-around;
  right: 0;
  top: 0;
  height: 100%;
`;

type NodeData = {
  //   slot?: Slot;
  linkObject?: LinkObject;
};

export type CustomNode = Node<NodeData>;

export const XRCustomLinkNode: React.FC<CustomNode & NodeProps> = ({
  data,
  ...props
}) => {
  const { linkObject } = data;
  const { updateOne } = useLinkObjectList();

  useEffect(() => {}, [data]);

  const updateLinkObject = useCallback(async () => {
    const nextLinkObject = produce(
      linkObject as LinkObject,
      (draft: LinkObject) => {
        draft.ui = {
          x: props.xPos,
          y: props.yPos,
        };
      }
    );
    await updateOne(linkObject.linkObjectId, nextLinkObject);
    // const activityMessage = `Slot:${linkObject.linkObjectId}の位置を変更しました。`;
    // await activityLogger.saveSlotActivity(
    //   "info",
    //   activityMessage,
    //   linkObject.pipelineId,
    //   linkObject.linkObjectId
    // );
  }, [linkObject, updateOne, props.xPos, props.yPos]);

  useEffect(() => {
    if (!props.dragging) {
      updateLinkObject();
    }
  }, [props.dragging, props.xPos, props.yPos, updateLinkObject]);

  return (
    <Container selected={props.selected}>
      <Content>
        <LeftSideHandleArea>
          {linkObject.inputs.length > 0 ? (
            linkObject.inputs.map((input: any, i: number) => {
              return (
                <IOWrapper key={`key-io-${i}`}>
                  <InputLabel>{input.name}</InputLabel>
                  <StyledHandle
                    type="target"
                    position={"left" as Position}
                    // onConnect={(params: any) => console.log("handle onConnect", params)}
                    isConnectable={true}
                    id={input.name}
                    style={{
                      position: "relative",
                      margin: 0,
                      padding: 0,
                      lineHeight: 0,
                      top: 0,
                      left: -6,
                      transform: "translateY(0)",
                    }}
                    kind={input.type}
                  />
                </IOWrapper>
              );
            })
          ) : (
            <></>
          )}
        </LeftSideHandleArea>
        <RightSideHandleArea>
          {linkObject.outputs.length > 0 ? (
            linkObject.outputs.map((output: any, i: number) => {
              return (
                <IOWrapper key={`key-io-${i}`}>
                  <OutputLabel>{output.name}</OutputLabel>
                  <StyledHandle
                    type="source"
                    position={"right" as Position}
                    id={output.name}
                    onConnect={(params: any) => {
                      //@TODO
                      //   addConnection(params);
                    }}
                    style={{
                      position: "relative",
                      right: -6,
                      margin: 0,
                      padding: 0,
                      lineHeight: 0,
                      top: 0,
                      transform: "translateY(0)",
                    }}
                    isConnectable={true}
                    kind={output.type}
                  />
                </IOWrapper>
              );
            })
          ) : (
            <></>
          )}
        </RightSideHandleArea>

        {/* <HandleBar className="custom-drag-handle" /> */}
        <StyledSlot>
          <Header>
            {/* <h1>{slot.optXId}</h1> */}

            <TitleLayout>
              {linkObject.name ? (
                <>
                  {linkObject.name ? (
                    <span>
                      {linkObject.name}
                      <SmallTitle>{linkObject.name}</SmallTitle>
                    </span>
                  ) : (
                    <DisabledText>{linkObject.name}(Empty)</DisabledText>
                  )}
                </>
              ) : (
                <>
                  {linkObject.name ? (
                    <span>{linkObject.name}</span>
                  ) : (
                    <DisabledText>Empty</DisabledText>
                  )}
                </>
              )}
            </TitleLayout>
          </Header>
        </StyledSlot>
        <StatusLayout>
          <LinkObjectIcon />
        </StatusLayout>
      </Content>

      {/* <StyledHandle
        type="source"
        position={"right" as Position}
        id="b"
        style={{ bottom: 10, top: "auto", background: "#555" }}
        isConnectable={true}
      /> */}
    </Container>
  );
};
