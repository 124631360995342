import { Navigate } from "react-router-dom";
import { useAuth } from "./UseAuth";
import { SESSION_EXPIRE_MS } from "src/consts/session";

const validateExpired = (dateNum: number | null) => {
  if (dateNum) {
    try {
      const now = new Date().getTime();
      return now - dateNum > 0;
    } catch (err) {
      return false;
    }
  }

  return false;
};
export const PrivateRoute = (props: any) => {
  const auth = useAuth();

  if (validateExpired(auth.expired ?? null)) {
    if (auth.logout) auth.logout();
  } else if (auth.expired) {
    // 有効期限の延長 1分ごとに更新
    // 毎回更新だと無限ループのようになるため
    const expireTimeMS = new Date().getTime() + SESSION_EXPIRE_MS;
    if (auth.extendExpireTime && expireTimeMS - auth.expired > 60 * 1000) {
      auth.extendExpireTime();
    }
  }
  // 渡された props をそのまま Route に設定する
  return <>{!auth.token ? <Navigate replace to="/login" /> : props.children}</>;
};
