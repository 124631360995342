import { useOptXV2VariationDetail } from "src/xerver-room/hooks/useOptXV2VariationDetail";
import { XRPageTemplate } from "./XRPageTemplate";
import {
  CHTabComponentProps,
  CHTabs,
} from "src/cheetah/components/molecules/CHTabs";
import { useOptXV2 } from "src/xerver-room/hooks/useOptXV2";
import { XROptXV2VersionUpdateForm } from "../organisms/XROptXV2VersionUpdateForm";
import { XROptXV2VersionInfo } from "../organisms/XROptXV2VersionInfo";
import { ASChip, ASPictureIcon } from "allegro-ui";
import styled from "styled-components";

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin-right: 12px;
  }
`;

type XROptXVersionSingleTemplateProps = {
  variationId: string;
  optXId: string;
};

export const XROptXVersionSingleTemplate: React.FC<XROptXVersionSingleTemplateProps> =
  (props) => {
    const { variationId, optXId } = props;

    const optxv2 = useOptXV2(optXId);
    const { optXVariation } = useOptXV2VariationDetail(optXId, variationId);

    const tabs: CHTabComponentProps[] = [
      {
        label: "Info",
        component: (
          <XROptXV2VersionInfo
            optX={optxv2.getOptX()}
            optXVariation={optXVariation}
          />
        ),
      },
      {
        label: "Settings",
        component: <XROptXV2VersionUpdateForm optXVariation={optXVariation} />,
      },
    ];

    return (
      <XRPageTemplate
        title={
          <Header>
            <ASPictureIcon
              size={50}
              src={optxv2.thumbnail}
              defaultSrc={optxv2.thumbnail}
            />
            <h1>{optxv2.name}</h1>
            <ASChip label={optXVariation?.version} />
          </Header>
        }
      >
        <CHTabs id="xr-optx-version-single" tabs={tabs} />
      </XRPageTemplate>
    );
  };
