import { useMyRole } from "src/portal/hooks/useMyRole";
import { createOptXV2VariationFormDefinition } from "../config/formDefinition/optXV2VariationFormDefinition";
import { createOptxV2VariationBrowserUi } from "../config/ui/optxV2VariationBrowserUi";
import { useAuth } from "src/root";
import { useOptXV2 } from "./useOptXV2";

export const useOptXV2Variations = (optXId: string) => {
  const auth = useAuth();
  const role = useMyRole();

  const optx = useOptXV2(optXId);

  const canCreateOptXVersion =
    role.canCreateOptXVariation && auth.contractorNo === optx.contractorNo;

  const browserUi = createOptxV2VariationBrowserUi(optXId);
  const formDefinition = createOptXV2VariationFormDefinition(
    optXId,
    undefined,
    optx.shareLevel,
    false
  );
  return {
    canCreateOptXVersion,
    browserUi,
    formDefinition,
  };
};
