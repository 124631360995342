import { APIConfigProps, createAPIConfig } from "../config/http";
import { OptX } from "./optx";
import { OptXV2 } from "./optxv2";
import { PipelineX } from "./pipelinex";
import { Registry } from "./registry";
import { Xervice } from "./xervice";
import { xfile as XFile } from "./xfile";

const _replaceApiVersionUrl = (input: string, newNumber: number) => {
  const result = input.replace(/\/api\/\d+/, `/api/${newNumber}`);
  return result;
};

export const nXtal = (props: APIConfigProps) => {
  const instanceForV1 = createAPIConfig(props);
  const instanceForV2 = createAPIConfig(props);

  if (instanceForV2.defaults.baseURL) {
    instanceForV2.defaults.baseURL = _replaceApiVersionUrl(
      instanceForV2.defaults.baseURL,
      2
    );
  } else {
    console.error("err");
  }

  const xervice = Xervice(instanceForV1);
  const optx = OptX(instanceForV1);
  const optxv2 = OptXV2(instanceForV2);
  const pipelinex = PipelineX(instanceForV1);
  const registry = Registry(instanceForV2);
  const xfile = XFile(instanceForV2);

  return {
    xervice,
    optx,
    optxv2,
    pipelinex,
    registry,
    xfile,
  };
};
