import { createNewtZeroAPI } from "src/api";
import { ASBrowserUiConf } from "src/cheetah/components/libs/ASLowCodeBrowser";

export const createPipelineActivitiesBrowserUi = (
  pipelineId: string
): ASBrowserUiConf => {
  return {
    newtQ: async (limit, skip, options) => {
      const { search, order, match } = options ?? {};
      const newtzero = createNewtZeroAPI();

      const searchQuery = {
        ...search,
        "contents.rawdata.resource": "slot",
        "contents.rawdata.tags": `pipeline:${pipelineId}`,
      };

      const newtQuery = {
        shape: {
          "contents.rawdata": "any",
          bases: "any",
        },
        limit,
        skip,
        search: searchQuery,
        order: {
          ...order,
          "bases.created": -1,
        },
        match,
      };

      const activities = await newtzero.activity
        .dataProvider(newtQuery)
        .catch(() => []);

      return activities;
    },
    tables: [
      {
        label: "Level",
        component: "LCSimpleColComponent",
        data: {
          value: "level",
        },
        options: {},
      },
      {
        label: "Message",
        component: "LCSimpleColComponent",
        data: {
          value: "message",
        },
        options: {},
      },
      {
        label: "Created",
        component: "LCUnixTimeCellComponent",
        data: {
          value: "created",
        },
        options: {
          dateFormat: "default",
        },
      },
      {
        label: "Created By",
        component: "LCUserIconComponent",
        data: {
          userNo: "createdBy",
        },
        options: {},
      },
      {
        label: "Slot ID",
        component: "LCSimpleColComponent",
        data: {
          value: "resourceId",
        },
        options: {},
      },
    ],
    pager: {
      perPage: 50,
    },
  };
};
