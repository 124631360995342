import { createNewtZeroAPI } from "src/api";
import { Activity } from "allegro-api";

// あるユーザーのアクティビティログのリストを取得する
export const fetchActivitiesByUserNo = async (
  userNo: number,
  options?: {
    limit?: number;
    skip?: number;
  }
): Promise<Activity[]> => {
  try {
    const newtzero = createNewtZeroAPI();
    const newtQuery = {
      shape: {
        id: "any",
        bases: "any",
        contents: "any",
      },
      limit: options?.limit ?? 50,
      skip: options?.skip ?? 0,
      search: {
        "bases.userNo": String(userNo),
      },
      order: {
        "bases.created": -1,
      },
    };
    const activities = await newtzero.activity.dataProvider(newtQuery);
    return activities;
  } catch (err) {
    console.error(err);
  }

  return [];
};
