import { OptXV2 } from "allegro-api";
import {
  OptXCard,
  OptXCreator,
  OptXHeader,
  OptXIconPlaceHolder,
  OptXId,
  OptXName,
} from "../atoms/OptXCard";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { CardActionArea, CardContent, CardActions } from "@material-ui/core";
import { ASPictureIcon, ASIconButton } from "allegro-ui";
import { XRTextArea } from "../atoms/XRTextArea";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const MenuArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  > *:last-child {
    margin-left: auto;
  }
  padding: 12px;
`;

type OptXV2CardItemProps = {
  optxv2: OptXV2;
  onClick: (optXId: string) => void;
  selected?: boolean;
};

export const XROptXV2CardItem: React.FC<OptXV2CardItemProps> = (props) => {
  const { optxv2, onClick, selected } = props;

  const navigate = useNavigate();

  // OptX詳細画面へ遷移する
  const handleNavigateOptXDetail = () => {
    navigate(`/optx/${optxv2.optXId}`);
  };

  return (
    <OptXCard selected={selected}>
      <CardActionArea onClick={() => onClick(optxv2.optXId)}>
        <CardContent>
          <OptXIconPlaceHolder>
            <ASPictureIcon
              src={optxv2.thumbnail}
              defaultSrc={optxv2.thumbnail}
              size={42}
            />
          </OptXIconPlaceHolder>
          <OptXHeader>
            <OptXName>
              {optxv2.name}

              {/* {optX.buildHistory[0]?.message === "ビルド成功" ? (
                <AvailableBudge>ビルド成功</AvailableBudge>
              ) : (
                <NonAvailableBudge>利用不可</NonAvailableBudge>
              )} */}
            </OptXName>

            <OptXCreator>by AllegroSmart, Inc.</OptXCreator>
          </OptXHeader>

          {/* <h3> {optxv2.parentSlotId}</h3> */}
          <XRTextArea>{optxv2.description}</XRTextArea>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <MenuArea>
          <OptXId>{optxv2.optXId}</OptXId>
          <ASIconButton
            kind="secondary"
            size="small"
            onClick={() => {
              handleNavigateOptXDetail();
            }}
          >
            <ArrowForwardIosIcon />
          </ASIconButton>
        </MenuArea>
      </CardActions>
    </OptXCard>
  );
};
