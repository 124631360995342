import React, { useState } from "react";
import styled from "styled-components";
import { ASButton, ASInputBase, ASPictureSelectButton } from "allegro-ui";
import { OptX } from "src/xerver-room/models";
import { useOptX } from "src/xerver-room/hooks";
import { ASLowCodeFormContainer } from "allegro-low-code-components";
import { updateOptXFormDeinition } from "src/xerver-room/config/formDefinition";

const MenuArea = styled.div`
  display: flex;
  flex-direction: column;

  > *:last-child {
    margin-bottom: 24px;
  }
`;

const FormContainer = styled.div`
  margin: 12px 0;
`;

const Block = styled.div`
  margin-top: 24px;
  padding-top: 12px;
  border-top: 1px solid #333;
  > p {
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;

const MarginDiv = styled.div`
  text-align: right;
  margin-top: 12px;
`;

type Props = {
  optX: OptX;
};
export const XRUpdateOptXForm: React.FC<Props> = (props) => {
  const [deleteInputText, setDeleteInputText] = useState();
  const optX = useOptX(props.optX.optXId);

  const handleDeleteInput = (e: any) => {
    setDeleteInputText(e.target.value);
  };

  const handleDelete = async () => {
    //入力したフィールドとOptXの名前が一致するなら削除する
    if (deleteInputText === optX.name) {
      //delete
      const ok = await optX.remove();
      if (ok) {
        alert(`${optX.name} is deleted.`);
      } else {
        alert(`Failed to delete ${optX.name}.`);
      }
    } else {
      alert(`Failed to delete ${optX.name}. Invalid OptX name.`);
    }
  };

  const handleSubmit = async (data: { [key: string]: any }) => {
    const ok = await optX.update(data);
    if (ok) {
      alert(`${optX.name} is updated.`);
    } else {
      alert(`Failed to rename ${optX.name} .`);
    }
  };

  const handleThumb = async (file: any) => {
    const ok = await optX.update({ thumbnail: file });
    if (ok) {
      alert(`Thumbnail is updated.`);
    } else {
      alert(`Failed set thumbnail to  ${optX.name} .`);
    }
  };

  return (
    <MenuArea>
      <Block>
        <h1>OptX Setting</h1>

        <FormContainer>
          <ASLowCodeFormContainer
            fullWidth
            isUpdate
            defaultValues={optX}
            formDefinition={updateOptXFormDeinition}
            onSubmit={async (data, options) => {
              handleSubmit(data);
            }}
          />
        </FormContainer>
      </Block>
      <Block>
        <h1>Icon Setting</h1>
        <p>画像は1MB以下にしてください。大きすぎるとアップロードできません。</p>
        <MarginDiv>
          <ASPictureSelectButton onChange={handleThumb} />
        </MarginDiv>
      </Block>

      <Block>
        <h1>Delete OptX</h1>
        <p>削除するには確認のため、OptXの名前を入力してください。</p>
        <ASInputBase
          kind="secondary"
          onChange={handleDeleteInput}
        ></ASInputBase>
        <ASButton kind="secondary" onClick={handleDelete}>
          DELETE
        </ASButton>
      </Block>
    </MenuArea>
  );
};
