import { defaultUser } from "allegro-models";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useRecoilValueLoadable } from "recoil";
import { myProfileState } from "src/root/recoils/userState";
import { useProfileActions } from "./useProfileActions";
import { User } from "allegro-api";
import { createnAuthAPI } from "src/api";
import { changePasswordFormUi } from "../ui/forms/changePasswordFormUi";
import {
  LowCodeFormData,
  LowCodeFormOptions,
} from "allegro-low-code-components";

const refreshPage = () => {
  window.location.reload();
};

const validatePassword = async (
  user: User,
  password: string
): Promise<boolean> => {
  const nauth = createnAuthAPI();
  try {
    const auth = await nauth.auth({
      contractor: user.contractorNo ?? "",
      email: user.username,
      password: password,
    });

    if (auth) {
      return true;
    }
  } catch (err) {}

  return false;
};

export const useSettingSecrets = () => {
  const myProfile = useRecoilValueLoadable(myProfileState);
  const snackbar = useSnackbar();

  const [showRefreshApiKeyModal, setOpen] = useState(false);

  const { updateProfile } = useProfileActions(
    myProfile.contents ?? defaultUser
  );

  const currentApiKey =
    myProfile.state === "hasValue" ? myProfile.contents?.token : "";

  const handleRefreshApiKey = async () => {
    await updateProfile({ refreshApikey: "true" });
    alert("Updated ApiKey.");
    refreshPage();
  };

  const handleValidatePassword = async (data: any, options: any) => {
    const { oldPassword, newPassword, confirmNewPassword } = data;
    if (myProfile.state !== "hasValue") {
      return {
        error: "",
        message: "アカウント情報を持っていません。",
      };
    }
    const validated = await validatePassword(myProfile.contents, oldPassword);
    if (!validated) {
      return {
        error: "error",
        message: "現在のパスワードが正しくありません。",
      };
    }

    if (oldPassword === newPassword) {
      return {
        error: "",
        message: "パスワードが変更されていません。",
      };
    }

    if (newPassword !== confirmNewPassword) {
      return {
        error: "",
        message: "新しいパスワードが一致しません。",
      };
    }

    return {
      error: undefined,
      message: undefined,
    };
  };

  const handleUpdatePassword = async (
    data: LowCodeFormData,
    options?: LowCodeFormOptions
  ) => {
    if (changePasswordFormUi.onSubmit) {
      try {
        await changePasswordFormUi.onSubmit(data);
        snackbar.enqueueSnackbar("パスワードの変更に成功しました。", {
          variant: "success",
        });
        window.location.reload();
      } catch (err) {
        snackbar.enqueueSnackbar("パスワードの変更に失敗しました。", {
          variant: "error",
        });
      }
    }
  };

  const handleOpenRefreshApiKeyModal = () => {
    setOpen(true);
  };

  const handleCloseRefreshApiKeyModal = () => {
    setOpen(false);
  };

  return {
    myProfile,
    currentApiKey,
    showRefreshApiKeyModal,
    handleValidatePassword,
    handleUpdatePassword,
    handleRefreshApiKey,
    handleOpenRefreshApiKeyModal,
    handleCloseRefreshApiKeyModal,
  };
};
