import { atom, selector, atomFamily } from "recoil";
import { createnXtalAPI } from "src/api";
import { authState } from "src/root/recoils/userState";
import { defaultPipelineX, PipelineX } from "allegro-api";
import { syncDBEffect } from "../effects/pipelineEffect";

export const cursorPipelineIdState = atom({
  key: "CursorPipelineId",
  default: "",
});

export const pipelineState = atomFamily<PipelineX, string>({
  key: "pipelineState",
  default: defaultPipelineX,
  effects: (pipelineId: string) => [syncDBEffect(pipelineId)],
});

export const pipelineSearchWordState = atom({
  key: "pipelineSearchWordState",
  default: "",
});

export const pipelineListState = selector<Array<PipelineX>>({
  key: "PipelineList",
  get: async ({ get }) => {
    const auth = get(authState);
    get(pipelineListRequestId);
    const searchWord = get(pipelineSearchWordState);
    const contractor: string =
      typeof auth.contractorNo === "string" ? auth.contractorNo : "";

    const nxtal = createnXtalAPI();
    const query = {
      where: {
        name: { $regex: searchWord, $options: "i" },
      },
      orderby: { name: 1 },
    };

    const pipelines = await nxtal.pipelinex.fetchMany(contractor, query);

    return pipelines;
  },
});

export const cursorPipelineState = selector<PipelineX | null>({
  key: "CursorPipeline",
  get: async ({ get }) => {
    const auth = get(authState);
    const pipelineId = get(cursorPipelineIdState);

    const contractor: string =
      typeof auth.contractorNo === "string" ? auth.contractorNo : "";

    const nxtal = createnXtalAPI();
    const pipelineX = await nxtal.pipelinex.fetchOne(contractor, pipelineId);

    return pipelineX;
  },
});

export const pipelineListRequestId = atom({
  key: "PipelineListRequestId",
  default: 0,
});
