import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { createnXtalAPI } from "src/api";
import { optXVariationState } from "../recoils";

export const useOptXV2VariationDetail = (
  optXId: string,
  variationId: string
) => {
  const nxtal = createnXtalAPI();

  const [optXVariation, setOptXVariation] = useRecoilState(
    optXVariationState(`${optXId}_${variationId}`)
  );

  const initialize = useCallback(async () => {
    if (optXVariation) {
      return;
    }

    if (!optXId || !variationId) {
      return;
    }

    try {
      const optXVariation = await nxtal.optxv2.optXVariation.fetchOne(
        optXId,
        variationId
      );
      if (optXVariation) {
        setOptXVariation(optXVariation);
      }
    } catch (err) {
      console.error(err);
    }
  }, [
    nxtal.optxv2.optXVariation,
    optXId,
    optXVariation,
    setOptXVariation,
    variationId,
  ]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return {
    optXVariation,
  };
};
