import { format } from "date-fns";
import moment from "moment";
import styled from "styled-components";

const Ms = styled.span`
  font-size: 11px;
`;

export interface ILCUnixTimeCellComponentProps {
  data: {
    value: any;
  };
  options?: {
    dateFormat?: "default" | "wareki" | "fromNow";
    hasMs?: boolean;
  };
}

export default function LCUnixTimeCellComponent(
  props: ILCUnixTimeCellComponentProps
) {
  const value = new Date(props.data.value);
  const isTime = !Number.isNaN(value.getTime());

  const dateFormat = props.options?.dateFormat ?? "default";

  const readableDate = () => {
    try {
      switch (dateFormat) {
        case "fromNow": {
          return moment(value).fromNow();
        }
        default: {
          return moment(value).format("yyyy-MM-DD HH:mm:ss");
        }
      }
    } catch (err) {
      return "-";
    }
  };

  const milliSeconds = () => {
    try {
      return format(value, ":SSS");
    } catch (err) {
      return "-";
    }
  };

  return (
    <div>
      {isTime ? (
        <span>
          {readableDate()}
          {props.options?.hasMs ? <Ms>{milliSeconds()}ms</Ms> : <></>}
        </span>
      ) : (
        <>-</>
      )}
    </div>
  );
}
