import React from "react";
import { NTGlobalNav } from "../organisms/NTGlobalNav";
import { XROptXBrowsePageTemplate } from "src/xerver-room/components/templates/XROptXBrowserPageTemplate";
import { OptXSchemaType } from "allegro-api";
import { useLocation } from "react-router-dom";

const parseOptXSchemaType = (
  schemaVersion: string
): OptXSchemaType | undefined => {
  switch (schemaVersion) {
    case OptXSchemaType.optxv1:
      return OptXSchemaType.optxv1;
    case OptXSchemaType.optxv2:
      return OptXSchemaType.optxv2;
    default:
      return undefined;
  }
};

type Props = {};
export const NTOptXBrowsePage: React.FC<Props> = (props) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const schemaVersion = parseOptXSchemaType(params.get("version") ?? "");

  return (
    <div>
      <NTGlobalNav />
      <XROptXBrowsePageTemplate schemaVersion={schemaVersion} />
    </div>
  );
};
