import { produce } from "immer";

export type ItemType =
  | "Project"
  | "Folder"
  | "File"
  | "CodeBox"
  | "Knowledge"
  | null;

export type Folder = ScienceFSItem & {
  type: "Folder";
  contents: null;
};

export type ScienceFile = ScienceFSItem & {
  type: "File";
};

export type CodeBox = ScienceFSItem & {
  type: "CodeBox";
  contents: {
    shouldInstant: boolean;
    xerverId: string;
    optXId: string;
  };
};

export type Project = ScienceFSItem & {
  type: "Project";
  contents: null;
};

export type ScienceFolder = Folder | CodeBox | Project;

export type ScienceItem = ScienceFolder | ScienceFile;

export function isScienceFolder(arg: ScienceItem): arg is ScienceFolder {
  return (
    arg.type === "Folder" || arg.type === "CodeBox" || arg.type === "Project"
  );
}

export function isScienceFile(arg: ScienceItem): arg is ScienceFile {
  return arg.type === "File";
}

export type ScienceFSItem = {
  name: string;
  parent: number | null;
  type: ItemType;
  itemId: number;
  readers: Array<UserId | GroupId>;
  writers: Array<UserId | GroupId>;
  owners: Array<UserId | GroupId>;
  author: Array<UserId | GroupId>;
  // createdDate: ISO;
  created_time?: number; //unixtime
  // updatedDate: ISO;
  last_edited_time?: number;
  last_edited_by: string;
  contents: any;
} & Partial<TopDataMeta>;

type TopDataMeta = {
  createdBy: string;
  updatedBy: string;
  createdTimestamp: string;
  createdUnixtime: number;
  _created: number;
  updatedTimestamp: string;
  updatedUnixtime: number;
  _modified: number;
};

type UserId = string;
type GroupId = string;

export const defaultScienceFolder: ScienceFolder = {
  name: "新規フォルダ",
  type: "Project",
  itemId: 1, //0はNG
  parent: 0, //0はparentなし(root)
  readers: [],
  writers: [],
  owners: [],
  author: [],
  created_time: 0,
  last_edited_by: "",
  last_edited_time: 0,
  contents: undefined,
};

export const defaultScienceFile: ScienceFile = {
  name: "新規ファイル",
  type: "File",
  itemId: 1, //0はNG
  parent: 0, //0はparentなし(root)
  readers: [],
  writers: [],
  owners: [],
  author: [],
  created_time: 0,
  last_edited_by: "",
  last_edited_time: 0,
  contents: undefined,
};

export const createScienceFolder: (
  props: any & { type: string }
) => ScienceFolder = (p) => {
  return produce(defaultScienceFolder, (draft) => {
    draft.itemId = p.itemId ?? draft.itemId;
    draft.parent = p.parent ?? draft.parent;
    draft.name = p.name ?? draft.name;
    draft.type = p.type ?? draft.type;
    draft.readers = p.readers ?? draft.readers ?? ["*"];
    draft.writers = p.writers ?? draft.writers ?? ["*"];
    draft.author = p.author ?? draft.author;
    draft.contents = p.contents ?? draft.contents;
  });
};

export const createScienceFile: (props: any & { type: string }) => ScienceFile =
  (p) => {
    if (!p.name) {
      console.log(p);
      // throw new Error("NOOO");
    }
    return produce(defaultScienceFile, (draft) => {
      draft.itemId = p.itemId ?? draft.itemId;
      draft.parent = p.parent ?? draft.parent;
      draft.name = p.name ?? draft.name;
      draft.type = p.type ?? draft.type;
      draft.readers = p.readers ?? draft.readers ?? ["*"];
      draft.writers = p.writers ?? draft.writers ?? ["*"];
      draft.author = p.author ?? draft.author;
      draft.contents = p.contents ?? draft.contents;
    });
  };

export const permissionToPublic = (item: ScienceItem): ScienceItem => {
  return produce<ScienceItem>(item, (draft) => {
    draft.writers = ["*"];
    draft.readers = ["*"];
  });
};

export const permissionToClose = (item: ScienceItem): ScienceItem => {
  return produce<ScienceItem>(item, (draft) => {
    draft.writers = [];
    draft.readers = [];
  });
};

export const chmodAdd = (
  props: ScienceItem,
  type: "Reader" | "Writer",
  userId: string
): ScienceItem => {
  return produce<ScienceItem>(props, (draft) => {
    switch (type) {
      case "Reader":
        if (draft.readers.includes(userId)) {
          console.error("already included.");
        } else {
          draft.readers = [...draft.readers, userId];
        }
        break;
      case "Writer":
        if (draft.writers.includes(userId)) {
          console.error("already included.");
        } else {
          draft.writers = [...draft.writers, userId];
        }
        break;
      default:
        console.error("chmod::不正な値");
    }
  });
};

export const chmodRemove = (
  props: ScienceItem,
  type: "Reader" | "Writer",
  userNo: string
): ScienceItem => {
  return produce<ScienceItem>(props, (draft) => {
    switch (type) {
      case "Reader":
        draft.readers = draft.readers.filter((reader) => {
          return reader !== userNo;
        });

        break;
      case "Writer":
        draft.writers = draft.writers.filter((writer) => {
          return writer !== userNo;
        });

        break;
      default:
        console.error("chmod::不正な値");
    }
  });
};

// export const adaptToScienceFile = (file: File): ScienceFile => {
//   return {
//     name: file.name ?? "新規フォルダ",
//     type: "File",
//     itemId: file.fileId ?? 1, //0はNG
//     parent: file.parent ?? 0, //0はparentなし(root)
//     readers: file.writers ?? ["*"],
//     writers: file.readers ?? ["*"],
//     owners: [],
//     author: file.author ? [file.author] : [],
//     created_time: file.createdDateUnix ?? 0,
//     last_edited_by: "",
//     last_edited_time: file.updatedDateUnix ?? 0,
//     contents: null,
//   };
// };
