export type BasesSchema = {
  created: Date;
  modified: Date;
  contractorNo: string;
  createdBy: string | number;
  readers: string[];
  writers: string[];
};

export const defaultBasesSchema: BasesSchema = {
  created: new Date(),
  modified: new Date(),
  contractorNo: "",
  createdBy: "",
  readers: ["*"],
  writers: ["*"],
};
