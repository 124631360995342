import styled from "styled-components";

import { ASCard } from "allegro-ui";
import React from "react";

const StyledCard = styled(ASCard)`
  width: 100%;
  height: 300px;
  padding: 40px;
`;

const Text = styled.div`
  font-size: 14px;
  line-space: 10px;
`;

type Props = {
  text?: string;
};

export const MDFinishCard: React.FC<Props> = (props) => {
  const text =
    props.text ?? "これで質問は終了です。ご協力ありがとうございました。";
  return (
    <StyledCard>
      <Text>
        <p>{text}</p>
      </Text>
    </StyledCard>
  );
};
