import produce from "immer";
import { BasesSchema, defaultBasesSchema } from "./Base";

export type OptXBuildHistory = {
  historyId: string; //API側で自動発行
  optXId: string;
  variationId: string;
  message: string;

  // docker image name. {host}/{image}:{tag}
  dockerImageFullName: string;
} & BasesSchema;

export const defaultOptXBuildHistory: OptXBuildHistory = {
  historyId: "",
  optXId: "",
  variationId: "",
  message: "",
  dockerImageFullName: "",
  ...defaultBasesSchema,
};

export const createOptXBuildHistoryFromNewtData = (data: {
  [key: string]: any;
}): OptXBuildHistory => {
  return produce(defaultOptXBuildHistory, (draft) => {
    draft.historyId = data.contents.rawdata.historyId ?? draft.historyId;
    draft.optXId = data.contents.rawdata.optXId ?? draft.optXId;
    draft.variationId = data.contents.rawdata.variationId ?? draft.variationId;
    draft.message = data.contents.rawdata.message ?? draft.message;
    draft.dockerImageFullName =
      data.contents.rawdata.dockerImageFullName ?? draft.dockerImageFullName;

    draft.contractorNo = data.bases.contractorNo ?? draft.contractorNo;
    draft.createdBy = data.bases.createdBy ?? draft.createdBy;
    draft.created = data.bases.created ?? draft.created;
    draft.modified = data.bases.modified ?? draft.modified;
  });
};
