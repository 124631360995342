import { Server } from "allegro-api/lib/models/Server";
import { ASLowCodeFormDefinition } from "allegro-low-code-components";
import { createNewtZeroAPI } from "src/api";

export const serverCreatingFormDefinition: ASLowCodeFormDefinition = {
  sections: [
    {
      title: "Server Setting",
      fields: [
        {
          label: "Server Name",
          name: "non_numeric_server_name",
          type: "text",
          active: "",
          values: undefined,
          required: true,
          errorText: "Server name is required.",
        },
        {
          label: "IP1",
          name: "non_numeric_ip1",
          type: "text",
          active: "",
          values: undefined,
          required: true,
          errorText: "IP1 is required.",
        },

        {
          label: "IP2",
          name: "non_numeric_ip2",
          type: "text",
          active: "",
          values: undefined,
        },
        {
          label: "IP3",
          name: "non_numeric_ip3",
          type: "text",
          active: "",
          values: undefined,
        },
        {
          label: "Public SSH Key",
          name: "non_numeric_public_ssh_key",
          type: "text",
          active: "",
          values: undefined,
        },

        {
          label: "Tags",
          name: "non_numeric_tags",
          type: "chip",
          active: [],
          values: undefined,
          required: true,
          errorText: "Tags is required",
        },

        {
          label: "SSH Informations",
          name: "nAnsible用設定",
          type: "section",
          active: "",
          values: undefined,
        },
        {
          label: "SSH Host",
          name: "non_numeric_ssh_host",
          type: "text",
          active: "root@127.0.0.1",
          values: undefined,
          errorText: "入力してください。",
        },
        {
          label: "SSH Private Key",
          name: "non_numeric_ssh_private_key",
          type: "text",
          active: "",
          values: undefined,
          errorText: "入力してください。",
        },
        {
          label: "SSH Public Key",
          name: "non_numeric_ssh_public_key",
          type: "text",
          active: "",
          values: undefined,
          errorText: "入力してください。",
        },
        {
          label: "Server ID",
          name: "non_numeric_server_id",
          type: "skelton",
          values: undefined,
        },
      ],
    },
  ],
  onSubmit: async (data, options) => {
    //互換性のあるアセットを作成する。

    const newServer: Server = data as Server;
    let isSuccessed = false;
    const newtzero = createNewtZeroAPI(2);
    try {
      if (options?.isUpdate) {
        const serverId = newServer.non_numeric_server_id ?? "";
        const res = await newtzero.server.update(serverId, newServer);
        isSuccessed = res;
      } else {
        const res = await newtzero.server.add(newServer);
        isSuccessed = res !== null;
      }

      if (isSuccessed) {
        window.location.reload();
        return;
      }
    } catch (err) {
      console.error(err);
    }

    const errMsg = options?.isUpdate
      ? `Failed to update ${newServer.non_numeric_server_name}`
      : `Failed to create ${newServer.non_numeric_server_name}`;
    throw Error(errMsg);
  },
};
