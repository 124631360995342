import { OptXV2 } from "allegro-api";
import { LCListViewTemplate } from "src/cheetah/components/libs/ASLowCodeBrowser";
import { useOptXV2Variations } from "src/xerver-room/hooks/useOptXV2Variations";
import styled from "styled-components";

const Container = styled.div``;

type XROptXV2VersionListProps = {
  optxv2: OptXV2;
};

export const XROptXV2VersionList: React.FC<XROptXV2VersionListProps> = (
  props
) => {
  const { optxv2 } = props;
  const key = `xr-optxv2-variaitons-${optxv2.optXId}`;

  const { canCreateOptXVersion, browserUi, formDefinition } =
    useOptXV2Variations(optxv2.optXId);

  return (
    <Container>
      <LCListViewTemplate
        name={key}
        browserUi={browserUi}
        formUi={formDefinition}
        disabledCreate={!canCreateOptXVersion}
      />
    </Container>
  );
};
