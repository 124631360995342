import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { sortFilterState } from "src/cheetah/components/libs/ASLowCodeBrowser";
import { useMyRole } from "src/portal/hooks/useMyRole";

export const useInfrastractureViewer = () => {
  const { canCreateServer, canUpdateServer, canDeleteServer } = useMyRole();
  const setSortQuery = useSetRecoilState(sortFilterState("nt-dev-ops-page"));

  useEffect(() => {
    setSortQuery({
      _created: 1,
    });
  }, [setSortQuery]);

  return {
    canCreateServer,
    canUpdateServer,
    canDeleteServer,
  };
};
