/* eslint-disable react-hooks/exhaustive-deps */
import { createnXtalAPI } from "src/api";
import { useCallback, useEffect, useState } from "react";

export const useFileXList = (optXId: string) => {
  const [files, setFiles] = useState<Array<any>>([]);

  const nxtal = createnXtalAPI();

  const fetchFiles = useCallback(async () => {
    const res = await nxtal.optx.fetchFiles({
      contractorNo: sessionStorage.contractorNo,
      optXId: optXId,
    });

    if (res.status === 200) setFiles(res.data);
  }, [nxtal.optx, optXId]);

  useEffect(() => {
    fetchFiles();
  }, []);

  return { files };
};
