import React from "react";
import { ASIconButton, ASPictureIcon } from "allegro-ui";
import { CardActionArea, CardActions, CardContent } from "@material-ui/core";
import styled from "styled-components";
import { OptX } from "src/xerver-room/models";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { XRTextArea } from "../atoms/XRTextArea";
import {
  OptXCard,
  OptXCreator,
  OptXHeader,
  OptXIconPlaceHolder,
  OptXId,
  OptXName,
} from "../atoms/OptXCard";
import { useNavigate } from "react-router-dom";

const MenuArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  > *:last-child {
    margin-left: auto;
  }
  padding: 12px;
`;

type Props = {
  optX: OptX;
  onClick: (optXId: string) => void;
  selected?: boolean;
};

export const XROptXCardItem: React.FC<Props> = ({
  optX,
  onClick,
  selected,
}) => {
  const navigate = useNavigate();

  // OptX詳細画面へ遷移する
  const handleNavigateOptXDetail = () => {
    navigate(`/optx/${optX.optXId}`);
  };

  return (
    <>
      <OptXCard selected={selected}>
        <CardActionArea onClick={() => onClick(optX.optXId)}>
          <CardContent>
            <OptXIconPlaceHolder>
              <ASPictureIcon
                src={optX.thumbnail}
                defaultSrc={optX.thumbnail}
                size={42}
              />
            </OptXIconPlaceHolder>
            <OptXHeader>
              <OptXName>
                {optX.name}
                {/* {optX.buildHistory[0]?.message === "ビルド成功" ? (
                <AvailableBudge>ビルド成功</AvailableBudge>
              ) : (
                <NonAvailableBudge>利用不可</NonAvailableBudge>
              )} */}
              </OptXName>

              <OptXCreator>by AllegroSmart, Inc.</OptXCreator>
            </OptXHeader>

            <h3> {optX.parentSlotId}</h3>
            <XRTextArea>{optX.description}</XRTextArea>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <MenuArea>
            <OptXId>{optX.optXId}</OptXId>
            <ASIconButton
              kind="secondary"
              size="small"
              onClick={() => {
                handleNavigateOptXDetail();
              }}
            >
              <ArrowForwardIosIcon />
            </ASIconButton>
          </MenuArea>
        </CardActions>
      </OptXCard>
    </>
  );
};
