import { OptXV2, OptXVariation } from "allegro-api";
import { XROptXBlock } from "../molecules/XROptXBlock";
import { useOptXFiles } from "src/xerver-room/hooks/useOptXFiles";
import { ASChip, ASMarkdown } from "allegro-ui";
import { XRXFileV2Card } from "../molecules/XRXFileV2Card";
import { useAuth } from "src/root";
import { useMyRole } from "src/portal/hooks/useMyRole";

type OptXV2VersionInfoProps = {
  optX?: OptXV2 | null;
  optXVariation?: OptXVariation | null;
};

export const XROptXV2VersionInfo: React.FC<OptXV2VersionInfoProps> = (
  props
) => {
  const { optXVariation } = props;

  const auth = useAuth();
  const role = useMyRole();
  const { xfiles } = useOptXFiles(
    optXVariation?.optXId ?? "",
    optXVariation?.variationId ?? ""
  );

  const canUpdateOptXFile =
    auth.contractorNo === optXVariation?.contractorNo &&
    role.canUpdateOptXVariation;

  return (
    <div>
      <XROptXBlock title="Version ID">{optXVariation?.variationId}</XROptXBlock>
      <XROptXBlock title="Version">{optXVariation?.version}</XROptXBlock>
      <XROptXBlock title="Description">
        <ASMarkdown>{optXVariation?.description}</ASMarkdown>
      </XROptXBlock>
      <XROptXBlock title="Files">
        {xfiles.map((xfile, idx) => {
          return (
            <XRXFileV2Card
              key={`xr-file-card-${idx}-${xfile.name}`}
              optXId={optXVariation?.optXId ?? ""}
              variationId={optXVariation?.variationId ?? ""}
              disabled={!canUpdateOptXFile}
              file={xfile}
            />
          );
        })}
      </XROptXBlock>
      <XROptXBlock title="Docker Image Tag">
        <ASChip label={`${optXVariation?.dockerImageTag}`} />
      </XROptXBlock>
    </div>
  );
};
