interface Change {
  oldValue: any;
  newValue: any;
}

interface ChangeResult {
  [key: string]: Change;
}

/**
 * Compares two arrays for equality.
 *
 * This function checks if two arrays are equal by comparing each element.
 *
 * @param {any[]} arr1 - The first array to compare.
 * @param {any[]} arr2 - The second array to compare.
 * @returns {boolean} True if the arrays are equal, false otherwise.
 */
const arraysEqual = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
};

/**
 * Compares two objects and detects changes.
 *
 * This function compares the `fromObject` and `toObject` to identify changes.
 * It returns an object with the keys representing the changed fields,
 * and values containing the old and new values.
 *
 * @param {Record<string, any>} fromObject - The original object before changes.
 * @param {Record<string, any>} toObject - The modified object after changes.
 * @param {string[]} [keysToCheck] - Optional array of keys to check for changes.
 * @returns {ChangeResult} An object containing the changes.
 * @example
 * const fromObject = { name: 'Alice', age: 25, hobbies: ['reading', 'traveling'], address: undefined, notes: null };
 * const toObject = { name: 'Alice', age: 26, hobbies: ['reading', 'traveling', 'sports'], country: 'USA', address: undefined, notes: null };
 * console.log(detectObjectChanges(fromObject, toObject, ['age', 'hobbies']));
 * // Output: { age: { oldValue: 25, newValue: 26 }, hobbies: { oldValue: ['reading', 'traveling'], newValue: ['reading', 'traveling', 'sports'] } }
 */
export const detectObjectChanges = (
  fromObject: Record<string, any>,
  toObject: Record<string, any>,
  keysToCheck?: string[]
): ChangeResult => {
  const changes: ChangeResult = {};
  const duplicateKeys = [...Object.keys(fromObject), ...Object.keys(toObject)];
  const keys =
    keysToCheck ||
    [...Object.keys(fromObject), ...Object.keys(toObject)].filter(
      (value, idx) => duplicateKeys.indexOf(value) === idx
    );

  keys.forEach((key) => {
    if (fromObject[key] === undefined && toObject[key] === undefined) {
      // Both are undefined, do not detect change
      return;
    } else if (fromObject[key] === null && toObject[key] === null) {
      // Both are null, do not detect change
      return;
    } else if (Array.isArray(fromObject[key]) && Array.isArray(toObject[key])) {
      if (!arraysEqual(fromObject[key], toObject[key])) {
        changes[key] = { oldValue: fromObject[key], newValue: toObject[key] };
      }
    } else if (fromObject[key] !== toObject[key]) {
      changes[key] = { oldValue: fromObject[key], newValue: toObject[key] };
    }
  });

  return changes;
};
