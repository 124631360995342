import produce from "immer";
import { BasesSchema } from "./Base";

export enum OptXSchemaType {
  optxv1 = "optxv1",
  optxv2 = "optxv2",
  github = "github",
}

export type OptXV2 = {
  optXId: string;
  name: string;
  description: string;
  schemaType: OptXSchemaType.optxv2;
  buildHistory: string[];
  thumbnail?: string;
  dockerRepositoryName: string;

  gitReferenceId?: string;
  registryId?: string;

  shareLevel: "private" | "public";
  allowReadUsers: string[];
  allowReadGroups: string[];
  allowWriteUsers: string[];
  allowWriteGroups: string[];

  enable: boolean;
} & BasesSchema;

export type OptXVariation = {
  variationId: string;
  optXId: string;
  version: string;
  description?: string;
  dockerImageTag: string;
  files: string[];

  validated: boolean;
  validateDateTime?: Date;

  freezed: boolean;
  freezedDateTime?: Date;

  discarded: boolean;
  discardedDateTime?: Date;

  shareLevel: "private" | "public";
  allowReadUsers: string[];
  allowReadGroups: string[];
  allowWriteUsers: string[];
  allowWriteGroups: string[];
} & BasesSchema;

export type OptXV2Input = {
  optXId?: string;
  name: string;
  description: string;
  buildHistory: string[];
  thumbnail?: string;
  gitReferenceId?: string;
  registryId?: string;
  dockerRepositoryName?: string;

  shareLevel: "private";
  allowReadUsers: string[];
  allowReadGroups: string[];
  allowWriteUsers: string[];
  allowWriteGroups: string[];
};

export type OptXVariationInput = {
  variationId?: string;
  optXId: string;
  version: string;
  description?: string;
  dockerImageTag?: string;
  files: string[];

  shareLevel: "private" | "public";
  allowReadUsers: string[];
  allowReadGroups: string[];
  allowWriteUsers: string[];
  allowWriteGroups: string[];
};

export const defaultOptXV2: OptXV2 = {
  optXId: "",
  name: "",
  description: "",
  schemaType: OptXSchemaType.optxv2,
  buildHistory: [],
  enable: false,
  dockerRepositoryName: "",

  created: new Date(),
  modified: new Date(),
  contractorNo: "",
  createdBy: "",
  readers: ["*"],
  writers: ["*"],

  shareLevel: "private",
  allowReadUsers: ["*"],
  allowReadGroups: [],
  allowWriteUsers: ["*"],
  allowWriteGroups: [],
};

export const defaultOptXVariation: OptXVariation = {
  variationId: "",
  optXId: "",
  version: "",
  dockerImageTag: "",
  files: [],
  validated: false,
  freezed: false,
  discarded: false,

  created: new Date(),
  modified: new Date(),
  contractorNo: "",
  createdBy: "",
  readers: ["*"],
  writers: ["*"],

  shareLevel: "private",
  allowReadUsers: ["*"],
  allowReadGroups: [],
  allowWriteUsers: ["*"],
  allowWriteGroups: [],
};

export const createOptXV2FromNewtData = (data: {
  [key: string]: any;
}): OptXV2 => {
  return produce(defaultOptXV2, (draft) => {
    draft.optXId = data.contents.rawdata.optXId ?? draft.optXId;
    draft.name = data.contents.rawdata.name ?? draft.name;
    draft.description = data.contents.rawdata.description ?? draft.description;
    draft.buildHistory =
      data.contents.rawdata.buildHistory ?? draft.buildHistory;
    draft.thumbnail = data.contents.rawdata.thumbnail ?? draft.thumbnail;
    draft.gitReferenceId =
      data.contents.rawdata.gitReferenceId ?? draft.gitReferenceId;
    draft.registryId = data.contents.rawdata.registryId ?? draft.registryId;
    draft.dockerRepositoryName =
      data.contents.rawdata.dockerRepositoryName ?? draft.dockerRepositoryName;
    draft.enable = data.contents.rawdata.enable ?? draft.enable;

    draft.shareLevel = data.contents.rawdata.shareLevel ?? draft.shareLevel;
    draft.allowReadUsers =
      data.contents.rawdata.allowReadUsers ?? draft.allowReadUsers;
    draft.allowReadGroups =
      data.contents.rawdata.allowReadGroups ?? draft.allowReadGroups;
    draft.allowWriteUsers =
      data.contents.rawdata.allowWriteUsers ?? draft.allowWriteUsers;
    draft.allowWriteGroups =
      data.contents.rawdata.allowWriteGroups ?? draft.allowWriteGroups;

    draft.contractorNo = data.bases.contractorNo ?? draft.contractorNo;
    draft.createdBy = data.bases.userNo;
    draft.created = data.bases.created;
    draft.modified = data.bases.modified;

    draft.readers = data.readers ?? draft.readers;
    draft.writers = data.writers ?? draft.writers;
  });
};

export const createOptXVariationFromNewtData = (data: {
  [key: string]: any;
}): OptXVariation => {
  return produce(defaultOptXVariation, (draft) => {
    draft.variationId = data.contents.rawdata.variationId ?? draft.variationId;
    draft.optXId = data.contents.rawdata.optXId ?? draft.optXId;
    draft.version = data.contents.rawdata.version ?? draft.version;
    draft.dockerImageTag =
      data.contents.rawdata.dockerImageTag ?? draft.dockerImageTag;
    draft.files = data.contents.rawdata.files ?? draft.files;
    draft.description = data.contents.rawdata.description ?? draft.description;

    draft.freezed = data.contents.rawdata.freezed ?? draft.freezed;
    draft.freezedDateTime =
      data.contents.rawdata.freezedDateTime ?? draft.freezedDateTime;

    draft.discarded = data.contents.rawdata.discarded ?? draft.discarded;
    draft.discardedDateTime =
      data.contents.rawdata.discardedDateTime ?? draft.discarded;

    draft.validated = data.contents.rawdata.validated ?? draft.validated;
    draft.validateDateTime =
      data.contents.rawdata.validateDateTime ?? draft.validateDateTime;

    draft.shareLevel = data.contents.rawdata.shareLevel ?? draft.shareLevel;
    draft.allowReadUsers =
      data.contents.rawdata.allowReadUsers ?? draft.allowReadUsers;
    draft.allowReadGroups =
      data.contents.rawdata.allowReadGroups ?? draft.allowReadGroups;
    draft.allowWriteUsers =
      data.contents.rawdata.allowWriteUsers ?? draft.allowWriteUsers;
    draft.allowWriteGroups =
      data.contents.rawdata.allowWriteGroups ?? draft.allowWriteGroups;

    draft.contractorNo = data.bases.contractorNo ?? draft.contractorNo;
    draft.createdBy = data.bases.userNo;
    draft.created = data.bases.created;
    draft.modified = data.bases.modified;

    draft.readers = data.readers ?? draft.readers;
    draft.writers = data.writers ?? draft.writers;
  });
};
