import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { LowCodeFormOptions, LowCodeFormData } from "../models";
import {
  ASLowCodeFormContainerProps,
  ASLowCodeFormDefinition,
} from "../ASLowCodeForm";
import { GridSize, GridSpacing } from "@material-ui/core";

export const useLowCodeForm = (props: ASLowCodeFormContainerProps) => {
  const { formDefinition } = props;
  //   const [data, setData] = useState();
  const { sections, buttonOptions } = formDefinition;
  const onSubmit = props.onSubmit ?? formDefinition.onSubmit;
  const onValid = props.onValid ?? formDefinition.onValid;
  const columns = props.columns ?? 1;
  const cellSizeX: GridSize = columns === 2 ? 6 : 12;
  const gridSpacing: GridSpacing = 2;
  const editable = props.editable ?? true;

  const [values, setValues] = useState(props.defaultValues);
  const [validMessage, setValidMessage] = useState<string | undefined>();

  const { enqueueSnackbar } = useSnackbar();
  const defaultMessage = "Faled to send data.";

  const cancelButtonText =
    buttonOptions?.cancelText ?? props.buttonsOptions?.cancelText ?? "Cancel";
  const submitButtonText =
    buttonOptions?.submitText ?? props.buttonsOptions?.submitText ?? "Submit";
  const updateButtonText =
    buttonOptions?.updateText ?? props.buttonsOptions?.updateText ?? "Update";

  const buttonText = props.isUpdate ? updateButtonText : submitButtonText;
  const lowCodeOptions: LowCodeFormOptions = {
    isUpdate: props.isUpdate,
    approval: props.isApproval,
  };

  const form = useForm();

  const initializeFormValues = (data?: { [key: string]: any }) => {
    if (data) {
      setValues(data);
      for (const [key, value] of Object.entries(data)) {
        form.setValue(key, value);
      }
    }
  };

  useEffect(() => {
    initializeFormValues(props.defaultValues);
  }, [props.defaultValues]);

  const handleSubmitButton = async (
    data: LowCodeFormData,
    options: LowCodeFormOptions
  ) => {
    const errorMessage = onValid
      ? await onValid(data, options).catch((err) => {
          console.error(err);
          return {
            error: "error",
            message: "Invalid data.",
          };
        })
      : null;

    const isValid = !errorMessage || !errorMessage.error;
    if (isValid) {
      if (onSubmit) {
        try {
          await onSubmit(data, options);

          if (!props.disabledSnackbar) {
            enqueueSnackbar("Success.", {
              variant: "success",
            });
          }

          setValidMessage(undefined);
        } catch (err: any) {
          console.error(err, typeof err, String(err), err.message);
          const errMsg = err.message ?? defaultMessage;
          setValidMessage(errMsg);

          if (!props.disabledSnackbar) {
            enqueueSnackbar(errMsg, {
              variant: "error",
            });
          }
        }
      }
    } else {
      const errMsg = errorMessage?.message ?? defaultMessage;
      setValidMessage(errMsg);
      enqueueSnackbar(errMsg, {
        variant: "error",
      });
    }
  };

  const handleChangeValue = (fieldName: string, value: any) => {
    form.setValue(fieldName, value);
  };

  const handleCloseForm = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  return {
    formDefinition,
    form,
    cellSizeX,
    gridSpacing,
    editable,
    values,
    validMessage,
    buttonText,
    cancelButtonText,
    lowCodeOptions,
    handleSubmitButton,
    handleChangeValue,
    handleCloseForm,
  };
};
