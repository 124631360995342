import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import { tabIdState } from "src/fortress/recoils/singleChildPageState";
import { useLocation, useNavigate } from "react-router-dom";
import { ModalState } from "src/fortress/recoils/familyPersonalState";
import { CHTabsProps } from "../components/molecules/CHTabs";

export const useTabs = (props: CHTabsProps) => {
  const { tabs } = props;
  const [isInitialized, setIsInitialized] = useState(false);
  const [tabId, setTabId] = useRecoilState(tabIdState(props.id ?? ""));
  const setOpen = useSetRecoilState(ModalState);
  const history = useNavigate();

  const location = useLocation();

  const _setTabInURL = useCallback(
    (label: string) => {
      if (!props.disableUrl) {
        const params = new URLSearchParams();
        params.append("tab", label);
        history({ search: params.toString() }, { replace: true });
      }
    },
    [history, props.disableUrl]
  );

  const handleChange = (event: any, newValue: number) => {
    setTabId(newValue);

    if (props.handleChange) {
      props.handleChange(newValue);
    }

    const tab = props.tabs[newValue];
    if (tab) {
      _setTabInURL(tab.label);
    }
  };

  useEffect(() => {
    if (!isInitialized) {
      const params = new URLSearchParams(location.search);
      const tabLabel = params.get("tab");

      /**
       * 描画時にtabIdを決定し、セットする。tabIdに変更があれば、URLのQuery Parametersに反映する
       * 優先順位:
       *   defaultId -> Query Parametersによるtabの指定 -> recoilで保持しているtabId(何も指定されていない場合)
       */

      // defaultIdが有効な場合
      if (props.defaultId && props.defaultId >= 0 && tabs[props.defaultId]) {
        const newTabId = props.defaultId;
        setTabId(newTabId);

        // tabIdに変更がある場合、URLに反映する
        if (newTabId !== tabId && tabs[newTabId]) {
          const defaultTab = tabs[newTabId];
          _setTabInURL(defaultTab.label);
        }
      }
      // disableUrlが無効かつ、Query Parametersにtabが指定されている場合
      else if (!props.disableUrl && tabLabel) {
        const newTabId = props.tabs.findIndex((tab) => {
          return tab.label === tabLabel;
        });

        setTabId(newTabId >= 0 ? newTabId : 0);
      }
      // 何も指定がない場合
      else {
        if (tabId >= 0 && tabs[tabId]) {
          const defaultTab = tabs[tabId];
          _setTabInURL(defaultTab.label);
        }
      }

      setIsInitialized(true);
    }
  }, [
    _setTabInURL,
    isInitialized,
    location.search,
    props.defaultId,
    props.disableUrl,
    props.tabs,
    setTabId,
    tabId,
    tabs,
  ]);

  const back = () => {
    history(-1);
    setOpen(false);
  };

  return {
    tabId,
    handleChange,
    back,
  };
};
