import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { CHSettingNav } from "src/cheetah/components/organisms/CHSettingNav";
import { createNAISettingNavConf } from "src/nai-console/config/nAISettingNavConf";
import styled from "styled-components";
import { NASummaryCard } from "../organisms/NASummaryCard";
import { useBundle } from "src/nai-console/hooks/useBundle";
import { SummaryBlock } from "src/nai-console/models";
import {
  ASButton,
  ASChip,
  ASEasyTable,
  ASLoadingCircle,
  ASModal,
  ASSelect,
} from "allegro-ui";
import { createBundleSummaryConf } from "src/nai-console/config/bundleSummaryConf";
import { Bundle, createBundle } from "allegro-api";
import MDStickyHeader from "src/medican/components/molecules/MDStickyHeader";
import { ASLowCodeFormContainer } from "allegro-low-code-components";
import { commitNAIFormConf } from "src/nai-console/config/formUi/commitNAIFormConf";
import { useRecoilValueLoadable } from "recoil";
import {
  aiConfigParameterState,
  datasetCountState,
} from "src/nai-console/recoils/nAIState";

const Root = styled.div``;

const Body = styled.div`
  display: flex;
  flex-direction: row;
`;

const LeftPanel = styled.div`
  width: 300px;
  margin-right: 20px;
`;

const CenterPanel = styled.div`
  /* display: flex; */
  width: calc(100vw - 600px);
  background: inherit;
`;

const RightPanel = styled.div`
  width: 300px;
  margin-left: 20px;
`;

const HeaderPanel = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-left: 24px;
  > *:last-child {
    margin-left: auto;
  }
`;

const VersionSelect = styled(ASSelect)`
  height: 40px;
  min-height: 40px;
`;

const StyledButton = styled(ASButton)`
  height: 40px;
  min-height: 40px;
`;

const Indent = styled.div`
  padding-left: 8px;
`;

const StyledChip = styled(ASChip)`
  margin: 3px;
  font-size: ${(props) => props.theme.labelFontSizeMd}px;
`;

type Props = {};

export const NAAISingleTemplate: React.FC<Props> = (props) => {
  const params = useParams();
  const { bundleId } = params;

  const navigate = useNavigate();
  const location = useLocation().search;
  const query = new URLSearchParams(location);

  const queryVersion = query.get("version");

  const {
    bundleLoadable,
    setBundleId,
    setVersion,
    commit,
    version,
    bundleVersionsLoadable,
  } = useBundle();

  const trainingDatasetCount = useRecoilValueLoadable(
    datasetCountState("training")
  );
  const testDatasetCount = useRecoilValueLoadable(datasetCountState("test"));
  const aiConfigLoadable = useRecoilValueLoadable(aiConfigParameterState);

  const [commiModalShow, setCommitModalShow] = useState(false);

  useEffect(() => {
    if (bundleId) {
      setBundleId(bundleId);
    }

    if (queryVersion) {
      setVersion(queryVersion);
    }
  }, [bundleId, setBundleId]);

  const handleChangeVersion = (newVersion: string) => {
    setVersion(newVersion);
  };

  const handleCommit = async (newVersion: string, comment?: string) => {
    try {
      const res = await commit(newVersion, comment);
    } catch (err) {
      console.error(err);
    }
  };

  const renderHeader = () => {
    return (
      <HeaderPanel>
        {bundleVersionsLoadable.state === "hasValue" ? (
          <VersionSelect
            kind="digicho"
            data={bundleVersionsLoadable.contents}
            value={version}
            onChange={(e: any) => {
              const newVersion = e.target.value as string;
              if (newVersion !== version) {
                handleChangeVersion(newVersion);
                navigate(`/ai/${bundleId}?version=${newVersion}`);
              }
            }}
          />
        ) : (
          <div></div>
        )}
        <StyledButton
          kind="primary"
          disabled={
            bundleLoadable.state !== "hasValue" ||
            (bundleLoadable.state === "hasValue" &&
              !bundleLoadable.contents?.isDefault)
          }
          onClick={() => setCommitModalShow(true)}
        >
          Commit
        </StyledButton>
      </HeaderPanel>
    );
  };

  const createBundleBlocks = (bundle?: Bundle): SummaryBlock[] => {
    if (!bundle) {
      return [];
    }

    const blocks: SummaryBlock[] = [
      {
        title: "Version",
        contents: (
          <Indent>
            <ASChip label={bundle?.version} />
          </Indent>
        ),
      },
      {
        title: "Tags",
        contents: (
          <Indent>
            {bundle?.tags.map((tag) => {
              return <StyledChip label={tag} />;
            })}
          </Indent>
        ),
      },
      {
        title: "Dataset",
        contents: (
          <Indent>
            <ASEasyTable
              tableData={[
                {
                  colName: "TrainDataset",
                  colValue: `${
                    trainingDatasetCount.state === "hasValue"
                      ? trainingDatasetCount.contents
                      : "--"
                  }件`,
                },
                {
                  colName: "TestDataset",
                  colValue: `${
                    testDatasetCount.state === "hasValue"
                      ? testDatasetCount.contents
                      : "--"
                  }件`,
                },
              ]}
            />
          </Indent>
        ),
      },
      {
        title: "Parameters",
        contents: (
          <Indent>
            <ASEasyTable
              tableData={
                aiConfigLoadable.state === "hasValue" &&
                aiConfigLoadable.contents
                  ? Object.entries(
                      aiConfigLoadable.contents.hyperParameters
                    ).map(([key, value]) => {
                      return { colName: key, colValue: String(value) };
                    })
                  : []
              }
            />
          </Indent>
        ),
      },
    ];

    return blocks;
  };

  return (
    <Root>
      <MDStickyHeader
        title={`${bundleLoadable.contents?.name ?? "---"}`}
        backPage
        headerContents={renderHeader()}
      />
      <Body>
        <LeftPanel>
          <CHSettingNav
            conf={createNAISettingNavConf(bundleId ?? "", version)}
          />
        </LeftPanel>
        <CenterPanel>{props.children}</CenterPanel>

        <RightPanel>
          {bundleLoadable.state === "hasValue" ? (
            <NASummaryCard
              summary={{
                ...createBundleSummaryConf(
                  createBundle(bundleLoadable.contents)
                ),
                blocks: createBundleBlocks(bundleLoadable.contents),
              }}
            />
          ) : (
            <ASLoadingCircle isLoading />
          )}
        </RightPanel>
      </Body>
      <ASModal open={commiModalShow} onClose={() => setCommitModalShow(false)}>
        <ASLowCodeFormContainer
          formDefinition={commitNAIFormConf}
          onSubmit={async (data, options) => {
            const newVersion = data.newVersion;
            const comment = data.comment;
            handleCommit(newVersion, comment);
          }}
        />
      </ASModal>
    </Root>
  );
};
