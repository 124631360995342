import { useCallback } from "react";
import { api, createnXtalAPI } from "src/api";
import { useRecoilState, useRecoilValue } from "recoil";
import { userState } from "src/root/recoils/userState";
import { cursorPipelineIdState, slotRequestId } from "../recoils";

export const useSlotList = () => {
  const auth = useRecoilValue(userState);
  const [slotReqId, setSlotReqId] = useRecoilState(slotRequestId);
  const cursorPipelineId = useRecoilValue(cursorPipelineIdState);
  // const cursorPipeline = useRecoilValueLoadable(cursorPipelineState);

  const nxtal = createnXtalAPI();
  const contractor: string =
    typeof auth.contractorNo === "string" ? auth.contractorNo : "";
  const apiKey: string = typeof auth.token === "string" ? auth.token : "";

  /**
   * Slotを作成する
   */
  const add = useCallback(async () => {
    //@todo url の環境変数化

    const res = await nxtal.xervice.add(contractor, cursorPipelineId);

    if (res.status === 200) {
      setSlotReqId(slotReqId + 1);
    }
  }, [contractor, cursorPipelineId, nxtal.xervice, setSlotReqId, slotReqId]);

  const updateIO = useCallback(
    async (slotId, inputs, outputs) => {
      const res = await api({
        method: "PUT",
        url: `/contractors/${contractor}/slot/${slotId}`,
        params: {
          apikey: apiKey,
        },
        data: {
          parentPipelineId: cursorPipelineId,
          inputs: inputs,
          outputs: outputs,
        },
      });
      console.log(res.data);
      setSlotReqId(slotReqId + 1);
    },
    [apiKey, contractor, cursorPipelineId, setSlotReqId, slotReqId]
  );

  const reloadList = () => {
    setSlotReqId(slotReqId + 1);
  };

  // スロットリストが更新されないのでページに直書き
  // const deleteSlot = useCallback(
  //   async (slot: Slot | undefined) => {
  //     if (slot !== undefined) {
  //       try {
  //         const contractor: string =
  //           typeof auth.contractorNo === "string" ? auth.contractorNo : "";

  //         const apiKey: string =
  //           typeof auth.token === "string" ? auth.token : "";
  //         const res = await api({
  //           method: "DELETE",
  //           url: `/contractors/${contractor}/slot/${slot.id}`,
  //           params: {
  //             apikey: apiKey,
  //           },
  //         });
  //         setSlotReqId(slotReqId + 1);
  //       } catch (err) {
  //         console.error(err);
  //       }
  //     }
  //     setSlotReqId(slotReqId + 1);
  //   },
  //   [auth.contractorNo, auth.token, setSlotReqId, slotReqId]
  // );
  return {
    add,
    updateIO,
    reloadList,
  };
};
