//Libs
import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { createnXtalAPI } from "src/api";
import { syncSlotEffects, statusEffectByRest } from "../effects/slotEffect";

//Components

//States
import { authState, userState } from "src/root/recoils/userState";

//Types
import { createSlot, Slot, defaultSlot } from "../models";
import { cursorPipelineIdState } from ".";
import { defaultOptXConf, OptXConf } from "allegro-api";
import { getCurrentContainerStats } from "../api/server";
import {
  ContainerStats,
  defaultContainerStats,
} from "../models/ContainerStats";
import { SlotFilterType } from "../types/slotFilterType";

export const slotBrowserPageIdState = atom({
  key: "slotBrowserPageIdState",
  default: 0,
});

export const slotBrowserPerPageNumState = atom({
  key: "slotBrowserPerPageNumState",
  default: 50,
});

export const slotBrowserFilterState = atom<SlotFilterType>({
  key: "slotBrowserFilterState",
  default: "All",
});

export const slotState = atomFamily<Slot, string>({
  key: "slotState",
  default: defaultSlot,
  effects: syncSlotEffects,
});

export const slotStatusState = atomFamily<
  "init" | "stopped" | "starting" | "running" | "stopping",
  string
>({
  key: "slotStatusState",
  default: "init",
  effects: (slotId: string) => [
    // statusEffect(slotId),
    statusEffectByRest(slotId),
  ],
});

export const slotIdsState = selectorFamily<string[], string>({
  key: "SlotIds",
  get:
    (pipelineId) =>
    async ({ get }) => {
      //@todo url の環境変数化
      //@todo 件数の指定(limit)
      get(slotRequestId);

      const nxtal = createnXtalAPI();
      const params = {
        pipelineId: pipelineId,
      };
      const slots = await nxtal.xervice.getSlots(params);

      if (Array.isArray(slots)) {
        const result = slots.map((data: any) => {
          return data.slotId;
        });
        return result;
      }
      return [];
    },
});

export const slotSearchFieldTextState = atom({
  key: "slotSearchFieldTextState",
  default: "",
});

export const slotListBrowserPageState = atom({
  key: "slotListBrowserPageState",
  default: 1,
});

const SLOT_LIST_LIMIT = 100;
/**
 * 消す
 * @deperecated
 */
export const slotListState = selector<Array<Slot>>({
  key: "SlotListState",
  get: async ({ get }) => {
    const filterType = get(slotBrowserFilterState);

    const pipelineId = get(cursorPipelineIdState);
    const searchWord = get(slotSearchFieldTextState);
    const page = get(slotListBrowserPageState);
    const searchFilters = get(slotSearchFilterState);

    get(slotRequestId);
    get(userState);

    let where: any = {
      ...searchFilters,
    };
    if (searchWord) {
      where = {
        ...where,

        AND: [
          { OR: where.OR },
          {
            OR: [
              { name: { contains: searchWord } },
              { serverId: { contains: searchWord } },
            ],
          },
        ],
      };
    }

    const params = {
      pipelineId: filterType === "All" ? undefined : pipelineId,
      limit: SLOT_LIST_LIMIT,
      skip: SLOT_LIST_LIMIT * (page - 1),
      where: where,
    };

    const nxtal = createnXtalAPI();
    const slots = await nxtal.xervice.getSlots(params);

    if (Array.isArray(slots)) {
      const nextList: Array<Slot> = slots.map((data: any) => {
        return createSlot(data);
      });
      return nextList;
    }
    return [];
  },
});

export const slotRequestId = atom({
  key: "SlotRequestId",
  default: 0,
});

/**
 * 現在選択されているSlotId
 */
export const cursorSlotIdState = atom<string>({
  key: "CursorSlotIdState",
  default: "",
});

/**
 * 現在選択されているSlotType
 */
export const cursorSlotTypeState = atom<string>({
  key: "CursorSlotTypeState",
  default: "",
});

export const slotSearchFilterState = atom<any>({
  key: "slotSearchFilterState",
  default: {},
});

// export const cursorSlotState = selector<Slot>({
//   key: "CursorSlotState",

// });

/**
 * アプリで現在選択されているSlotを取得する
 * @deprecated unused
 */
export const cursorSlotState = selector<Slot>({
  key: "cursorSlotState",
  get: ({ get }) => {
    let result: Slot = defaultSlot;
    const cursorId = get(cursorSlotIdState);
    result = get(slotState(cursorId));
    return result;
  },
});

/**
 * 現在のスロットに設定されているOptXのコンフィグファイルを取得する
 * @deprecated unused
 */
export const cursorSlotConfFile = selector<OptXConf>({
  key: "cursorSlotConfFileState",
  get: ({ get }) => {
    //@todo
    let result = defaultOptXConf;
    return result;
  },
});

export const currentSlotStatsState = selectorFamily<ContainerStats, string>({
  key: "currentSlotStatsState",
  get:
    (key) =>
    async ({ get }) => {
      const auth = get(authState);
      if (!auth.token) {
        return defaultContainerStats;
      }
      const statsData = await getCurrentContainerStats(key, auth.token);

      return statsData ?? defaultContainerStats;
    },
});
