import LCAIResultCellComponent from "./LCAIResultCellComponent";
import LCApprovalChipCellComponent from "./LCApprovalChipCellComponent";
import LCArrowLinkCellComponent from "./LCArrowLinkCellComponent";
import LCAssessmentCellComponent from "./LCAssessmentCellComponent";
import LCBudgeCollectionComponent from "./LCBudgeCollectionComponent";
import { LCCheckCellComponent } from "./LCCheckCellComponent";
import LCDeleteButtonComponent from "./LCDeleteAssetButton";
import LCHumanNameThumbComponent from "./LCHumanNameThumbComponent";
import LCIconLinkCellComponent from "./LCIconLinkCellComponent";
import LCNumberChipCellComponent from "./LCNumberChipCellComponent";
import LCPercentageCellComponent from "./LCPercentageCellComponent";
import LCSecretCellComponent from "./LCSecretCellComponent";
import LCSimpleColComponent from "./LCSimpleColComponent";
import LCTextLinkCellComponent from "./LCTextLinkCellComponent";
import LCUnixTimeCellComponent from "./LCUnixTimeCellComponent";
import LCWatchCellComponent from "./LCWatchCellComponent";
import LCNameThumbComponent from "./LCNameThumbComponent";
import { LCUserIconComponent } from "./LCUserIconComponent";
import LCServerStatusComponent from "./LCServerStatusCellComponent";
import { ComponentTypes } from "../../types/LowCodeUiDefinition";

export const TableComponents: {
  [key in ComponentTypes]: any;
} = {
  LCHumanNameThumbComponent,
  LCSimpleColComponent,
  LCBudgeCollectionComponent,
  LCAssessmentCellComponent,
  LCAIResultCellComponent,
  LCWatchCellComponent,
  LCDeleteButtonComponent,
  LCTextLinkCellComponent,
  LCUnixTimeCellComponent,
  LCPercentageCellComponent,
  LCSecretCellComponent,
  LCArrowLinkCellComponent,
  LCCheckCellComponent,
  LCNumberChipCellComponent,
  LCApprovalChipCellComponent,
  LCIconLinkCellComponent,
  LCNameThumbComponent,
  LCUserIconComponent,
  LCServerStatusComponent
};

