import { ASUserIcon } from "allegro-ui";
import styled from "styled-components";
import { useUserIconComponent } from "../../hooks/useUserIcon";
import { Tooltip } from "@material-ui/core";

type Props = {
  data: {
    userNo: string;
  };
};

const Container = styled.div`
  display: inline-block;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
`;

export const LCUserIconComponent: React.FC<Props> = (props) => {
  const { data } = props;

  const { userRealName, avatar, isLoading } = useUserIconComponent(data.userNo);

  return (
    <Container>
      {!isLoading ? (
        <Tooltip key={`lc-user-icon-tooltip`} title={userRealName ?? "aaaaa"}>
          <div>
            <ASUserIcon userName={userRealName} avatar={avatar} />
          </div>
        </Tooltip>
      ) : null}
    </Container>
  );
};
