import moment from "moment";
import { useState, useCallback, useEffect } from "react";
import {
  useRecoilValueLoadable,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { useMyRole } from "src/portal/hooks/useMyRole";
import {
  currentSlotStatsState,
  cursorPipelineIdState,
  cursorSlotIdState,
} from "../recoils";
import { createContainerName } from "./useDockerStatsViewer";
import { useSlotActions } from "./useSlot";
import { usePipeline } from "./usePipeline";
import { numToBytesSI, numToPercentage } from "../utils/parseStats";
import { serverListState } from "../recoils/serverState";
import { useSwitchOptX } from "./useSwitchOptX";

export const useSlotTableRow = (slotId: string) => {
  const role = useMyRole();
  const slot = useSlotActions(slotId);
  const optx = useSwitchOptX(slot.optXId ?? "");

  const { pipeline } = usePipeline(slot.pipelineId);

  const containerName = createContainerName(slot);

  const currentStatsLoadable = useRecoilValueLoadable(
    currentSlotStatsState(containerName)
  );
  const cursorPipelineId = useRecoilValue(cursorPipelineIdState);
  const setCursorSlotId = useSetRecoilState(cursorSlotIdState);
  const serverListLoadable = useRecoilValueLoadable(serverListState);

  const [canEditPipeline, setCanEditPipeline] = useState(false);

  const udpatePipelineEditableState = useCallback(async () => {
    const ok = await role.canUpdatePipeline(cursorPipelineId);
    setCanEditPipeline(ok);
  }, [cursorPipelineId, role]);

  useEffect(() => {
    udpatePipelineEditableState();
  }, [udpatePipelineEditableState]);

  const server =
    serverListLoadable.state === "hasValue"
      ? serverListLoadable.contents.find((value) => {
          return [
            value.contents.rawdata.non_numeric_ip1,
            value.contents.rawdata.non_numeric_ip2,
            value.contents.rawdata.non_numeric_ip3,
          ].includes(slot.ip);
        })
      : null;

  const cpuPercentage =
    currentStatsLoadable.state === "hasValue"
      ? numToPercentage(currentStatsLoadable.contents.cpuUsage ?? 0)
      : "-";

  const memoryUsage =
    currentStatsLoadable.state === "hasValue"
      ? numToPercentage(currentStatsLoadable.contents.memoryUsage ?? 0)
      : "-";

  const diskUsage =
    currentStatsLoadable.state === "hasValue"
      ? numToBytesSI(currentStatsLoadable.contents.diskUsage ?? 0)
      : "-";

  const createdTime =
    currentStatsLoadable.state === "hasValue"
      ? moment(currentStatsLoadable.contents.created).fromNow()
      : "-";

  return {
    slot,
    optx,
    server,
    pipeline,
    canEditPipeline,
    cpuPercentage,
    memoryUsage,
    diskUsage,
    createdTime,
    setCursorSlotId,
  };
};
