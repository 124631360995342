import React from "react";
import { LCListViewTemplate } from "src/cheetah/components/libs/ASLowCodeBrowser";
import {
  CHTabComponentProps,
  CHTabs,
} from "src/cheetah/components/molecules/CHTabs";
import { serverCreatingFormDefinition } from "src/cheetah/definitions/forms/serverCreatingFormDefinition";
import { createServerBroweserUi } from "src/cheetah/definitions/listPages/serverBrowserUi";
import { useInfrastractureViewer } from "src/newtroid-console/hooks/useInfrastructureViewer";
import { XRPageTemplate } from "src/xerver-room/components/templates/XRPageTemplate";
import { NTRegistryListTemplate } from "./NTRegistryListTemplate";

type Props = {};

export const NTInfrastructurePageTemplate: React.FC<Props> = (props) => {
  const { canCreateServer, canDeleteServer } = useInfrastractureViewer();

  //
  // ___________________________________________________
  // EDIT HERE
  //
  const tabs: CHTabComponentProps[] = [
    {
      label: "Servers",
      component: (
        <LCListViewTemplate
          name="nt-servers-page"
          browserUi={createServerBroweserUi(canDeleteServer)}
          formUi={serverCreatingFormDefinition}
          disabledCreate={!canCreateServer}
          createModalTitle="New Server"
          createButtonText="New Server"
        />
      ),
    },
    {
      label: "Registry",
      component: <NTRegistryListTemplate />,
    },
  ];
  //
  // ___________________________________________________
  //

  return (
    <XRPageTemplate title="Infrastructure">
      <CHTabs id="nt-infrastructure-page" tabs={tabs} disableBack />
    </XRPageTemplate>
  );
};
