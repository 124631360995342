import { atom, selector } from "recoil";
import { Bundle } from "allegro-api";
import { findBundle } from "../api/findBundle";
import { newtOut } from "src/medican/api";
import consts from "../consts";

export const cursorBundleIdState = atom<string | undefined>({
  key: "cursorBundleIdState",
  default: undefined,
});

export const cursorBundleVersionState = atom<string>({
  key: "cursorBundleVersionState",
  default: "latest",
});

export const cursorBundleState = selector<Bundle | undefined>({
  key: "cursorBundleState",
  get: async ({ get }) => {
    const bundleId = get(cursorBundleIdState);
    const version = get(cursorBundleVersionState);

    if (!bundleId) return;

    const bundle = await findBundle(bundleId, version);
    return bundle;
  },
});

export const cursorBundleVersionsState = selector<
  { value: string; label: string }[]
>({
  key: "cursorBundleVersionsState",
  get: async ({ get }) => {
    const bundleId = get(cursorBundleIdState);

    const newtQuery = {
      assetId: consts.assetIds.bundle,
      search: {
        "contents.rawdata.bundleId": bundleId,
      },
      limit: 10,
      shape: {
        "contents.rawdata.version": "any",
        _created: "any",
      },
      order: {
        _created: 1,
      },
    };

    const [data, _] = await newtOut(newtQuery);
    const versions = data.map((d: any) => {
      const version = d.contents.rawdata.version;
      return { label: version, value: version };
    });

    return versions;
  },
});
