import { RegistryInput } from "allegro-api";
import { ASLowCodeFormDefinition } from "allegro-low-code-components";
import { createnXtalAPI } from "src/api";
import { fetchGroupMany } from "src/portal/api/fetchGroup";
import { fetchUsersByEmail } from "src/portal/api/fetchUser";
import { activityLogger } from "src/xerver-room/activity-logger";
import { detectObjectChanges } from "src/xerver-room/utils/detectObjectChanges";

export const createRegistryFormDefinition = (
  registryId?: string,
  defaultValues?: { [key: string]: any }
): ASLowCodeFormDefinition => {
  return {
    sections: [
      {
        fields: [
          {
            label: "Name",
            name: "name",
            type: "text",
            required: true,
            errorText: "Name is required.",
          },
          {
            label: "Host",
            name: "host",
            type: "text",
            required: true,
            errorText: "Host is required.",
          },
          {
            label: "Version",
            name: "version",
            type: "text",
            required: false,
          },
          {
            label: "Registry Credential",
            name: "credential",
            type: "section",
          },
          {
            label: "User Name",
            name: "userName",
            type: "text",
            required: false,
            errorText: "User name is required.",
          },
          {
            label: "Password",
            name: "password",
            type: "secret",
            required: false,
            errorText: "Password is required.",
          },

          {
            label: "Security Setting",
            name: "settings",
            type: "section",
          },
          {
            label: "shareLevel",
            name: "shareLevel",
            type: "radio",
            values: [
              {
                label: "公開",
                value: "public",
              },
              {
                label: "非公開",
                value: "private",
              },
            ],
            active: "private",
          },
          {
            label: "Readable Users",
            name: "allowReadUsers",
            type: "chip",
            required: true,
            errorText: "readable users are required.",
            active: ["*"],
            values: async (searchText) => {
              if (searchText === "*") {
                return [];
              }
              const users = await fetchUsersByEmail(searchText ?? "");
              return users.map((user) => {
                return {
                  label: user.email,
                  value: user.userNoText,
                };
              });
            },
          },
          {
            label: "Readable Groups",
            name: "allowReadGroups",
            type: "chip",
            required: false,
            values: async (searchText) => {
              if (searchText === "*") {
                return [];
              }
              const searchQuery = {
                $or: [
                  { "contents.rawdata.group_name": searchText },
                  { "contents.rawdata.group_id": searchText },
                ],
              };
              const groups = await fetchGroupMany({ matchQ: searchQuery });
              return groups.map((group) => {
                return {
                  label: group.group_name,
                  value: group.group_id,
                };
              });
            },
          },
          {
            label: "Writable Users",
            name: "allowWriteUsers",
            type: "chip",
            required: true,
            active: ["*"],
            errorText: "writable users are required.",
            values: async (searchText) => {
              if (searchText === "*") {
                return [];
              }
              const users = await fetchUsersByEmail(searchText ?? "");
              return users.map((user) => {
                return {
                  label: user.email,
                  value: user.userNoText,
                };
              });
            },
          },
          {
            label: "Writable Groups",
            name: "allowWriteGroups",
            type: "chip",
            required: false,
            values: async (searchText) => {
              if (searchText === "*") {
                return [];
              }
              const searchQuery = {
                $or: [
                  { "contents.rawdata.group_name": searchText },
                  { "contents.rawdata.group_id": searchText },
                ],
              };
              const groups = await fetchGroupMany({ matchQ: searchQuery });
              return groups.map((group) => {
                return {
                  label: group.group_name,
                  value: group.group_id,
                };
              });
            },
          },
        ],
      },
    ],
    buttonOptions: {
      submitText: "Create",
      updateText: "Update",
    },
    onSubmit: async (data, options) => {
      const {
        userName,
        password,
        name,
        host,
        version,
        shareLevel,
        allowReadUsers,
        allowReadGroups,
        allowWriteUsers,
        allowWriteGroups,
      } = data;

      const nxtal = createnXtalAPI();

      const registryInput: RegistryInput = {
        name,
        host,
        version,
        shareLevel,
        allowReadUsers,
        allowReadGroups,
        allowWriteUsers,
        allowWriteGroups,
        credential: {
          userName,
          password,
        },
      };

      if (options?.isUpdate) {
        if (!registryId) {
          throw Error("registryId is undefined");
        }
        const res = await nxtal.registry.updateOne(registryId, registryInput);
        if (res) {
          const diffs = defaultValues
            ? detectObjectChanges(defaultValues, data, Object.keys(data))
            : {};
          const message =
            diffs && Object.keys(diffs).length > 0
              ? `Registry:${registryId}の${Object.keys(diffs).join(
                  "、"
                )}を編集しました。`
              : `Registry:${registryId}を編集しました。`;

          await activityLogger.saveRegistryActivity(
            "info",
            message,
            registryId
          );

          // window.location.reload();
        } else {
          throw Error("Failed to update registry");
        }
      } else {
        const res = await nxtal.registry.add(registryInput);

        if (res) {
          await activityLogger.saveRegistryActivity(
            "info",
            `Registry:${res.registryId}を新規登録しました。`,
            res.registryId
          );

          window.location.reload();
        } else {
          throw Error("Failed to create registry");
        }
      }
    },
  };
};
