import { ASBrowserUiConf } from "src/cheetah/components/libs/ASLowCodeBrowser";
import { newtOut } from "src/medican/api";
import consts from "src/nai-console/consts";

export const nAIBrowserUi: ASBrowserUiConf = {
  newtQ: {
    assetId: consts.assetIds.bundle,
    search: {
      "contents.rawdata.isDefault": true,
    },
    shape: {
      "contents.rawdata": "any",
      bases: "any",
    },
  },
  tables: [
    {
      label: "Name",
      component: "LCNameThumbComponent",
      data: {
        title: "contents.rawdata.name",
        subTitle: "contents.rawdata.slug",
        id: "contents.rawdata.bundleId",
        version: "contents.rawdata.version",
      },
      options: {
        path: "/ai/{id}?version={version}",
      },
    },
    {
      label: "Version",
      component: "LCBudgeCollectionComponent",
      data: {
        values: "contents.rawdata.version",
        kind: "positive",
      },
      options: {},
    },
    {
      label: "Tags",
      component: "LCBudgeCollectionComponent",
      data: {
        values: "contents.rawdata.tags",
        kind: "positive",
      },
      options: {},
    },
    {
      label: "ID",
      component: "LCSimpleColComponent",
      data: {
        value: "contents.rawdata.bundleId",
      },
      options: {
        fontSize: 8,
      },
    },
    {
      label: "Owner",
      component: "LCUserIconComponent",
      data: {
        userNo: "bases.userNo",
      },
      options: {},
    },
  ],
  filters: [
    {
      label: "Filters",
      design: "checkbox",
      selections: async () => {
        const newtQuery = {
          assetId: "NAI-FILTER-TAGS",
          limit: 100,
          shape: {
            "contents.rawdata": "any",
          },
        };
        try {
          const [tagsRes] = await newtOut(newtQuery);
          return tagsRes.map((tag: any) => {
            return {
              label: tag.contents.rawdata.label,
              value: tag.contents.rawdata.value,
            };
          });
        } catch (err) {
          console.error(err);
        }

        return [];
      },
      callback: (value: string) => {
        if (value)
          return {
            $match: {
              "contents.rawdata.tags": value,
            },
          };
        else return undefined;
      },
      action: {
        label: "add Tag",
        type: "create",
        onValid: (role) => {
          return true;
        },
      },
    },
  ],
  verticalFilters: [
    {
      label: "Search",
      design: "search",
      selections: [],
      callback: (value: string) => {
        return {
          $or: [
            {
              "contents.rawdata.name": {
                $regex: value,
                $options: "i",
              },
            },
            {
              "contents.rawdata.slug": {
                $regex: value,
                $options: "i",
              },
            },
          ],
        };
      },
    },
  ],
  actions: [
    {
      label: "Create",
      type: "create",
      onValid: (role) => {
        return true;
      },
    },
  ],
};
