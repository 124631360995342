import { atom, selector } from "recoil";
import { createUser } from "../models/user";
import Cookies from "js-cookie";
import { User } from "allegro-api";
import { SESSION_EXPIRE_MS } from "src/consts/session";

/**
 * @deprecated allegro-api-hooksへ移行
 */
export type AuthState = {
  userNo: number | null;
  userNoText: string | null;
  token: string | null;
  username: string | null;
  contractorNo: string | undefined | null;
  userId: string | null;
  userNameText: string | null;
  roles: string | null;
  groups: string | null;
  userRealName: string | null;
  avatar: string | null;
  email: string | null;
  phoneNumber: string | null;
  zipcode: string | null;
  address: string | null;
  expired: number | null;
};

// const defaultState: State = {
//   id: sessionStorage.getItem("userId") || "",
//   pass: "",
//   userNoText: sessionStorage.getItem("userNoText") || "",
//   contractorNo: sessionStorage.getItem("contractorNo") || "",
//   token: sessionStorage.getItem("token") || "",
//   userRealName: sessionStorage.getItem("userRealName") || "",
// };

/**
 * @deprecated allegro-api-hooksへ移行
 */
export const authState = atom<AuthState>({
  key: "authState__DEPRECATED",
  default: {
    userNo: null,
    userNoText: null,
    username: null,
    token: null,
    contractorNo: null,
    userId: null,
    userNameText: null,
    roles: null,
    groups: null,
    userRealName: null,
    avatar: null,
    email: null,
    phoneNumber: null,
    zipcode: null,
    address: null,
    expired: null,
  },
  effects: [
    ({ onSet, trigger, setSelf }) => {
      if (trigger === "get") {
        const newValue = {
          userNo: Cookies.get("userNo") ? Number(Cookies.get("userNo")) : null,
          userNoText: Cookies.get("userNoText") ?? null,
          username: Cookies.get("username") ?? null,
          token: Cookies.get("token") ?? null,
          contractorNo: Cookies.get("contractorNo") ?? null,
          userId: Cookies.get("userNoText") ?? null,
          userNameText: Cookies.get("userNameText") ?? null,
          roles: Cookies.get("roles") ?? null,
          groups: Cookies.get("groups") ?? null,
          userRealName: Cookies.get("userRealName") ?? null,
          avatar: window.localStorage.getItem("avatar") ?? null,
          email: Cookies.get("email") ?? null,
          phoneNumber: Cookies.get("phoneNumber") ?? null,
          zipcode: Cookies.get("zipcode") ?? null,
          address: Cookies.get("address") ?? null,
          expired: Number(window.localStorage.getItem("expired")) ?? null,
        };

        setSelf(newValue);

        for (const [key, value] of Object.entries(newValue)) {
          if (value && key !== "avatar")
            sessionStorage.setItem(key, value.toString());
        }
      }

      onSet((newValue) => {
        // 有効期限の単位を分から日に換算する 例. 60分 -> 1/24日
        const expireDay = new Date(new Date().getTime() + SESSION_EXPIRE_MS);
        for (const [key, value] of Object.entries(newValue)) {
          if (value) {
            // TODO localStrageでアバターを保存しているが、実装的に良くないためAPIを使う予定
            if (key === "avatar" || key === "expired") {
              window.localStorage.setItem(key, value.toString());
            } else {
              Cookies.set(key, value.toString(), { expires: expireDay });
            }
          } else {
            if (key === "avatar" || key === "expired") {
              window.localStorage.removeItem(key);
            } else {
              Cookies.remove(key);
            }
          }
        }
      });

      /**
       * @deprecated
       * 下位互換 - sessionStorageはCookieに置き換え
       */
      onSet((newValue) => {
        for (const [key, value] of Object.entries(newValue)) {
          if (value) sessionStorage.setItem(key, value.toString());
        }
      });
    },
  ],
});

/**
 * @deprecated authStateをかわりに使用してください。
 */
export const userState = authState;

/**
 * @deprecated allegro-api-hooksへ移行
 */
export const myProfileState = selector<User>({
  key: "myProfileState__DEPRECATED",
  get: async ({ get }) => {
    const auth = get(authState);

    return createUser({
      userNoText: auth.userNoText,
      token: auth.token ?? undefined,
      username: auth.username ?? undefined,
      profile: {
        userRealName: auth.userRealName,
        apiKey: auth.token ?? "",
      },
      contractorNo: auth.contractorNo ?? undefined,
      avatar: auth.avatar ?? undefined,
      email: auth.email ?? undefined,
      phoneNumber: auth.phoneNumber ?? undefined,
      zip: auth.zipcode ?? undefined,
      address: auth.address ?? undefined,
    });
  },
});
