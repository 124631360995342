import React, { useState } from "react";
import styled from "styled-components";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { CardContent, Menu, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";

import { PipelineX } from "allegro-api";

//Libs
//Components
import { ASModal, ASIconButton, ASCard, ASNoDataPlaceHolder } from "allegro-ui";
import { RenamePipelineModal } from "../organisms/RenamePipelineModal";
import { LCUserIconComponent } from "src/cheetah/components/libs/ASLowCodeBrowser/components/LCTableColumnComponents/LCUserIconComponent";
import { decodeUserNoText } from "src/fortress/utils/userNoEdit";
import { usePipelineXPermissionControl } from "src/xerver-room/hooks/usePipelinePermissionControl";

//States
//Types

const CardHead = styled.div`
  display: flex;
  justify-content: space-between;
  h1 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.4;
    text-decoration: none;
    color: #ffffff;
    :hover {
      color: #afafaf;
      transition: all 0.2s 0s ease;
    }
  }
`;

const StyledCard = styled(CardContent)`
  height: 180px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Caption = styled.div`
  margin-top: 8px;
  font-size: 12px;
  color: #8d8d8d;
`;

const CardFooter = styled.div`
  display: flex;
  flex-direciton: row;
  align-items: center;
`;

const Author = styled(Caption)`
  margin-left: auto;
`;

const NotAuthorized = styled(ASNoDataPlaceHolder)`
  height: 100%;
`;

type Props = {
  pipeline: PipelineX;
  handleDeleteModal: (pipeline: PipelineX) => any;
};

export const PipelineCard: React.FC<Props> = (props) => {
  const { pipeline } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const { canEditPipeline } = usePipelineXPermissionControl(props.pipeline);

  const [showRenameModal, setShowRenameModal] = useState(false);

  const handleMenuOpen = (event: React.ChangeEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ASCard>
        <StyledCard>
          <CardHead>
            <div>
              <Link
                to={`/pipeline/${pipeline.id}`}
                style={{ textDecoration: "none" }}
              >
                <h1>{pipeline.name}</h1>
              </Link>
            </div>

            <div>
              <ASIconButton
                kind="secondary"
                onClick={handleMenuOpen}
                size="small"
              >
                <MoreHorizIcon />
              </ASIconButton>
            </div>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {canEditPipeline ? (
                <div>
                  <MenuItem onClick={() => setShowRenameModal(true)}>
                    名前を変更
                  </MenuItem>
                  <MenuItem onClick={() => props.handleDeleteModal(pipeline)}>
                    削除する
                  </MenuItem>
                </div>
              ) : (
                <MenuItem>
                  <NotAuthorized>権限がありません</NotAuthorized>
                </MenuItem>
              )}
            </Menu>
          </CardHead>

          <CardFooter>
            <Caption>{pipeline.id}</Caption>
            <Author>
              {/* Created by:{pipeline.createdBy} */}
              <LCUserIconComponent
                data={{
                  userNo: String(decodeUserNoText(pipeline.createdBy ?? "")),
                }}
              />
            </Author>
          </CardFooter>
        </StyledCard>
      </ASCard>

      {/* モーダル */}
      <ASModal open={showRenameModal} onClose={setShowRenameModal}>
        <RenamePipelineModal
          pipeline={pipeline}
          onCancel={() => setShowRenameModal(false)}
        ></RenamePipelineModal>
      </ASModal>
    </>
  );
};
