import { ASLowCodeFormDefinition } from "allegro-low-code-components";
import { createNewtZeroAPI } from "src/api";
import consts from "src/nai-console/consts";

export const createLabelFormUi: ASLowCodeFormDefinition = {
  sections: [
    {
      title: "",
      description: "",
      fields: [
        {
          label: "Label",
          name: "label",
          type: "text",
          required: true,
          errorText: "labelは必須です。",
        },
      ],
    },
  ],
  onSubmit: async (data, options) => {
    const newtzero = createNewtZeroAPI();
    try {
      const postData = {
        label: data.label,
        value: data.label,
      };
      const res = await newtzero.streamGate(
        consts.assetIds.aiFilterTags,
        postData,
        { upsert: true }
      );
      if (res?.status === 200) {
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
    }
  },
};
