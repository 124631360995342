import * as React from "react";
import styled from "styled-components";
import { Tooltip } from "@material-ui/core";
import { darken, lighten, opacify } from "polished";

import { ASChip } from "allegro-ui";

const Container = styled.div`
  margin-left: -24px;
  max-width: 30vw;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  line-height: 1.7;

  overflow-x: scroll;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
`;

const Layer = styled.div`
  margin-left: 24px;
`;

const Row = styled.div`
  text-align: left;
  min-height: 25px;
`;

const StyledChip = styled(ASChip)<{ fontSize?: number }>`
  margin: 4px 3px;
  font-size: ${(props) => props.fontSize ?? props.theme.regularFontSizeMd}px;
  background-color: ${(props) =>
    props.theme.darkMode
      ? darken(0.35, props.theme.primaryColor)
      : props.theme.primaryColor};
  color: ${(props) =>
    props.theme.darkMode
      ? props.theme.primaryColor
      : props.theme.primaryOnText};
  border: 1px solid ${(props) => props.theme.primaryColor};
  .MuiChip-deleteIcon {
    color: ${(props) =>
      props.theme.darkMode
        ? opacify(0.9, lighten(0.2, props.theme.primaryColor))
        : props.theme.primaryOnText};
    opacity: 0.3;
  }

  max-width: 250px;
`;

const Text = styled.div<{ rowLimit?: number }>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(props) => props.rowLimit};
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  overflow: hidden;
  overflow-wrap: anywhere;
`;

const Font = styled.span`
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#222")};
`;

export interface ILCSimpleColComponentProps {
  data: {
    value: any;
  };
  options: {
    arrayLimitSize?: number;
    fontSize?: number;
  };
}

const ArrayViewComponent: React.FC<{
  array: any[];
  limit?: number;
  fontSize?: number;
}> = (props) => {
  const array = props.array.slice(0, props.limit);
  return (
    <>
      {array.map((content, id) => {
        if (typeof content === "number") {
          return (
            <Tooltip title={content} placement="bottom">
              <StyledChip
                key={`json-key-val-indicator-${id}`}
                label={content}
                size="small"
                fontSize={props.fontSize}
              />
            </Tooltip>
          );
        } else if (typeof content === "string") {
          return (
            <Tooltip title={content} placement="bottom">
              <StyledChip
                key={`json-key-val-indicator-${id}`}
                label={content}
                size="small"
                fontSize={props.fontSize}
              />
            </Tooltip>
          );
        } else if (Array.isArray(content)) {
          return (
            <Text rowLimit={3} key={`json-key-val-indicator-${id}`}>
              <pre>{JSON.stringify(content, null, 2)}</pre>
            </Text>
          );
        } else {
          return (
            <Text rowLimit={4} key={`json-key-val-indicator-${id}`}>
              <pre>{JSON.stringify(content, null, 2)}</pre>
            </Text>
          );
        }
      })}
      <>{props.limit && props.array.length > props.limit ? <>...</> : <></>}</>
    </>
  );
};

const JSONRenderer: React.FC<{
  json: any;
  arrayLimitSize?: number;
  fontSize?: number;
}> = (props) => {
  const renderALayer = (
    json: { [key: string]: any } | any[] | number | string,
    arrayLimitSize?: number,
    fontSize?: number
  ) => {
    let result = [];
    //json配列の場合
    if (Array.isArray(json)) {
      result.push(
        <ArrayViewComponent
          key={`json-render-layer-${Math.random()}`}
          array={json}
          limit={arrayLimitSize}
          fontSize={fontSize}
        />
      );
    } else if (typeof json === "string") {
      const text = json !== "" ? json : "-";
      result.push(<Row key={`json-render-layer-${Math.random()}`}>{text}</Row>);
    } else if (typeof json === "number") {
      result.push(<Row key={`json-render-layer-${Math.random()}`}>{json}</Row>);
    } else {
      //Objectの場合
      result.push(
        <Text rowLimit={12} key={`json-render-layer-${Math.random()}`}>
          <pre>{JSON.stringify(json, null, 2)}</pre>
        </Text>
      );
    }
    return <Layer>{result}</Layer>;
  };
  return <>{renderALayer(props.json, props.arrayLimitSize, props.fontSize)}</>;
};

export default function LCSimpleColComponent(
  props: ILCSimpleColComponentProps
) {
  return (
    <Container>
      <Font>
        <JSONRenderer
          key={`lc-simple-col-${props.data.value}-${Math.random()}`}
          json={props.data.value ?? "-"}
          arrayLimitSize={props.options.arrayLimitSize}
          fontSize={props.options.fontSize}
        />
      </Font>
    </Container>
  );
}
