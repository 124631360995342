import { saveOptXActivity } from "./saveOptXActivity";
import { saveOptXVariationActivity } from "./saveOptXVariationActivity";
import { saveRegistryActivity } from "./saveRegistryActivity";
import { saveSlotActivity } from "./saveSlotActivity";
import { saveXerviceActivity } from "./saveXerviceActivity";

export const activityLogger = {
  saveSlotActivity,
  saveOptXActivity,
  saveOptXVariationActivity,
  saveXerviceActivity,
  saveRegistryActivity,
};
