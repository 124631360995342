import axios, { AxiosInstance } from "axios";
import {
  NewtQuery,
  OptXVariation as OptXVar,
  OptXVariationInput,
  createOptXVariationFromNewtData,
} from "../../models";

export const OptXVariation = (instance: AxiosInstance) => {
  const basePath = "/newtzero";

  const add = async (
    optXId: string,
    optXVariation: OptXVariationInput
  ): Promise<OptXVar | null> => {
    const path = basePath + `/optx/${optXId}/version`;
    try {
      const res = await instance.post(path, optXVariation);
      if (res.status === 200) {
        return createOptXVariationFromNewtData(res.data.message);
      }
    } catch (err) {
      const errMsg =
        axios.isAxiosError(err) && err.response?.data.message
          ? err.response.data.message
          : "Failed to create OptX version.";
      throw Error(errMsg);
    }

    return null;
  };

  const fetchOne = async (
    optXId: string,
    variationId: string
  ): Promise<OptXVar | null> => {
    const path = basePath + `/optx/${optXId}/version/${variationId}`;
    try {
      const res = await instance.get(path);
      if (res.status === 200) {
        return createOptXVariationFromNewtData(res.data.message);
      }
    } catch (err) {
      console.error(err);
    }
    return null;
  };

  /**
   * OptXVariationの一覧を取得する関数
   * @returns OptXvariationの一覧とデータ総件数を返却する
   */
  const fetchMany = async (
    optXId: string,
    options?: {
      limit?: number;
      skip?: number;
      count?: boolean;
      explain?: boolean;
    }
  ): Promise<[OptXVar[], number]> => {
    const path = basePath + `/optx/${optXId}/version`;
    try {
      const res = await instance.get(path, { params: options });
      if (res.status === 200) {
        return [
          res.data.message.map((data: { [key: string]: any }) => {
            return createOptXVariationFromNewtData(data);
          }),
          res.data.totalDataCount,
        ];
      }
    } catch (err) {
      console.error(err);
    }

    return [[], 0];
  };

  /**
   * OptXVariationの一覧を取得する関数、
   * DataProviderAPIでNewtQueryを使用してデータを取得する
   * @returns OptXvariationの一覧とデータ総件数を返却する
   */
  const dataProvider = async (
    optXId: string,
    newtQuery: NewtQuery,
    options?: { count?: boolean; explain?: boolean }
  ): Promise<[OptXVar[], number]> => {
    const path = basePath + `/optx/${optXId}/version/dataprovider`;
    try {
      const res = await instance.post(path, newtQuery, { params: options });
      if (res.status === 200) {
        return [
          res.data.message.map((data: { [key: string]: any }) => {
            return createOptXVariationFromNewtData(data);
          }),
          res.data.totalDataCount,
        ];
      }
    } catch (err) {
      console.error(err);
    }

    return [[], 0];
  };

  const updateOne = async (
    optXId: string,
    variationId: string,
    optXVariation: OptXVariationInput
  ): Promise<boolean> => {
    const path = basePath + `/optx/${optXId}/version/${variationId}`;
    try {
      const res = await instance.put(path, optXVariation);
      if (res.status === 200) {
        return true;
      }
    } catch (err) {
      const errMsg =
        axios.isAxiosError(err) && err.response?.data.message
          ? err.response.data.message
          : "Failed to create OptX version.";
      throw Error(errMsg);
    }
    return false;
  };

  const deleteOne = async (
    optXId: string,
    variationId: string
  ): Promise<boolean> => {
    const path = basePath + `/optx/${optXId}/version/${variationId}`;
    try {
      const res = await instance.delete(path);
      if (res.status === 200) {
        return true;
      }
    } catch (err) {
      console.error(err);
    }
    return false;
  };

  const freeze = async (
    optXId: string,
    variationId: string
  ): Promise<boolean> => {
    const path = basePath + `/optx/${optXId}/version/${variationId}/freeze`;
    try {
      const res = await instance.put(path);
      if (res.status === 200) {
        return true;
      }
    } catch (err) {
      console.error(err);
    }
    return false;
  };

  const unlockFreeze = async (
    optXId: string,
    variationId: string
  ): Promise<boolean> => {
    const path = basePath + `/optx/${optXId}/version/${variationId}/freeze`;
    try {
      const res = await instance.delete(path);
      if (res.status === 200) {
        return true;
      }
    } catch (err) {
      console.error(err);
    }
    return false;
  };

  return {
    add,
    fetchOne,
    fetchMany,
    dataProvider,
    updateOne,
    deleteOne,
    freeze,
    unlockFreeze,
  };
};
