import produce from "immer";
import { BasesSchema, defaultBasesSchema } from "./Base";

export type ActivityLevel =
  | "warn"
  | "error"
  | "info"
  | "http"
  | "verbose"
  | "debug"
  | "silly";
export type ActivityResource =
  | "optx"
  | "optx-variation"
  | "slot"
  | "xervice"
  | "registry"
  | "server"
  | "user"
  | "none";

export type ActivityInput = {
  level: ActivityLevel;
  message: string;
  tags: string[];

  resource?: ActivityResource;

  resourceId?: string;
};

export type Activity = ActivityInput & BasesSchema;

export const defaultActivity: Activity = {
  level: "debug",
  message: "",
  tags: [],
  ...defaultBasesSchema,
};

export const createActivityFromNewtData = (data: {
  [key: string]: any;
}): Activity => {
  return produce(defaultActivity, (draft) => {
    draft.level = data.contents.rawdata.level;
    draft.message = data.contents.rawdata.message;
    draft.tags = data.contents.rawdata.tags ?? draft.tags;
    draft.resource = data.contents.rawdata.resource;
    draft.resourceId = data.contents.rawdata.resourceId;

    draft.created = data.bases.created;
    draft.modified = data.bases.modified;
    draft.contractorNo = data.bases.contractorNo;
    draft.createdBy = Number(data.bases.userNo);
  });
};
