import produce from "immer";
import {
  InputConf,
  OutputConf,
  defaultInputConf,
  defaultOutputConf,
} from "./IO";

export type OptXEnvConf = {
  name: string;
  label: string;
  supportText?: string;
  required?: boolean;
  active?: any;
  errorText?: string;
  placeholder?: string;
};

export type OptXConf = {
  version: number;
  io: {
    inputs: InputConf[];
    outputs: OutputConf[];
  };

  environments: OptXEnvConf[];
  envMappings: { [key: string]: string };
};

export const defaultOptXConf: OptXConf = {
  version: 2,
  io: {
    inputs: [defaultInputConf],
    outputs: [defaultOutputConf],
  },

  environments: [],
  envMappings: {},
};

export const createOptXConf = (data: any) => {
  return produce(defaultOptXConf, (draft) => {
    draft.version = data.version ?? draft.version;

    const environments: OptXEnvConf[] = (
      data.environments ?? draft.environments
    ).map((environment: any) => {
      if (typeof environment === "string") {
        return {
          label: environment,
          name: environment,
          required: false,
        };
      } else {
        return environment;
      }
    });
    draft.environments = environments;

    const inputs =
      Array.isArray(data.io?.inputs) && data.io.inputs.length > 0
        ? data.io.inputs
        : [defaultInputConf];
    const outputs =
      Array.isArray(data.io?.outputs) && data.io.outputs.length > 0
        ? data.io.outputs
        : [defaultOutputConf];
    draft.io = {
      inputs,
      outputs,
    };

    draft.envMappings = data.envMappings ?? draft.envMappings;
  });
};
