import produce from "immer";

export type OptX = {
  optXId: string;
  name: string;
  description: string;
  parentSlotId: string;
  files: string[];
  buildStatus: "success" | "failed" | "none";
  version: string;
  buildHistory: any[];
  thumbnail: string;
  schemaType: "optxv1";
};

export const defaultOptX: OptX = {
  optXId: "",
  name: "",
  description: "",
  parentSlotId: "",
  files: [],
  buildStatus: "none",
  version: "",
  buildHistory: [],
  thumbnail: "",
  schemaType: "optxv1",
};

export const createOptX = (props: Partial<OptX>): OptX => {
  return produce(defaultOptX, (draft) => {
    draft.optXId =
      typeof props.optXId === "string" && props.optXId !== ""
        ? props.optXId
        : draft.optXId;
    draft.name =
      typeof props.name === "string" && props.name !== ""
        ? props.name
        : draft.name;
    draft.description =
      typeof props.description === "string" && props.description !== ""
        ? props.description
        : draft.description;

    draft.thumbnail = props.thumbnail ?? "";
    draft.parentSlotId =
      typeof props.parentSlotId === "string" && props.parentSlotId !== ""
        ? props.parentSlotId
        : draft.parentSlotId;
    draft.buildHistory = props.buildHistory ?? [];
  });
};
