import React, { useEffect } from "react";

import { Handle, Position, Node, NodeProps } from "react-flow-renderer";
import styled from "styled-components";
import { gridSize, gridOffset } from "../organisms/XRGridEditorView";
import { darken, transparentize } from "polished";
import { Slot } from "../../models";
import { useSlotActions } from "src/xerver-room/hooks";
import { Audio } from "react-loader-spinner";
import BlockIcon from "@material-ui/icons/Block";
import { useRecoilValue } from "recoil";
import { slotStatusState } from "src/xerver-room/recoils";
import { ASPictureIcon } from "allegro-ui";
import { LinkObject } from "src/xerver-room/hooks/useLinkObjectList";

type SlotProps = {
  selected: boolean;
  slotNum: number;
};

const Container = styled.div.attrs<SlotProps>((props) => ({}))<SlotProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  width: ${() => gridSize * 13 + gridOffset * 2}px;
  /* min-height: ${() => gridSize * 3 + gridOffset * 2}px; */
  min-height: ${(props) =>
    gridSize * Math.max(props.slotNum, 3) + 8 * (props.slotNum + 2)}px;

  border-top: 2px solid
    ${(props) => (props.selected ? props.theme.primaryColor : "#4c4c4c")};
  border-bottom: 2px solid
    ${(props) => (props.selected ? props.theme.primaryColor : "#3c3b3b")};
  border-right: 2px solid
    ${(props) => (props.selected ? props.theme.primaryColor : "#3d3d3d")};
  border-left: 2px solid
    ${(props) => (props.selected ? props.theme.primaryColor : "#585858")};

  border-radius: 20px;
  background: ${(props) => transparentize(0.1, props.theme.cardColor)};

  box-shadow: 5px 5px 31px #0e0e0e, -5px -5px 31px #202020;

  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); */
  /* > * {
    grid-row: 1 / -1;
    grid-column: 1 / -1;
  } */
`;

const SmallTitle = styled.div`
  margin-top: 4px;
  font-size: 16px;
  opacity: 0.6;
`;

type HandleProps = {
  kind: "http" | "tcp" | "default";
};
const StyledHandle = styled(Handle).attrs((props: HandleProps) => {
  const { kind } = props;
  const color =
    kind === "http" ? "#3bdf48" : kind === "tcp" ? "#ef9b2e" : "#c2c2c2";

  return { color };
})<HandleProps>`
  width: 16px;
  height: 16px;
  border: 2px solid ${(props) => props.color};
  background: ${(props) => darken(0.4, props.color)};
  margin: 4px 0;
`;

const StyledSlot = styled.div`
  text-align: left;

  padding: 18px 24px;

  > h1 {
    font-size: 12px;
    font-weight: bold;
  }
  > h3 {
    margin-top: 8px;
    margin-bottom: 3px;
    opacity: 0.1;
    font-size: 10px;
  }
  > span {
    font-size: 14px;
    color: #86e47d;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  > *:first-child {
    margin-right: 8px;
  }
`;

const StatusLayout = styled.div`
  position: absolute;
  right: 18px;
  bottom: 12px;
`;

const TitleLayout = styled.h2`
  display: flex;
  font-size: 20px;
  > *:first-child {
    margin-right: 8px;
  }
`;

const StopedIcon = styled(BlockIcon)`
  color: ${(props) => props.theme.textColor};
  opacity: 0.5;
  font-size: 17px;
`;

const DisabledIcon = styled(BlockIcon)`
  color: ${(props) => (props.theme.darkMode ? "#666" : "#999")};
  font-size: 17px;
`;

const DisabledText = styled.span`
  color: ${(props) => (props.theme.darkMode ? "#666" : "#999")};
`;

const IOWrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
`;

const InputLabel = styled.div`
  position: absolute;
  /* bottom: 12px; */

  right: 24px;
  transform: translateY(-12px);
  font-size: ${(props) => props.theme.labelFontSizeMd}px;
  color: #777;
`;

const OutputLabel = styled.div`
  position: absolute;
  left: 24px;
  transform: translateY(-12px);

  font-size: ${(props) => props.theme.labelFontSizeMd}px;
  color: #777;
`;

const LeftSideHandleArea = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  left: 0;
  top: 0;

  width: 16px;
`;
const RightSideHandleArea = styled.div`
  position: absolute;
  height: 100%;
  width: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  right: 0;

  top: 0;
`;

type NodeData = {
  slot?: Slot;
  linkObject?: LinkObject;
};

export type CustomNode = Node<NodeData>;

export const XRCustomNode: React.FC<CustomNode & NodeProps> = ({
  data,
  ...props
}) => {
  const { slot } = data;
  const { optX, addConnection, updatePosition } = useSlotActions(slot.slotId);

  const name = optX?.name;
  const thumbnail = optX?.thumbnail;

  const status = useRecoilValue(slotStatusState(slot.id));

  const slotCount = Math.max(slot.inputs.length, slot.outputs.length, 0);

  useEffect(() => {
    if (!props.dragging) {
      updatePosition(props.xPos, props.yPos);
    }
  }, [props.dragging, props.xPos, props.yPos, slot.slotId, updatePosition]);

  return (
    <Container selected={props.selected} slotNum={slotCount}>
      <LeftSideHandleArea>
        {slot.inputs.length > 0 ? (
          slot.inputs.map((input: any, i: number) => {
            return (
              <IOWrapper key={`key-io-${i}`}>
                <InputLabel>{input.name}</InputLabel>
                <StyledHandle
                  type="target"
                  position={"left" as Position}
                  isConnectable={true}
                  id={input.name}
                  style={{
                    position: "relative",
                    padding: 0,
                    lineHeight: 0,
                    top: 0,
                    left: -6,
                    transform: "translateY(0)",
                  }}
                  kind={input.type}
                />
              </IOWrapper>
            );
          })
        ) : (
          <></>
        )}
      </LeftSideHandleArea>

      <StyledSlot>
        <Header>
          {thumbnail ? (
            <div>
              <ASPictureIcon size={48} src={thumbnail} defaultSrc={thumbnail} />
            </div>
          ) : null}
          <TitleLayout>
            {slot.name ? (
              <>
                {name ? (
                  <span>
                    {slot.name} <SmallTitle>{name}</SmallTitle>
                  </span>
                ) : (
                  <DisabledText>{slot.name}(Empty)</DisabledText>
                )}
              </>
            ) : (
              <>
                {name ? (
                  <span>{name}</span>
                ) : (
                  <DisabledText>Empty</DisabledText>
                )}
              </>
            )}
          </TitleLayout>
        </Header>
      </StyledSlot>

      <StatusLayout>
        {status === "init" ? (
          <>...</>
        ) : status === "running" ? (
          <Audio height="16" width="16" color="#86e47d" ariaLabel="loading" />
        ) : name ? (
          <StopedIcon />
        ) : (
          <DisabledIcon />
        )}
      </StatusLayout>
      <RightSideHandleArea>
        {slot.outputs.length > 0 ? (
          slot.outputs.map((output: any, i: number) => {
            return (
              <IOWrapper key={`key-io-${i}`}>
                <OutputLabel>{output.name}</OutputLabel>
                <StyledHandle
                  type="source"
                  position={"right" as Position}
                  id={output.name}
                  onConnect={(params: any) => {
                    addConnection(params);
                  }}
                  style={{
                    position: "relative",
                    right: -6,
                    // margin: 0,
                    padding: 0,
                    lineHeight: 0,
                    top: 0,
                    transform: "translateY(0)",
                  }}
                  isConnectable={true}
                  kind={output.type}
                />
              </IOWrapper>
            );
          })
        ) : (
          <></>
        )}
      </RightSideHandleArea>

      {/* <StyledHandle
        type="source"
        position={"right" as Position}
        id="b"
        style={{ bottom: 10, top: "auto", background: "#555" }}
        isConnectable={true}
      /> */}
    </Container>
  );
};
