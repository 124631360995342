import React from "react";
import styled from "styled-components";
import { Tab, Box } from "@material-ui/core";
import { ASTabs } from "allegro-ui";
import { LCListViewTemplate } from "src/cheetah/components/libs/ASLowCodeBrowser";

import { serverPipelineBroweserUi } from "src/xerver-room/config/ui/serverPipelineBrowserUi";
import { XRXerviceBrowserContainer } from "./XRXerviceBrowserContainer";
import { createPipelineActivitiesBrowserUi } from "src/xerver-room/config/ui/pipelineActivitiesBrowserUi";

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const Header = styled.div`
  position: sticky;
  width: 100%;
  min-height: 45px;
  height: 45px;
  top: 0;
  z-index: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.theme.secondaryColor};
  border-top: 1px solid ${(props) => props.theme.secondaryBorderColor};
  border-bottom: 1px solid ${(props) => props.theme.secondaryBorderColor};
`;
const MainArea = styled.div`
  height: 100%;
  overflow-y: scroll;
  width: 100%;
`;

const ResizeInner1 = styled.div`
  flex-grow: 1;
  display: flex;
  min-height: 100px;
  flex-flow: column;
  overflow-y: auto;
  padding: 16px 24px;
`;

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

type Props = { pipelineId?: string };

export const XRBottomPanel: React.FC<Props> = (props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const tabs = [
    {
      label: "Xervice List",
      component: (
        <React.Suspense fallback={<>loading</>}>
          <XRXerviceBrowserContainer
            slotFilterType="ByPipeline"
            showSerialNum
          />
        </React.Suspense>
      ),
    },
    {
      label: "Servers",
      component: (
        <div>
          <LCListViewTemplate
            name="nt-server-list-pipeline-tab"
            browserUi={serverPipelineBroweserUi}
          />
        </div>
      ),
    },
    {
      label: "Activities",
      component: (
        <LCListViewTemplate
          name="nt-activity-list-tab"
          browserUi={createPipelineActivitiesBrowserUi(props.pipelineId ?? "")}
        />
      ),
    },
  ];
  return (
    <Container>
      <Header>
        <ASTabs
          value={value}
          onChange={handleChange}
          noround={true}
          tiny={true}
        >
          {tabs.map((tab, id) => {
            return (
              <Tab
                key={`xr-bottom-panel-tab-${tab.label}-${id}`}
                label={tab.label}
                {...a11yProps(id)}
              />
            );
          })}
        </ASTabs>
      </Header>

      <MainArea>
        {tabs.map((tab, id) => {
          return (
            <TabPanel
              key={`xr-bottom-panel-tab-panel-${tab.label}-${id}`}
              value={value}
              index={id}
              style={{ height: "100%" }}
            >
              <ResizeInner1>{tab.component}</ResizeInner1>
            </TabPanel>
          );
        })}
      </MainArea>
    </Container>
  );
};
