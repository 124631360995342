import { OptXVariation } from "allegro-api";
import { createnXtalAPI } from "src/api";

// OptXVariationをoptXIdを指定して、version, variationIdを部分一致で検索する関数
export const fetchOptXVariationsByVersion = async (
  optXId: string,
  filter: {
    version?: string;
    variationId?: string;
  }
): Promise<OptXVariation[]> => {
  const nxtal = createnXtalAPI();
  const { version, variationId } = filter;
  const versionQuery = {
    "contents.rawdata.version": {
      $regex: version,
    },
  };

  const variationIdQuery = {
    "contents.rawdata.variationId": {
      $regex: variationId,
    },
  };
  const searchQuery = {
    $or: [versionQuery, variationIdQuery],
  };

  const newtQuery = {
    shape: {
      contents: "any",
      bases: "any",
    },
    order: {
      "bases.created": -1,
    },
    search: searchQuery,
    limit: 8,
    skip: 0,
  };

  const [variations] = await nxtal.optxv2.optXVariation
    .dataProvider(optXId, newtQuery)
    .catch((err) => [[] as OptXVariation[]]);

  return variations;
};
