import React from "react";

export type TableData = {
  colName: string;
  type?: string;
  colValue: any;
};
import styled from "styled-components";
import { ASNoDataPlaceHolder } from "../atoms";

const Table = styled.div`
  width: 100%;
  display: table;

  /* table-layout: fixed; */
  > th,
  td {
    /* width: 50%; */
    display: block;
    padding: 12px;
    font-size: ${(props) => props.theme.regularFontSizeMd}px;
  }
  th {
    text-align: left;
  }
  td {
    text-align: right;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  color: ${(props) => props.theme.textColor};
  /* font-size: 13px; */
`;

const Indent = styled.div`
  /* display: flex; */
  width: 100%;
  padding-left: 24px;
`;

const ObjectCard = styled.div`
  background: ${(props) => props.theme.bgSecondaryColor};
  padding: 8px;
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
  border: 1px solid ${(props) => props.theme.secondaryBorderColor};
  margin-top: 12px;
  margin-bottom: 16px;
`;

const Row = styled.div``;

const Left = styled.div`
  flex: 1;
  text-align: left;
  vertical-align: center;
`;

const Right = styled.div`
  flex: 1;
  text-align: right;
  margin-left: auto;
  vertical-align: center;
`;

type Props = {
  tableData: Array<TableData>;
  className?: any;
};

const NoDataPlaceHolder = styled(ASNoDataPlaceHolder)`
  min-height: 100px;
  height: 100%;
`;

export const createEasyTableProps = (
  data: any,
  nullText?: string
): TableData[] => {
  const _nullText = nullText ?? "---";
  return Object.entries(data).map(([col, val]) => {
    let parsed: any = undefined;
    let type: string | undefined = undefined;
    if (!val || val === "Invalid Date") {
      parsed = _nullText;
      type = "none";
    } else if (Array.isArray(val)) {
      parsed = val.map((v) => {
        if (typeof v === "object") {
          return createEasyTableProps(v);
        } else {
          return v;
        }
      });
      // parsed = val.join(", ");
      type = "array";
    } else {
      type = typeof val;
      switch (typeof val) {
        case "number":
          parsed = val.toString();
          break;
        case "string":
          parsed = val;
          break;
        case "object":
          if (val) {
            parsed = createEasyTableProps(val);
          } else {
            parsed = _nullText;
          }
          break;
        default:
          parsed = _nullText;
      }
    }

    return {
      colName: col,
      type: type,
      colValue: parsed ?? "-",
    };
  });
};

export const ASEasyTable: React.FC<Props> = ({ className, tableData }) => {
  const renderJson = (data: TableData) => {
    switch (data.type) {
      case "array":
        return (
          <Row key={`as-easy-table-object-card-${Math.random()}`}>
            <Left>{data.colName}</Left>
            <Indent key={`k-array-${data}`}>
              {data.colValue.map((d: any) => {
                if (typeof d === "object") {
                  return (
                    <ObjectCard
                      key={`as-easy-table-object-card-${Math.random()}`}
                    >
                      <ASEasyTable tableData={d} />
                    </ObjectCard>
                  );
                } else {
                  return (
                    <ObjectCard
                      key={`as-easy-table-object-card-${Math.random()}`}
                    >
                      {d}
                    </ObjectCard>
                  );
                }
              })}
            </Indent>
          </Row>
        );
      case "object":
        return (
          <Row key={`as-easy-table-object-card-${Math.random()}`}>
            <Left>{data.colName}</Left>
            <Indent>
              <ObjectCard>
                <ASEasyTable tableData={data.colValue} />
              </ObjectCard>
            </Indent>
          </Row>
        );
      default:
        return (
          <div key={`as-easy-table-object-card-${Math.random()}`}>
            <Left>{data.colName}</Left>
            <Right>{data.colValue}</Right>
          </div>
        );
    }
  };
  return Array.isArray(tableData) ? (
    <>
      {tableData.length > 0 ? (
        <Table className={className}>
          {tableData.map((d, id) => {
            return (
              <Flex className="row" key={`k-${id}-${d.colName}`}>
                {renderJson(d)}
              </Flex>
            );
          })}
        </Table>
      ) : (
        <NoDataPlaceHolder>データがありません</NoDataPlaceHolder>
      )}
    </>
  ) : (
    <></>
  );
};
